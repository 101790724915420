import {
    Box,
    Button,
    ButtonProps,
    Flex,
    FlexProps,
    FormControl,
    FormLabel,
    FormLabelProps,
    Heading,
    Input,
    InputProps,
    Select,
    SelectProps,
    Slider,
    SliderFilledTrack,
    SliderProps,
    SliderThumb,
    SliderTrack,
    Text,
    TextProps,
} from "@chakra-ui/react"
import { MutableRefObject, useContext } from "react"

import { EThemeMode, ThemeContext } from "../../configs/theme"

const useThemeStyles = () => {
    const theme = useContext(ThemeContext)
    const isLight = theme === EThemeMode.Light
    return {
        textColor: isLight ? "#1E1E1E" : "white",
        borderColor: isLight ? "#E7E7E7" : "var(--ui-separator-color)",
        backgroundColor: isLight ? "#F7F7F7" : "transparent",
        backgroundColor_hover: isLight ? "#EEEEEE" : "rgba(255, 255, 255, 0.05)",
        backgroundColor_selected: isLight ? "#DDDDDD" : "var(--ui-separator-color)",
        backgroundColor_active: isLight ? "#FFFFFF" : "transparent",
        sliderColor_empty: isLight ? "rgba(0,0,0,0.15)" : "var(--ui-separator-color)",
        sliderColor_filled: "#05BC8B",
        sliderColor_thumb: "#BABABA",
    }
}

const BrandForm = {
    Title: (props: TextProps) => {
        const { children, ...rest } = props
        const theme = useThemeStyles()
        return (
            <Heading
                as="h3"
                fontSize="16px"
                fontWeight="500"
                color={theme.textColor}
                marginBottom="5px"
                userSelect="none"
                {...rest}
            >
                {children}
            </Heading>
        )
    },
    FieldTitle: (props: FormLabelProps) => {
        const { children, ...rest } = props
        const theme = useThemeStyles()
        return (
            <FormLabel
                fontSize="16px"
                fontWeight="500"
                color={theme.textColor}
                marginBottom="5px"
                lineHeight={1.2}
                userSelect="none"
                {...rest}
            >
                {children}
            </FormLabel>
        )
    },
    Group: (props: FlexProps) => {
        const { children, ...rest } = props
        return (
            <FormControl
                display="flex"
                flexDirection="column"
                width="full"
                justifyContent="flex-start"
                marginBottom="20px"
                gap={2}
                {...rest}
            >
                {children}
            </FormControl>
        )
    },
    SwitchGroup: (props: FlexProps) => {
        const { children, ...rest } = props
        return (
            <FormControl
                display="flex"
                width="full"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="10px"
                {...rest}
            >
                {children}
            </FormControl>
        )
    },
    GroupExpansion: (props: { isExpanded: boolean } & FlexProps) => {
        const { children, isExpanded, ...rest } = props
        return (
            <Box
                overflow={isExpanded ? "visible" : "hidden"}
                width="full"
                display="grid"
                gridTemplateRows={isExpanded ? "1fr" : "0fr"}
                opacity={isExpanded ? 1 : 0}
                transition={
                    isExpanded
                    ? "grid-template-rows 0.3s ease-out, opacity 0.4s ease-out"
                    : "grid-template-rows 0.4s ease-out, opacity 0.2s ease-in"
                }
            >
                <Flex
                    flexDirection="column"
                    width="full"
                    minHeight="0"
                    // marginTop="-10px"
                    gap={3}
                    {...rest}
                >
                    {children}
                </Flex>
            </Box>
        )
    },
    PillContainer: (props: FlexProps) => {
        const { children, ...rest } = props
        const theme = useThemeStyles()
        return (
            <Flex
                width="full"
                border={`1px solid ${theme.borderColor}`}
                borderRadius="md"
                {...rest}
            >
                {children}
            </Flex>
        )
    },
    Slider: (props: SliderProps) => {
        const { title, ...rest } = props
        const theme = useThemeStyles()
        return (
            <Flex width="full" alignItems="center" justifyContent="flex-start">
                <Text fontSize="14px" color={theme.textColor} minW="120px">
                    {title}
                </Text>
                <Slider maxW="150px" {...rest}>
                    <SliderTrack backgroundColor={theme.sliderColor_empty}>
                        <SliderFilledTrack backgroundColor={theme.sliderColor_filled} />
                    </SliderTrack>
                    <SliderThumb backgroundColor={theme.sliderColor_thumb} boxSize="20px" />
                </Slider>
            </Flex>
        )
    },
    Select: (props: SelectProps) => {
        const { value, onChange, children, ...rest } = props
        const theme = useThemeStyles()
        return (
            <Select
                value={value}
                onChange={onChange}
                size="lg"
                fontSize="md"
                variant="outline"
                color={theme.textColor}
                borderColor={theme.borderColor}
                backgroundColor={theme.backgroundColor}
                _hover={{
                    backgroundColor: theme.backgroundColor_hover,
                }}
                _active={{
                    backgroundColor: theme.backgroundColor_active,
                }}
                {...rest}
            >
                {children}
            </Select>
        )
    },
    Input: (props: InputProps) => {
        const { value, onChange, ...rest } = props
        const theme = useThemeStyles()
        return (
            <Input
                value={value}
                onChange={onChange}
                size="lg"
                fontSize="md"
                variant="outline"
                color={theme.textColor}
                borderColor={theme.borderColor}
                backgroundColor={theme.backgroundColor}
                _hover={{
                    backgroundColor: theme.backgroundColor_hover,
                }}
                _active={{
                    backgroundColor: theme.backgroundColor_active,
                }}
                onKeyDown={(e) => e.stopPropagation()}
                {...rest}
            />
        )
    },
    ColorPicker: (
        props: InputProps & {
            value: string
            defaultValue?: string
            pickerRef: MutableRefObject<HTMLInputElement | null>
        },
    ) => {
        const { value, onChange, pickerRef, name, defaultValue = "#FFFFFF" } = props
        const theme = useThemeStyles()
        const inputValue = value || defaultValue
        const displayValue = inputValue.includes("#") ? inputValue.split("#").pop()! : inputValue
        return (
            <Flex
                justifyContent="flex-start"
                alignItems="center"
                border={`1px solid ${theme.borderColor}`}
                borderRadius="md"
                height="38px"
                width="160px"
                position="relative"
            >
                <Box
                    minWidth="44px"
                    height="100%"
                    backgroundColor={inputValue}
                    borderLeftRadius="md"
                    borderRight={`1px solid ${theme.borderColor}`}
                    onClick={() => pickerRef?.current?.click()}
                />
                <input
                    ref={pickerRef}
                    value={inputValue}
                    onChange={onChange}
                    name={name}
                    type="color"
                    style={{ visibility: "hidden" }}
                />
                <Text color={theme.textColor} userSelect="none" marginBottom={0}>
                    #
                </Text>
                <Input
                    value={displayValue}
                    textTransform="uppercase"
                    name={name}
                    onChange={onChange}
                    border="none"
                    color={theme.textColor}
                    ml="-10px"
                />
            </Flex>
        )
    },
    SelectCell: (props: SelectProps & { hasLeftOption: boolean; hasRightOption: boolean }) => {
        const { value, onChange, hasLeftOption, hasRightOption, children, ...rest } = props
        const theme = useThemeStyles()
        const contextStyles = {} as any
        if (!hasLeftOption) {
            contextStyles.borderLeftRadius = "md"
        }
        if (hasRightOption) {
            contextStyles.borderRight = `1px solid ${theme.borderColor}`
        } else {
            contextStyles.borderRight = "none"
            contextStyles.borderRightRadius = "md"
        }
        return (
            <Select
                value={value}
                onChange={onChange}
                border="none"
                color={theme.textColor}
                fontSize="md"
                size="lg"
                height="46px"
                minWidth="fit-content"
                borderRadius={0}
                {...contextStyles}
                _hover={{
                    backgroundColor: theme.backgroundColor_hover,
                    ...contextStyles,
                }}
                _focusWithin={{
                    backgroundColor: theme.backgroundColor_active,
                    ...contextStyles,
                }}
                {...rest}
            >
                {children}
            </Select>
        )
    },
    ButtonCell: (
        props: ButtonProps & { selected: boolean; hasLeftOption: boolean; hasRightOption: boolean },
    ) => {
        const { value, onChange, hasLeftOption, hasRightOption, selected, children, ...rest } =
            props
        const theme = useThemeStyles()
        const contextStyles = {} as any
        if (!hasLeftOption) {
            contextStyles.borderLeftRadius = "md"
        }
        if (hasRightOption) {
            contextStyles.borderRight = `1px solid ${theme.borderColor}`
        } else {
            contextStyles.borderRightRadius = "md"
        }
        return (
            <Button
                onClick={onChange}
                backgroundColor={selected ? theme.backgroundColor_selected : "transparent"}
                minWidth="fit-content"
                color={theme.textColor}
                fontSize="md"
                size="lg"
                borderRadius={0}
                {...rest}
                {...contextStyles}
                _hover={{
                    backgroundColor: selected
                        ? theme.backgroundColor_selected
                        : theme.backgroundColor,
                    ...contextStyles,
                }}
            >
                {children}
            </Button>
        )
    },
}

export default BrandForm
