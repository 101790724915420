import { BrandStyle } from "@prisma/client"
import round from "lodash/round"
import { useMemo } from "react"

import { IClipSchemaItem, ITranscriptWord } from "../@types/snippet_types"
import _c from "../configs/constants"
import { applyCaptionStyleToWordList, getTranscriptForClipSchema } from "../utils/captions_util"
import { sumFields } from "../utils/number_util"
import { getClipDuration } from "../utils/video_util"

const getDeletedRangesFromSchema = (clipSchema: IClipSchemaItem[]) => {
    const ranges = clipSchema.map((interval, i) => ({
        start: interval.start,
        end: interval.start + interval.duration,
    }))
    const durationThreshold = _c.INTERNAL_TIMELINE_TICK_SIZE_MS / 1_000
    return ranges.reduce((acc, currentRange, i, list) => {
        if (i === 0) {
            return acc
        }
        const previousRange = list[i - 1]
        const rangeDiff = currentRange.start - previousRange.end
        if (rangeDiff > durationThreshold) {
            acc.push({
                start: previousRange.end,
                end: currentRange.start + durationThreshold,
            })
        }
        return acc
    }, [] as { start: number; end: number }[])
}

export const useClipSchema = (clipSchema: IClipSchemaItem[]) => {
    const clipStart = (() => {
        if (!clipSchema?.[0]) {
            return 0
        }
        return clipSchema[0].start
    })()
    const clipEnd = (() => {
        const lastInterval = clipSchema?.[clipSchema.length - 1]
        if (!lastInterval) {
            return 0
        }
        return lastInterval.start + lastInterval.duration
    })()
    const schemaTimeRanges = useMemo(() => {
        return clipSchema.map((_, i) => ({
            start: round(
                sumFields(clipSchema.slice(0, i), "duration"),
                _c.REQUIRED_TIMELINE_PRECISION,
            ),
            end: round(
                sumFields(clipSchema.slice(0, i + 1), "duration"),
                _c.REQUIRED_TIMELINE_PRECISION,
            ),
        }))
    }, [clipSchema])

    const deletedRanges = useMemo(() => getDeletedRangesFromSchema(clipSchema), [clipSchema])

    const clipDuration = !clipSchema ? 0 : getClipDuration(clipSchema)

    const getCaptionsForSchema = (transcript: ITranscriptWord[], brand: BrandStyle) => {
        if (clipSchema.length === 0) {
            return []
        }
        const indexedTranscript = transcript.map((word, index) => ({ ...word, index }))

        const schemaWords = getTranscriptForClipSchema({
            schema: clipSchema,
            transcript: indexedTranscript,
        })

        const captions = applyCaptionStyleToWordList(brand, schemaWords)

        return captions
    }

    return {
        clipStart,
        clipEnd,
        clipDuration,
        schemaTimeRanges,
        deletedRanges,
        getCaptionsForSchema,
    }
}
