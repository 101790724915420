import {
    Box,
    Image as ChakraImage,
    Flex,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    Tooltip,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import round from "lodash/round"
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MdMoreHoriz } from "react-icons/md"

import { IClipSchemaItem } from "../../@types/snippet_types"
import _c from "../../configs/constants"
import { useClipSchema } from "../../hooks/useClipSchema"
import useWindowSize from "../../hooks/useWindowSize"
import { EEditorTutorialSteps } from "../../pages/clips/editor"
import { isMobileSafari } from "../../utils/browser_util"
import { EAWSCFDistribution, getCloudFrontUrl } from "../../utils/link_util"
import { sumFields } from "../../utils/number_util"
import { TutorialStepWrapper } from "../tutorial/TutorialStepWrapper"

interface Thumbnail {
    id: string
    url: string
}

export const getFromCache = (key: string): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("thumbnailCacheDB", 1)

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            const db = (event.target as IDBOpenDBRequest).result
            db.createObjectStore("thumbnails", { keyPath: "id" })
        }

        request.onsuccess = (event: Event) => {
            const db = (event.target as IDBOpenDBRequest).result
            const transaction = db.transaction("thumbnails", "readonly")
            const objectStore = transaction.objectStore("thumbnails")
            const cacheRequest = objectStore.get(key)

            cacheRequest.onsuccess = () => {
                resolve(cacheRequest.result ? (cacheRequest.result as Thumbnail).url : null)
            }

            cacheRequest.onerror = () => reject(cacheRequest.error)
        }

        request.onerror = () => reject(request.error)
    })
}

export const saveToCache = (key: string, url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("thumbnailCacheDB", 1)

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            const db = (event.target as IDBOpenDBRequest).result
            db.createObjectStore("thumbnails", { keyPath: "id" })
        }

        request.onsuccess = (event: Event) => {
            const db = (event.target as IDBOpenDBRequest).result
            const transaction = db.transaction("thumbnails", "readwrite")
            const objectStore = transaction.objectStore("thumbnails")
            const cacheRequest = objectStore.put({ id: key, url })

            cacheRequest.onsuccess = () => resolve()
            cacheRequest.onerror = () => reject(cacheRequest.error)
        }

        request.onerror = () => reject(request.error)
    })
}

export const generateImageFromStoryboard = async (args: {
    imageUrl: string
    xOffset: number
    yOffset: number
    width: number
    height: number
}): Promise<string> => {
    return new Promise<string>((resolve) => {
        const image = new Image()
        image.crossOrigin = "anonymous"

        image.onload = () => {
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")

            canvas.width = args.width
            canvas.height = args.height

            ctx!.drawImage(
                image,
                args.xOffset,
                args.yOffset,
                args.width,
                args.height,
                0,
                0,
                args.width,
                args.height,
            )

            canvas.toBlob((blob) => {
                if (blob) {
                    const objectUrl = URL.createObjectURL(blob)
                    resolve(objectUrl)
                }
            })
        }
        image.src = args.imageUrl
    })
}

// 1. Create video
// 2. On load, seek to time
// 3. On seek, get image thumbnail
// 4. Trigger load
export const generateThumbnailFromVideo = async (args: {
    videoUrl: string
    time: number
    width: number
    height: number
    quality?: number
}) => {
    const { videoUrl, time, width, height, quality = 0.25 } = args

    const cacheKey = `${videoUrl}_${time}`

    try {
        const cachedUrl = await getFromCache(cacheKey)
        if (cachedUrl) {
            return cachedUrl
        }
    } catch (error) {
        // If there is an error, get the thumbnail as usual
    }

    return new Promise<string>((resolve) => {
        const video = document.createElement("video")
        video.src = videoUrl
        video.crossOrigin = "anonymous"
        if (!!width && !!height) {
            video.width = width
            video.height = height
        }
        const onSeek = () => {
            const canvas = document.createElement("canvas")
            canvas.width = width
            canvas.height = height
            const context = canvas.getContext("2d")
            const makeSafariChanges = isMobileSafari()

            // PNG fails less in Safari
            const imageFormat = makeSafariChanges ? "image/png" : "image/jpeg"

            // Safari mobile fails to drawImage if we don't wait,
            // but there is no API to know when it's ready.
            const browserDelay = makeSafariChanges ? 1_000 : 0

            setTimeout(async () => {
                context?.drawImage(video, 0, 0, width, height)
                const url = canvas.toDataURL(imageFormat, quality)
                await saveToCache(cacheKey, url)
                resolve(url)
            }, browserDelay)
        }
        video.addEventListener("seeked", onSeek)
        video.load()
        video.currentTime = time
    })
}

const DRAG_HANDLE_WIDTH = 14
const validUIDragThreshold = DRAG_HANDLE_WIDTH / 2

enum EDragMode {
    None,
    Left,
    Right,
}

export enum EEditorTimelineSteps {
    EditorTimeline = "step-editor-timeline",
}

interface IEditorTimelineProps {
    clipSchema: IClipSchemaItem[]
    activeIntervalIndex: number
    sourceAspectRatio: number
    videoRef: MutableRefObject<HTMLVideoElement | null>
    hasStoryboards: boolean
    taskId: string
    onUpdateStart: (newStart: number) => void
    onUpdateEnd: (newEnd: number) => void
    onDeleteInterval: (intervalIndex: number) => void
}
const EditorTimeline = (props: IEditorTimelineProps) => {
    const {
        taskId,
        videoRef,
        clipSchema,
        activeIntervalIndex,
        sourceAspectRatio,
        onUpdateStart,
        onUpdateEnd,
        onDeleteInterval,
    } = props
    const [dragMode, setDragMode] = useState<EDragMode>(EDragMode.None)
    const [thumbnailInfo, setThumbnails] = useState<Record<number, string>>({})
    const [mousePos, setMousePos] = useState<MouseEvent | null>(null)
    const windowSize = useWindowSize()
    const [extendTime, setExtendTime] = useState<number>(0)

    const intervalRef = useRef<number | null>(null)
    const mousePositionLineRef = useRef<HTMLDivElement | null>(null)
    const scrubAreaRef = useRef<HTMLDivElement | null>(null)

    const timelineRect = useMemo(() => {
        if (!scrubAreaRef.current) {
            return null
        }
        return scrubAreaRef.current!.getBoundingClientRect()
    }, [windowSize.width, scrubAreaRef.current])
    const schemaTimeRanges = useMemo(() => {
        return clipSchema.map((_, i) => ({
            start: round(
                sumFields(clipSchema.slice(0, i), "duration"),
                _c.REQUIRED_TIMELINE_PRECISION,
            ),
            end: round(
                sumFields(clipSchema.slice(0, i + 1), "duration"),
                _c.REQUIRED_TIMELINE_PRECISION,
            ),
        }))
    }, [clipSchema])
    const { clipStart, clipEnd, clipDuration } = useClipSchema(clipSchema)
    const startTime = (() => {
        if (dragMode === EDragMode.Left) {
            return clipStart - extendTime
        }
        return clipStart
    })()
    const endTime = (() => {
        if (dragMode === EDragMode.Right) {
            return clipEnd + extendTime
        }
        return clipEnd
    })()
    const showDragIndicator = (() => {
        if (dragMode === EDragMode.None || !mousePos) {
            return false
        }
        const xPos = mousePos!.clientX - timelineRect!.x
        const isOutOfLeft = xPos <= 0
        const isOutOfRight = xPos >= timelineRect!.width
        return !isOutOfLeft && !isOutOfRight
    })()

    // Get intervals that are in range and compute what proportion of the total duration they take up
    const layoutIntervals = useMemo(() => {
        return clipSchema.map((interval, i) => ({
            ...interval,
            proportion: interval.duration / clipDuration,
            cumalativeStart: sumFields(clipSchema.slice(0, i), "duration"),
            cumalativeEnd: sumFields(clipSchema.slice(0, i + 1), "duration"),
            i,
        }))
    }, [clipSchema, clipDuration])

    const generateThumbnailsFromStoryboards = async () => {
        const sbTemplateUrl = getCloudFrontUrl(EAWSCFDistribution.Snippets, `${taskId}/storyboards`)
        for (const interval of layoutIntervals) {
            if (thumbnailInfo[interval.start]) {
                continue
            }
            const thumbStart = Math.floor(interval.start)
            const indexInFile = thumbStart % _c.VIDEO_STORYBOARDS.THUMBNAILS_PER_FILE
            const xIndex = indexInFile % _c.VIDEO_STORYBOARDS.COLUMNS_PER_FILE
            const yIndex = Math.floor(indexInFile / _c.VIDEO_STORYBOARDS.ROWS_PER_FILE)
            const fileNumber =
                (thumbStart - indexInFile) / _c.VIDEO_STORYBOARDS.THUMBNAILS_PER_FILE + 1
            const fileNumberPadded = `${fileNumber}`.padStart(3, "0")
            const imageUrl = await generateImageFromStoryboard({
                imageUrl: `${sbTemplateUrl}/sb_${fileNumberPadded}.jpg`,
                xOffset: xIndex * _c.VIDEO_STORYBOARDS.THUMBNAIL_WIDTH,
                yOffset: yIndex * _c.VIDEO_STORYBOARDS.THUMBNAIL_HEIGHT,
                width: _c.VIDEO_STORYBOARDS.THUMBNAIL_WIDTH,
                height: _c.VIDEO_STORYBOARDS.THUMBNAIL_HEIGHT,
            })
            setThumbnails((prev) => ({ ...prev, [interval.start]: imageUrl }))
        }
    }
    const generateThumbnailsFromVideo = async () => {
        const videoUrl = videoRef.current!.getElementsByTagName("source")[0].src
        for (const interval of layoutIntervals) {
            if (thumbnailInfo[interval.start]) {
                continue
            }
            const intervalIndex = layoutIntervals.findIndex((i) => i.start === interval.start)
            const seekLocation =
                intervalIndex === 0 ? clipStart : layoutIntervals[intervalIndex].start
            // Scale thumbnail size/quality based on the number of intervals, fewer means bigger thumbnails
            const thumbWidth = Math.max(
                200,
                Math.min(windowSize.width / layoutIntervals.length, 600),
            )
            const thumbHeight = thumbWidth / sourceAspectRatio
            const imageUrl = await generateThumbnailFromVideo({
                videoUrl,
                time: seekLocation,
                width: thumbWidth,
                height: thumbHeight,
            })
            setThumbnails((prev) => ({ ...prev, [interval.start]: imageUrl }))
        }
    }
    useEffect(() => {
        ;(async () => {
            if (!videoRef.current || !layoutIntervals.length) {
                return
            }
            if (props.hasStoryboards) {
                generateThumbnailsFromStoryboards()
                return
            }
            generateThumbnailsFromVideo()
            return
        })()
    }, [layoutIntervals, videoRef.current, startTime, endTime])
    const maintainSeekPosition = useCallback(
        (video: HTMLVideoElement) => {
            if (schemaTimeRanges.length === 0) {
                scrubAreaRef.current?.style.setProperty("--seek-percentage", "0%")
                return
            }
            const videoTime = video.currentTime
            const intervalIndex = clipSchema.findIndex((interval) => {
                return (
                    videoTime >= interval.start && videoTime <= interval.start + interval.duration
                )
            })
            if (intervalIndex === -1) {
                return
            }
            const clipDurationBeforeInterval = schemaTimeRanges[intervalIndex].start
            const elapsedInInterval = videoTime - clipSchema[intervalIndex].start
            const timeInClip = clipDurationBeforeInterval + elapsedInInterval
            const seekPercentage = (timeInClip / clipDuration) * 100
            scrubAreaRef.current?.style.setProperty("--seek-percentage", `${seekPercentage}%`)
        },
        [clipSchema, startTime, clipDuration],
    )
    useEffect(() => {
        const video = videoRef.current
        if (!video) {
            return
        }
        maintainSeekPosition(video)
        const intervalId = setInterval(() => {
            maintainSeekPosition(video)
        }, 50)
        return () => clearInterval(intervalId)
    }, [videoRef.current, clipSchema, startTime, clipDuration, activeIntervalIndex])
    const calculateTimelinePositionInSeconds = (args: {
        mousePos: number
        containerRect: DOMRect
        start: number
        end: number
    }) => {
        const { mousePos, containerRect, start, end } = args
        const tlRelativexPos = mousePos - containerRect.x
        const proportion = tlRelativexPos / containerRect.width
        const clipDuration = end - start
        const videoClickedAt = proportion * clipDuration
        return start + videoClickedAt
    }
    const calculateLeftDragOffset = (args: {
        mouse: MouseEvent | null
        rect: DOMRect | null
        mode: EDragMode
    }) => {
        const { mode, mouse, rect } = args
        if (mode === EDragMode.None || !rect || !mouse) {
            return 0
        }
        const relativeX = mouse!.clientX - rect!.x + validUIDragThreshold
        const canMoveHandle = mode === EDragMode.Left && relativeX < 0
        const absoluteOffset = Math.abs(relativeX)
        const boundedOffset = canMoveHandle ? Math.min(absoluteOffset, DRAG_HANDLE_WIDTH) : 0
        return boundedOffset
    }
    const calculateRightDragOffset = (args: {
        mouse: MouseEvent | null
        rect: DOMRect | null
        mode: EDragMode
    }) => {
        const { mode, mouse, rect } = args
        if (mode === EDragMode.None || !rect || !mouse) {
            return 0
        }
        const relativeX = mouse!.clientX - rect!.x
        const rightOffset = relativeX - rect!.width - validUIDragThreshold
        const canMoveHandle = mode === EDragMode.Right && rightOffset > 0
        const boundedOffset = canMoveHandle ? Math.min(rightOffset, DRAG_HANDLE_WIDTH) : 0
        return boundedOffset
    }
    const handleDragMouseDown = (mode: EDragMode) => (e: React.MouseEvent) => {
        e.preventDefault()
        setDragMode(mode)
        setMousePos(e.nativeEvent)
    }
    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            setMousePos(e)
            if (!timelineRect || !mousePositionLineRef.current) {
                return
            }

            // Set mouse position line
            const relativeXPos = e.clientX - timelineRect!.x
            const mousePosition = relativeXPos / timelineRect!.width
            // Set manually, as rendering is too slow
            mousePositionLineRef.current.style.transform = `translateX(${
                // In transform % is relative to element's width not container's width
                // Thus we compute position in js
                timelineRect!.width * mousePosition - 0.5
            }px)`

            if (dragMode === EDragMode.None) {
                return
            }
            const offsetCalculator =
                dragMode === EDragMode.Left ? calculateLeftDragOffset : calculateRightDragOffset
            const offset = offsetCalculator({
                mouse: e,
                rect: timelineRect,
                mode: dragMode,
            })
            const timerStartThreshold = DRAG_HANDLE_WIDTH
            const intervalId = intervalRef.current
            const shouldBeginExtendingClip = offset >= timerStartThreshold
            if (shouldBeginExtendingClip) {
                if (intervalId) {
                    return
                }
                intervalRef.current = setInterval(() => {
                    setExtendTime((prev) => prev + 0.25)
                }, 500) as unknown as number
            } else {
                if (intervalId) {
                    clearInterval(intervalId)
                    intervalRef.current = null
                }
            }
        },
        [dragMode, timelineRect, extendTime, clipStart, clipEnd],
    )
    const handleDragMouseUp = useCallback(() => {
        if (dragMode === EDragMode.None) {
            return
        }
        const significantDragThreshold = 20
        const tlRelativexPos = mousePos!.clientX - timelineRect!.x
        const didDragOutFromLeftSide = tlRelativexPos < 0
        const didDragOutFromRightSide = tlRelativexPos > timelineRect!.width
        const didDragIntoTimeline = !didDragOutFromLeftSide && !didDragOutFromRightSide

        let didExceedThreshold = false
        if (didDragOutFromLeftSide) {
            didExceedThreshold = tlRelativexPos < -1 * significantDragThreshold
        } else if (didDragOutFromRightSide) {
            didExceedThreshold = tlRelativexPos - timelineRect!.width > significantDragThreshold
        }
        if (!didDragIntoTimeline && !didExceedThreshold) {
            setDragMode(EDragMode.None)
            return
        }

        if (didDragIntoTimeline) {
            const newTime = calculateTimelinePositionInSeconds({
                mousePos: mousePos!.clientX,
                containerRect: timelineRect!,
                start: dragMode === EDragMode.Left ? startTime : clipStart,
                end: dragMode === EDragMode.Right ? endTime : clipEnd,
            })
            if (dragMode === EDragMode.Left) {
                const isNewStartTimeValid = newTime <= clipEnd
                if (!isNewStartTimeValid) {
                    setDragMode(EDragMode.None)
                    return
                }
                onUpdateStart(newTime)
            } else {
                const isNewEndTimeValid = newTime >= clipStart
                if (!isNewEndTimeValid) {
                    setDragMode(EDragMode.None)
                    return
                }
                onUpdateEnd(newTime)
            }
        } else {
            if (dragMode === EDragMode.Left) {
                onUpdateStart(startTime)
            } else {
                onUpdateEnd(endTime)
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
        setExtendTime(0)
        setDragMode(EDragMode.None)
    }, [dragMode, mousePos, startTime, endTime, timelineRect, extendTime, clipStart, clipEnd])
    useEffect(() => {
        window.addEventListener("mouseup", handleDragMouseUp)
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mouseup", handleDragMouseUp)
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [dragMode, mousePos, startTime, endTime, timelineRect])
    const handleClickInterval =
        (interval: (typeof layoutIntervals)[number]) =>
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const video = videoRef.current
            if (!video) {
                return
            }
            let intervalStartTime = interval.start
            const isFirstIndex = interval.i === 0
            if (isFirstIndex) {
                intervalStartTime = startTime
            }
            video.currentTime = intervalStartTime
            e.currentTarget?.blur()
        }
    const handleClickTimeline = (e: React.MouseEvent) => {
        const video = videoRef.current
        if (!video) {
            return
        }
        e.stopPropagation()
        const clickPosition = e.clientX - timelineRect!.left
        const proportion = clickPosition / timelineRect!.width
        const clipTime = proportion * clipDuration
        const intervalIndex = layoutIntervals.findIndex(
            (range) => clipTime >= range.cumalativeStart && clipTime < range.cumalativeEnd,
        )
        const interval = layoutIntervals[intervalIndex]
        const elapsedInInterval = clipTime - interval.cumalativeStart
        video.currentTime = interval.start + elapsedInInterval
    }
    const renderDragIndicator = () => {
        if (!showDragIndicator) {
            return null
        }
        const dragIndicatorProps = (() => {
            if (!showDragIndicator) {
                return {}
            }
            const xPos = mousePos!.clientX - timelineRect!.x
            const proportion = (xPos / timelineRect!.width) * 100
            if (dragMode === EDragMode.Left) {
                return { left: "0", width: `${proportion}%`, borderRight: "2px solid white" }
            } else {
                return { right: "0", width: `${100 - proportion}%`, borderLeft: "2px solid white" }
            }
        })()
        return (
            <Box
                position="absolute"
                height="100%"
                top="0"
                {...dragIndicatorProps}
                backgroundColor="rgba(0,0,0,0.7)"
                zIndex={1}
            />
        )
    }
    const renderLeftTooltip = () => {
        const xOffset = calculateLeftDragOffset({
            mouse: mousePos,
            rect: timelineRect,
            mode: dragMode,
        })
        const showLeftTooltip = dragMode === EDragMode.Left && xOffset > 0
        return (
            <Tooltip
                isOpen={showLeftTooltip}
                closeOnClick={false}
                label={`+${extendTime}s`}
                placement="top"
                hasArrow={true}
                gutter={12}
                padding="10px 16px"
                backgroundColor="#313131"
                borderRadius="md"
                fontSize="md"
            >
                <Box
                    position="absolute"
                    top="0"
                    left="var(--drag-handle-width)"
                    width="1"
                    background="none"
                />
            </Tooltip>
        )
    }
    const renderRightTooltip = () => {
        const xOffset = calculateRightDragOffset({
            mouse: mousePos,
            rect: timelineRect,
            mode: dragMode,
        })
        const showRightTooltip = dragMode === EDragMode.Right && xOffset > 0
        return (
            <Tooltip
                isOpen={showRightTooltip}
                closeOnClick={false}
                label={`+${extendTime}s`}
                placement="top"
                hasArrow={true}
                gutter={12}
                padding="10px 16px"
                backgroundColor="#313131"
                borderRadius="md"
                fontSize="md"
            >
                <Box
                    position="absolute"
                    top="0"
                    right="var(--drag-handle-width)"
                    width="1"
                    background="none"
                />
            </Tooltip>
        )
    }
    const renderCenterTooltip = () => {
        const mouseX = mousePos ? mousePos!.clientX : 0
        const xPos = !timelineRect ? 0 : mouseX - timelineRect!.x
        const isOverTimeline = xPos > 0 && xPos < timelineRect!.width
        const showTooltip = dragMode !== EDragMode.None
        const showCenterTooltip = showTooltip && isOverTimeline
        const centerTimeChange = (() => {
            if (!showCenterTooltip) {
                return ""
            }
            const newTime = calculateTimelinePositionInSeconds({
                mousePos: mouseX,
                containerRect: timelineRect!,
                start: startTime,
                end: endTime,
            })
            if (dragMode === EDragMode.Left) {
                return `-${(newTime - startTime).toFixed(1)}s`
            } else {
                return `-${(endTime - newTime).toFixed(1)}s`
            }
        })()
        const centerTooltipPos = (() => {
            if (!showCenterTooltip) {
                return 0
            }
            const xPos = mouseX - timelineRect!.x
            const proportion = (xPos / timelineRect!.width) * 100
            return proportion
        })()
        return (
            <Tooltip
                isOpen={showCenterTooltip}
                closeOnClick={false}
                label={centerTimeChange}
                placement="top"
                hasArrow={true}
                gutter={12}
                padding="10px 16px"
                backgroundColor="#313131"
                borderRadius="md"
                fontSize="md"
            >
                <Box
                    position="absolute"
                    top="0"
                    left={`calc(${centerTooltipPos}% + (var(--drag-handle-width) / 2))`}
                    width="1"
                    background="none"
                />
            </Tooltip>
        )
    }
    const renderLeftDragHandle = () => {
        const xOffset = calculateLeftDragOffset({
            mouse: mousePos,
            rect: timelineRect,
            mode: dragMode,
        })
        return (
            <Box position="relative" width="var(--drag-handle-width)" height="100%">
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    borderTopLeftRadius={xOffset > 5 ? 0 : "5px"}
                    borderBottomLeftRadius={xOffset > 5 ? 0 : "5px"}
                    backgroundImage="linear-gradient(to left, rgba(6, 214, 160, 0.25) 33.33%, rgba(6, 214, 160, 0.5) 33.33% 66.66%, rgba(6, 214, 160, 0.75) 66.66%)"
                />
                <Box
                    onMouseDown={handleDragMouseDown(EDragMode.Left)}
                    position="relative"
                    width="var(--drag-handle-width)"
                    height="100%"
                    backgroundColor="#02b788"
                    transform={`translateX(-${xOffset}px)`}
                    borderTopLeftRadius="5px"
                    borderBottomLeftRadius="5px"
                    cursor="grab"
                    transition={dragMode === EDragMode.None ? "transform 0.1s ease" : "none"}
                    _active={{ cursor: "grabbing" }}
                >
                    <Box
                        position="absolute"
                        top="calc((100% - 27px) / 2)"
                        left="calc((var(--drag-handle-width) - 2px) / 2)"
                        backgroundColor="white"
                        width="2px"
                        height="27px"
                        zIndex={2}
                    />
                </Box>
            </Box>
        )
    }
    const renderRightDragHandle = () => {
        const xOffset = calculateRightDragOffset({
            mouse: mousePos,
            rect: timelineRect,
            mode: dragMode,
        })
        return (
            <Box position="relative" width="var(--drag-handle-width)" height="100%">
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    borderTopRightRadius={xOffset > 5 ? 0 : "5px"}
                    borderBottomRightRadius={xOffset > 5 ? 0 : "5px"}
                    backgroundImage="linear-gradient(to right, rgba(6, 214, 160, 0.25) 33.33%, rgba(6, 214, 160, 0.5) 33.33% 66.66%, rgba(6, 214, 160, 0.75) 66.66%)"
                />
                <Box
                    onMouseDown={handleDragMouseDown(EDragMode.Right)}
                    position="relative"
                    width="var(--drag-handle-width)"
                    height="100%"
                    backgroundColor="#02b788"
                    borderTopRightRadius="5px"
                    borderBottomRightRadius="5px"
                    cursor="grab"
                    _active={{ cursor: "grabbing" }}
                    transform={`translateX(${xOffset}px)`}
                    transition={dragMode === EDragMode.None ? "transform 0.1s ease" : "none"}
                >
                    <Box
                        position="relative"
                        top="calc((100% - 27px) / 2)"
                        left="calc((var(--drag-handle-width) - 2px) / 2)"
                        backgroundColor="white"
                        width="2px"
                        height="27px"
                    />
                </Box>
            </Box>
        )
    }
    const renderIntervals = () => {
        return (
            <HStack
                spacing={0}
                position="relative"
                height="100%"
                width="full"
                backgroundColor="var(--secondary-bg-color)"
            >
                {layoutIntervals.map((interval, i) => {
                    const isHighlighted = interval.i === activeIntervalIndex
                    const imageUrl = thumbnailInfo[interval.start]
                    const hasImage = !!imageUrl && imageUrl.length > 0
                    const opacity = (() => {
                        if (!hasImage) {
                            return 0
                        }
                        return isHighlighted ? 1 : 0.6
                    })()
                    return (
                        <Box
                            data-group
                            key={interval.start}
                            position="relative"
                            height="100%"
                            width={`${interval.proportion * 100}%`}
                            css={{
                                "&:first-of-type > button:first-of-type": {
                                    borderTopLeftRadius: "0",
                                    borderBottomLeftRadius: "0",
                                    borderLeft: "none",
                                },
                                "&:last-of-type > button:first-of-type": {
                                    borderTopRightRadius: "0",
                                    borderBottomRightRadius: "0",
                                    borderRight: "none",
                                },
                            }}
                        >
                            <Box
                                as="button"
                                className="interval-button"
                                // @ts-ignore TS doesn't know it's a button
                                onClick={handleClickInterval(interval)}
                                width="full"
                                height="full"
                                backgroundColor="var(--secondary-bg-color)"
                                backgroundImage={imageUrl}
                                backgroundSize="cover"
                                backgroundPosition="center"
                                backgroundRepeat="no-repeat"
                                transition="border 0.1s ease, opacity 0.3s ease"
                                border={isHighlighted ? "1px solid #02b788" : "none"}
                                opacity={opacity}
                                borderRadius="5px"
                                cursor="pointer"
                                _hover={{
                                    backgroundColor: "#999999",
                                    opacity: !showDragIndicator ? 1 : 0.6,
                                }}
                            />
                            <Menu placement="top-start">
                                <MenuButton
                                    as={IconButton}
                                    aria-label="Options"
                                    icon={<MdMoreHoriz size="20px" color="white" />}
                                    isRound={true}
                                    width="auto"
                                    minWidth="auto"
                                    height="auto"
                                    position="absolute"
                                    top="-10px"
                                    right="-10px"
                                    backgroundColor="var(--ui-highlight-color)"
                                    boxShadow="3px 3px 15px rgba(0,0,0,30%)"
                                    zIndex={1}
                                    opacity={0}
                                    _groupHover={{
                                        opacity: 1,
                                    }}
                                    _hover={{
                                        backgroundColor: "#13896E",
                                    }}
                                    _active={{
                                        backgroundColor: "#13896E",
                                    }}
                                />
                                <MenuList borderColor="#282828" paddingY={0} fontSize="14px">
                                    <MenuItem
                                        onClick={() => onDeleteInterval(interval.i)}
                                        paddingX={5}
                                        paddingY={4}
                                        color="white"
                                        backgroundColor="#282828"
                                        _hover={{ backgroundColor: "#1C1C1C" }}
                                    >
                                        Delete Section
                                    </MenuItem>
                                    {/* <MenuItem
                                        paddingX={5}
                                        paddingY={4}
                                        color="white"
                                        backgroundColor="#282828"
                                        _hover={{ backgroundColor: "#1C1C1C" }}
                                    >
                                        Add as intro
                                    </MenuItem> */}
                                </MenuList>
                            </Menu>
                        </Box>
                    )
                })}
                {renderDragIndicator()}
            </HStack>
        )
    }
    const renderIntervalPanels = () => {
        return (
            <Box
                paddingTop="calc(var(--scrubber-area-height) + 5px)"
                paddingX="var(--controls-offset-width)"
                width="full"
                overflow="hidden"
            >
                <HStack
                    spacing={0}
                    height="var(--panel-height)"
                    width="full"
                    position="relative"
                >
                    {renderLeftTooltip()}
                    {renderRightTooltip()}
                    {renderCenterTooltip()}
                    {renderLeftDragHandle()}
                    {renderIntervals()}
                    {renderRightDragHandle()}
                </HStack>
            </Box>
        )
    }
    const renderTimeline = () => {
        if (!timelineRect) {
            return null
        }
        const SEGMENTS_PER_SECOND = 2
        const EMPHASIS_INTERVAL_SECONDS = 10
        const totalSegmentsInClip = clipDuration * SEGMENTS_PER_SECOND
        const widthPerSegment = timelineRect.width / totalSegmentsInClip
        const majorEmphasisInterval = EMPHASIS_INTERVAL_SECONDS * SEGMENTS_PER_SECOND
        const minorEmphasisInterval = majorEmphasisInterval / 2
        return (
            <Flex
                flexWrap="nowrap"
                position="absolute"
                top="0"
                width="calc(100% - ((var(--drag-handle-width) + var(--controls-offset-width)) * 2))"
                left="calc(var(--drag-handle-width) + var(--controls-offset-width))"
                height="var(--timeline-height)"
                overflow="visible"
                pointerEvents="none"
            >
                {Array.from({ length: totalSegmentsInClip })
                    .fill(null)
                    .map((_, i) => {
                        const hasMajorEmphasis = i % majorEmphasisInterval === 0
                        const hasMinorEmphasis = i % minorEmphasisInterval === 0
                        const time = i / SEGMENTS_PER_SECOND
                        const height = (() => {
                            if (hasMajorEmphasis) {
                                return "100%"
                            }
                            if (hasMinorEmphasis) {
                                return "75%"
                            }
                            return "40%"
                        })()
                        return (
                            <Box
                                key={i}
                                height={height}
                                width={`${widthPerSegment}px`}
                                flexGrow={0}
                                flexShrink={0}
                                flexBasis="auto"
                                position="relative"
                            >
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    height="100%"
                                    width="100%"
                                    borderLeft="1px solid #29324C"
                                />
                                {hasMajorEmphasis && (
                                    <Text
                                        as="span"
                                        position="absolute"
                                        left="5px"
                                        top="40%"
                                        color="rgba(255, 255, 255, 0.3)"
                                        fontSize="sm"
                                    >
                                        {dayjs.duration(time, "seconds").format("mm:ss")}
                                    </Text>
                                )}
                            </Box>
                        )
                    })}
            </Flex>
        )
    }
    return (
        <Box
            width="full"
            height="100%"
            position="relative"
            backgroundColor="var(--primary-bg-color)"
            css={{
                "--timeline-container-height": "150px",
                "--timeline-height": "23px",
                "--controls-offset-width": "20px",
                "--panel-height": "66px",
                "--scrubber-area-height": "50px",
                "--scrubber-grab-height": "28px",
                "--scrubber-grab-width": "13px",
                "--drag-handle-width": `${DRAG_HANDLE_WIDTH}px`,
            }}
        >
            {renderTimeline()}
            {renderIntervalPanels()}
            <Box
                ref={scrubAreaRef}
                onClick={handleClickTimeline}
                position="absolute"
                height="var(--scrubber-area-height)"
                top="0"
                cursor="pointer"
                width="calc(100% - ((var(--drag-handle-width) + var(--controls-offset-width)) * 2))"
                left="calc(var(--drag-handle-width) + var(--controls-offset-width))"
                data-group
            >
                <TutorialStepWrapper
                    tutorialKey={EEditorTimelineSteps.EditorTimeline}
                    nextTutorialKey={EEditorTutorialSteps.EditorTutorialInfo}
                    popoverPlacement="top"
                    placeholderPosition={{ left: "25%", top: "-60%" }}
                />
                <Box
                    position="absolute"
                    height="100%"
                    width="var(--scrubber-grab-width)"
                    top="0"
                    // True position minus half the width of the handle
                    left="calc(var(--seek-percentage, 0%) - 1 * ((var(--scrubber-grab-width)) / 2))"
                >
                    <Box
                        width="1px"
                        height="calc(var(--timeline-container-height) - var(--scrubber-grab-height) + 1px)"
                        position="absolute"
                        top="calc(var(--scrubber-grab-height) - 1px)"
                        left="calc((100% - 1px) / 2)"
                        backgroundColor="#02b788"
                    />
                    <ChakraImage
                        src="/images/snippets/scrubber.svg"
                        alt="timeline scrubber"
                        height="var(--scrubber-grab-height)"
                        width="var(--scrubber-grab-width)"
                        position="absolute"
                        top="0"
                        left="0"
                        opacity="1"
                    />
                </Box>
                <Box
                    ref={mousePositionLineRef}
                    backgroundColor="rgba(255, 255, 255, 0.3)"
                    position="absolute"
                    width="1px"
                    height="var(--timeline-container-height)"
                    top="0"
                    left="0"
                    willChange="transform"
                    // Show based on parent hover
                    display="none"
                    _groupHover={{
                        display: "block",
                    }}
                />
            </Box>
        </Box>
    )
}

export default EditorTimeline
