import {
    EBrandPunctuationOption,
    EBrandStyleSection,
    ECaptionFont,
    ECaptionPlacement,
    ECaptionStyle,
    EFontWeight,
    ETextStyleTemplateName,
    TBrandStyle,
    TBrandStylingObject,
    TSupportedFontWeight,
} from "../@types/brand_style_types"

export const CAPTION_STYLE_TEMPLATE_MAP = {
    [ETextStyleTemplateName.Classic]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.Montserrat,
        fontWeight: "900",
        fontSize: 38,
        fillColor: "#FFFFFF",
        backgroundColor: "none",
        isItalic: false,
        isUppercase: true,
        lineHeight: 1.3,
        shadowColor: "#000000",
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 3,
        cornerRadius: 0,
        strokeWidth: 6,
        strokeColor: "#000000",
        backgroundOpacity: 0,
    },
    [ETextStyleTemplateName.BrightLights]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.ClimateCrisis,
        fontSize: 34,
        fontWeight: "400",
        isItalic: false,
        isUppercase: false,
        lineHeight: 1.3,
        fillColor: "#FCE837",
        backgroundColor: "none",
        shadowColor: "#D9A41B",
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 16,
        cornerRadius: 0,
        strokeWidth: 3,
        strokeColor: "#D9A41B",
        backgroundOpacity: 0,
    },
    [ETextStyleTemplateName.HandDrawn]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.PermanentMarker,
        fontWeight: "400",
        isItalic: false,
        fontSize: 38,
        fillColor: "#FFFFFF",
        backgroundColor: "none",
        isUppercase: false,
        shadowColor: "#000000",
        shadowOffsetX: 0,
        shadowOffsetY: 5,
        shadowBlur: 8,
        cornerRadius: 0,
        strokeWidth: 0,
        strokeColor: "#000000",
        backgroundOpacity: 0,
    },
    [ETextStyleTemplateName.SuperSerif]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.Caprasimo,
        fontWeight: "400",
        isItalic: false,
        fontSize: 40,
        fillColor: "#A5FFF4",
        backgroundColor: "none",
        isUppercase: false,
        shadowColor: "none",
        cornerRadius: 0,
        strokeWidth: 6,
        strokeColor: "#168C8C",
        backgroundOpacity: 0,
    },
    [ETextStyleTemplateName.Backdrop]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.BebasNeue,
        fontWeight: "400",
        isItalic: false,
        fontSize: 32,
        fillColor: "#FFFFFF",
        backgroundColor: "#000000",
        isUppercase: true,
        shadowColor: "none",
        cornerRadius: 10,
        strokeWidth: 0,
        strokeColor: "#000000",
        backgroundOpacity: 1,
    },
    [ETextStyleTemplateName.TheBoldOne]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.TheBoldOne,
        fontWeight: "700",
        isItalic: false,
        fontSize: 38,
        fillColor: "#FFFFFF",
        backgroundColor: "none",
        isUppercase: false,
        shadowColor: "none",
        cornerRadius: 0,
        strokeWidth: 5,
        strokeColor: "#5F5CFB",
        backgroundOpacity: 0,
    },
    [ETextStyleTemplateName.RoundAndSoft]: {
        position: ECaptionPlacement.Bottom,
        fontFamily: ECaptionFont.BagelFatOne,
        fontWeight: "400",
        isItalic: false,
        isUppercase: false,
        fontSize: 40,
        fillColor: "#FBDA2C",
        backgroundColor: "none",
        shadowColor: "none",
        cornerRadius: 0,
        strokeWidth: 5,
        strokeColor: "#000000",
        backgroundOpacity: 0,
    },
} as const

export const DEFAULT_BRAND_NAME = "Default brand style"
export const DEFAULT_BRAND_CAPTION_STYLE = ECaptionStyle.SingleLine
export const DEFAULT_BRAND_CAPTION_PLACEMENT = ECaptionPlacement.Bottom
export const DEFAULT_BRAND_PUNCTUATION = EBrandPunctuationOption.None
export const DEFAULT_BRAND_CSS_STYLES: TBrandStylingObject = {
    ...CAPTION_STYLE_TEMPLATE_MAP[ETextStyleTemplateName.Classic],
}
export const DEFAULT_BRAND_STYLE: TBrandStyle = {
    [EBrandStyleSection.General]: DEFAULT_BRAND_CSS_STYLES,
}

const MIN_FONT_SIZE = 20
const MAX_FONT_SIZE = 70
export const SUPPORTED_FONTS = Object.values(ECaptionFont)
export const SUPPORTED_FONT_SIZES = Array.from(
    { length: MAX_FONT_SIZE - MIN_FONT_SIZE + 1 },
    (_, i) => i + MIN_FONT_SIZE,
)
export const SUPPORTED_FONT_WEIGHTS_MAP = Object.entries(EFontWeight).reduce(
    (acc, [key, value]) => {
        acc[value] = key
        return acc
    },
    {} as Record<TSupportedFontWeight, string>,
)
