import { useState } from "react"

import { validateFields, Validation, ValidationError } from "../utils/validation_util"

export const useForm = <T extends Record<keyof T, any>>({
    onSubmit,
    validation,
    initialValues,
}: {
    onSubmit: (data: T) => void | Promise<void>
    validation?: Partial<Record<keyof T, Validation>>
    initialValues?: Partial<T>
}) => {
    const [data, setData] = useState<T>((initialValues || {}) as T)
    const [errors, setErrors] = useState<ValidationError<T>>({})

    const handleChange =
        (key: keyof T, format?: (value: string) => string) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
            updateFormData(key, format ? format(e.currentTarget.value) : e.currentTarget.value)
            clearExistingError(key)
        }

    const handleBooleanChange =
        (key: keyof T) =>
        <T extends { target: { checked: boolean } }>(e: T) => {
            updateFormData(key, e.target.checked)
            clearExistingError(key)
        }

    const handleStringChange = (key: keyof T) => (valueAsString: string) => {
        updateFormData(key, valueAsString)
        clearExistingError(key)
    }

    const handleNumberChange = (key: keyof T) => (value: number) => {
        updateFormData(key, value)
        clearExistingError(key)
    }

    const handleFileChange = (key: keyof T) => (file: File | null) => {
        updateFormData(key, file)
        clearExistingError(key)
    }

    const handleObjectChange = (key: keyof T) => (obj: Record<string, any>) => {
        updateFormData(key, obj)
        clearExistingError(key)
    }

    const updateFormData = (
        key: keyof T,
        value: string | number | boolean | File | Record<string, any> | null,
    ) => {
        setData((prev) => ({ ...prev, [key]: value }))
        clearExistingError(key)
    }

    const clearExistingError = (key: keyof T) => {
        if (!errors[key]) {
            return
        }
        setErrors({ ...errors, [key]: undefined })
    }

    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault()
        setErrors({})

        if (!isFormValid()) {
            return
        }

        onSubmit(data)
    }

    const isFormValid = (): boolean => {
        if (!validation) {
            return true
        }

        const { isValid, errors } = validateFields({ validation, data })

        if (!isValid && errors) {
            setErrors(errors)
        }

        return isValid
    }

    const resetFormData = () => {
        setData((initialValues || {}) as T)
    }

    const reInitialiseForm = (newFormData: T) => {
        setData(newFormData)
        setErrors({})
    }

    const resetErrors = () => {
        setErrors({})
    }

    return {
        data,
        errors,
        handleChange,
        handleStringChange,
        handleBooleanChange,
        handleNumberChange,
        handleFileChange,
        handleObjectChange,
        handleSubmit,
        resetFormData,
        isFormValid,
        resetErrors,
        reInitialiseForm,
    }
}
