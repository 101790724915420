export enum EBrandStyleSection {
    General = "general",
    Word = "word",
    ActiveWord = "active-word",
}

export type TBrandStylingObject = {
    position: ECaptionPlacement

    // Lettering
    fontSize: number
    fontFamily: string
    fontWeight: string
    lineHeight: number
    isItalic: boolean
    isUppercase: boolean
    
    // Text styles
    fillColor: string
    strokeColor: string
    strokeWidth: number
    shadowOffsetX: number
    shadowOffsetY: number
    shadowBlur: number
    shadowColor: string
    
    // Background styles
    backgroundColor: string
    backgroundOpacity: number
    cornerRadius: number
}

export enum EBrandPunctuationOption {
    None = "none",
    Regular = "regular",
    Special = "special",
}

export type TBrandStyle = {
    [EBrandStyleSection.General]: TBrandStylingObject
}

export enum ECaptionStyle {
    SingleWord = "single-word",
    SingleLine = "single-line",
    MultipleLines = "multiple-lines",
}

export enum ETextStyleTemplateName {
    Classic = "Classic",
    BrightLights = "Bright Lights",
    HandDrawn = "Hand Drawn",
    SuperSerif = "SuperSerif",
    Backdrop = "Backdrop",
    TheBoldOne = "The Bold One",
    RoundAndSoft = "Round And Soft",
    Custom = "Custom",
}

export enum ECaptionPlacement {
    Bottom = "bottom",
    Middle = "middle",
    Top = "top",
    // Custom = "custom",
}

export type TSupportedFontWeight = "300" | "400" | "500" | "600" | "700" | "800" | "900"

export enum EFontWeight {
    Light = "300",
    Normal = "400",
    Medium = "500",
    SemiBold = "600",
    Bold = "700",
    ExtraBold = "800",
    Black = "900",
}

export enum ECaptionFont {
    Montserrat = "Montserrat",
    Caprasimo = "Caprasimo",
    BebasNeue = "Bebas Neue",
    PermanentMarker = "Permanent Marker",
    BagelFatOne = "Bagel Fat One",
    ClimateCrisis = "Climate Crisis",
    TheBoldOne = "The Bold Font",
    Bangers = "Bangers",
    Bungee = "Bungee",
    Gloock = "Gloock",
    Koulen = "Koulen",
    Poppins = "Poppins",
    RedHatDisplay = "Red Hat Display",
    Syne = "Syne",
    Unbounded = "Unbounded",
}

