import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { BrandStyle } from "@prisma/client"
import { produce } from "immer"
import React, {
    ChangeEvent,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"

import {
    EBrandPunctuationOption,
    EBrandStyleSection,
    ECaptionFont,
    ECaptionPlacement,
    ECaptionStyle,
    ETextStyleTemplateName,
    TBrandStyle,
} from "../../@types/brand_style_types"
import { XOR } from "../../@types/utility_types"
import {
    CAPTION_STYLE_TEMPLATE_MAP,
    DEFAULT_BRAND_CAPTION_STYLE,
    DEFAULT_BRAND_CSS_STYLES,
    DEFAULT_BRAND_NAME,
    DEFAULT_BRAND_PUNCTUATION,
    SUPPORTED_FONT_SIZES,
    SUPPORTED_FONT_WEIGHTS_MAP,
    SUPPORTED_FONTS,
} from "../../configs/brand_style_config"
import AlertController from "../../controllers/alert_controller"
import { getAvailableWeights } from "../../hooks/useFontFamily"
import { useForm } from "../../hooks/useForm"
import api from "../../services/root_service"
import { capitalizeFirstLetter } from "../../utils/string_util"
import { SwitchInput } from "../SwitchInput"
import BrandForm from "./BrandForm"

type TBrandStyleForm = {
    // Metadata
    name: string
    isDefault: boolean
    showCaptions: boolean
    styleTemplate: ETextStyleTemplateName

    // Formatting
    captionStyle: ECaptionStyle
    showPunctuationOptions: boolean
    punctuationOption: EBrandPunctuationOption

    // Placement
    position: ECaptionPlacement

    // Lettering
    fontSize: number
    fontFamily: string
    fontWeight: string
    lineHeight: number
    isItalic: boolean
    isUppercase: boolean

    // Text styles
    fillColor: string
    strokeColor: string
    strokeWidth: number
    shadowOffsetX: number
    shadowOffsetY: number
    shadowBlur: number
    shadowColor: string

    // Background styles
    backgroundColor: string
    backgroundOpacity: number
    cornerRadius: number
}

export const DEFAULT_FORM_BRAND_STYLE: TBrandStyleForm = {
    name: DEFAULT_BRAND_NAME,
    isDefault: true,
    showCaptions: true,

    showPunctuationOptions: false,
    punctuationOption: DEFAULT_BRAND_PUNCTUATION,
    captionStyle: DEFAULT_BRAND_CAPTION_STYLE,
    styleTemplate: ETextStyleTemplateName.Classic,

    ...DEFAULT_BRAND_CSS_STYLES,
}

const DEFAULT_PUNCTUATION_TOGGLE = false
const DEFAULT_TEXT_BACKGROUND = "#000000"
const DEFAULT_TEXT_BACKGROUND_OPACITY = 0.4
const DEFAULT_TEXT_BACKGROUND_RADIUS = 10
const DEFAULT_TEXT_SHADOW_COLOR = "#000000"
const DEFAULT_TEXT_STROKE_COLOR = "#000000"
export const BRAND_STYLE_FORM__NEW_BRAND_KEY = "new-brand"

const convertBrandStyleToForm = (brand: BrandStyle): TBrandStyleForm => {
    const { name, isDefault, showCaptions, captionStyle, punctuationOption, styles } = brand
    const otherStyles = (styles as TBrandStyle)[EBrandStyleSection.General]

    // @ts-ignore
    const allowPunctuation =
        punctuationOption !== EBrandPunctuationOption.None ?? DEFAULT_PUNCTUATION_TOGGLE
    const formInput: Partial<TBrandStyleForm> = {
        name,
        isDefault,
        showCaptions,
        captionStyle: captionStyle as ECaptionStyle,
        position: otherStyles.position,
        showPunctuationOptions: allowPunctuation,
        punctuationOption: punctuationOption as unknown as EBrandPunctuationOption,
        ...(otherStyles as Partial<TBrandStyleForm>),
    }
    return formInput as TBrandStyleForm
}
const applyFormChangesToBrandStyle = (brand: BrandStyle, form: TBrandStyleForm): BrandStyle => {
    return produce(brand, (draft) => {
        const {
            name,
            isDefault,
            showCaptions,
            captionStyle,
            styleTemplate,
            showPunctuationOptions: allowPunctuation,
            punctuationOption,
            ...formStyles
        } = form

        // Update brand options
        draft.name = name
        draft.isDefault = isDefault
        draft.showCaptions = showCaptions
        draft.captionStyle = captionStyle
        draft.captionPlacement = formStyles.position
        draft.punctuationOption = punctuationOption as unknown as string

        // Update css styles
        const styling = draft.styles as TBrandStyle
        const prevStyles = styling[EBrandStyleSection.General] as Record<string, any>
        for (const key in formStyles) {
            // @ts-ignore
            prevStyles[key] = formStyles[key]
        }
    })
}
const detectChangesBetweenStyleAndForm = (
    prev: TBrandStyleForm,
    next: TBrandStyleForm,
): boolean => {
    const prevStyles = prev as Record<string, any>
    const nextStyles = next as Record<string, any>
    for (const key in prevStyles) {
        if (prevStyles[key] !== nextStyles[key]) {
            return true
        }
    }
    return false
}
const findMatchingCaptionTemplate = (form: TBrandStyleForm): ETextStyleTemplateName => {
    const templates = Object.values(ETextStyleTemplateName)
    for (const templateName of templates) {
        if (templateName === ETextStyleTemplateName.Custom) {
            continue
        }
        const template =
            CAPTION_STYLE_TEMPLATE_MAP[templateName as keyof typeof CAPTION_STYLE_TEMPLATE_MAP]
        if (!template) {
            continue
        }
        // @ts-ignore
        const allMatch = Object.keys(template)
            .filter(Boolean)
            .map((k: keyof typeof template) => template[k] === form[k])
            .every((v) => v === true)
        if (allMatch) {
            return templateName as keyof typeof CAPTION_STYLE_TEMPLATE_MAP
        }
    }
    return ETextStyleTemplateName.Custom
}

export interface IBrandStylesEditorRef {
    hasChanges: () => boolean
    triggerSaveChanges: () => Promise<void>
}

interface IBrandStylesEditorProps {
    // Initially undefined - until the preview data comes through
    selectedId?: string
    brands: BrandStyle[]
    onBrandStylesChange: (brand: BrandStyle, prevId?: string) => void
    onChangeSelectedBrand: (args: XOR<{ id: string }, { brand: BrandStyle }>) => void
    onBrandSaved?: (brand: BrandStyle) => void
}

const BrandStylesEditor = React.forwardRef(
    (
        {
            selectedId,
            brands,
            onBrandStylesChange,
            onChangeSelectedBrand,
            onBrandSaved,
        }: IBrandStylesEditorProps,
        ref,
    ) => {
        const selectedBrand = brands.find((s) => s.id === selectedId)
        const [initialBrands, setInitialBrands] = useState<BrandStyle[]>(brands)
        const [errorMessage, setErrorMessage] = useState<string>("")
        const fontColorRef = useRef<HTMLInputElement | null>(null)
        const strokeColorRef = useRef<HTMLInputElement | null>(null)
        const shadowColorRef = useRef<HTMLInputElement | null>(null)
        const bgColorRef = useRef<HTMLInputElement | null>(null)
        const formHooks = useForm<TBrandStyleForm>({
            validation: {},
            initialValues: selectedBrand ? convertBrandStyleToForm(selectedBrand!) : {},
            onSubmit: async (data) => {
                try {
                    const brandData = createRequestBrandForm(selectedBrand!, data)
                    const isNewBrand = selectedId!.includes(BRAND_STYLE_FORM__NEW_BRAND_KEY)
                    setErrorMessage("")
                    setLoading(true)
                    let result: { brand: BrandStyle }
                    if (isNewBrand) {
                        result = await api().postCreateBrandStyle({
                            name: brandData.name,
                            isDefault: brandData.isDefault,
                            showCaptions: brandData.showCaptions,
                            captionStyle: brandData.captionStyle as ECaptionStyle,
                            captionPlacement: brandData.captionPlacement as ECaptionPlacement,
                            punctuationOption:
                                brandData.punctuationOption as unknown as EBrandPunctuationOption,
                            styles: brandData.styles as TBrandStyle,
                        })
                        const output = convertBrandStyleToForm(result.brand)
                        onBrandStylesChange(result.brand, selectedId)
                        onChangeSelectedBrand({ id: result.brand.id })
                        reInitialiseForm(output)
                    } else {
                        result = await api().postUpdateBrandStyle({
                            brandId: brandData.id,
                            name: brandData.name,
                            isDefault: brandData.isDefault,
                            showCaptions: brandData.showCaptions,
                            captionStyle: brandData.captionStyle as ECaptionStyle,
                            captionPlacement: brandData.captionPlacement as ECaptionPlacement,
                            punctuationOption:
                                brandData.punctuationOption as unknown as EBrandPunctuationOption,
                            styles: brandData.styles as TBrandStyle,
                        })
                    }
                    setInitialBrands((prev) => {
                        const updatedBrands = prev.map((b) => {
                            if (b.id === selectedId) {
                                return result.brand
                            }
                            return b
                        })
                        return updatedBrands
                    })
                    setLoading(false)
                    setHasChanged(false)
                    if (!!onBrandSaved) {
                        onBrandSaved(result.brand)
                    }
                } catch (e: any) {
                    setErrorMessage(e.message)
                }
            },
        })
        const {
            data,
            handleChange,
            handleStringChange,
            handleNumberChange,
            handleBooleanChange,
            handleSubmit,
            reInitialiseForm,
        } = formHooks
        const [showCustomTextStyle, setShowCustomTextStyle] = useState<boolean>(
            data.styleTemplate === ETextStyleTemplateName.Custom,
        )
        const [hasChanged, setHasChanged] = useState<boolean>(false)
        const [isLoading, setLoading] = useState<boolean>(false)

        const supportedFontWeights = useMemo(() => {
            if (!data.fontFamily) {
                return []
            }
            const weights = getAvailableWeights(data.fontFamily as ECaptionFont)
            return weights.map((weight) => ({
                value: weight,
                display: SUPPORTED_FONT_WEIGHTS_MAP[weight],
            }))
        }, [data.fontFamily])

        useEffect(() => {
            if (!selectedBrand) {
                return
            }
            const updatedBrandStyle = applyFormChangesToBrandStyle(selectedBrand, data)
            onBrandStylesChange(updatedBrandStyle)

            const initialBrandVersion = initialBrands.find((s) => s.id === selectedId)!
            const hasChanged = detectChangesBetweenStyleAndForm(
                convertBrandStyleToForm(initialBrandVersion),
                data,
            )
            setHasChanged(hasChanged)
        }, [data, initialBrands, selectedId])

        // Update selected style template when styles change
        useEffect(() => {
            if (!data || !data.styleTemplate) {
                return
            }
            const matchingTemplate = findMatchingCaptionTemplate(data)
            if (matchingTemplate !== data.styleTemplate) {
                handleStringChange("styleTemplate")(matchingTemplate)
            }
        }, [data])

        const hasChanges = () => {
            return hasChanged
        }
        const triggerSaveChanges = () => {
            return handleSubmit()
        }
        useImperativeHandle(ref, () => ({
            hasChanges,
            triggerSaveChanges,
        }))
        const createRequestBrandForm = (brand: BrandStyle, form: TBrandStyleForm) => {
            let brandStyle = applyFormChangesToBrandStyle(brand, form)
            const generalStyles = {
                ...(brandStyle.styles as TBrandStyle)[EBrandStyleSection.General],
            }
            brandStyle = {
                ...brandStyle,
                styles: {
                    [EBrandStyleSection.General]: {
                        ...generalStyles,
                    },
                },
            }
            return brandStyle
        }
        const switchOrCreateBrand = async (brandId: string) => {
            let change: XOR<{ id: string }, { brand: BrandStyle }>
            let brandForm: TBrandStyleForm
            if (brandId === BRAND_STYLE_FORM__NEW_BRAND_KEY) {
                const newCount = brands.filter((s) =>
                    s.name.includes(BRAND_STYLE_FORM__NEW_BRAND_KEY),
                ).length
                const newBrandId = `${BRAND_STYLE_FORM__NEW_BRAND_KEY}-${newCount + 1}`
                const brandName = `New brand ${newCount > 0 ? newCount + 1 : ""}`
                const updateBrand = {
                    ...applyFormChangesToBrandStyle(brands[0], DEFAULT_FORM_BRAND_STYLE),
                    id: newBrandId,
                    name: brandName,
                }
                change = {
                    brand: updateBrand,
                }
                brandForm = convertBrandStyleToForm(updateBrand)
                setInitialBrands((prev) => [...prev, updateBrand])
            } else {
                change = { id: brandId }
                brandForm = convertBrandStyleToForm(brands.find((s) => s.id === brandId)!)
            }
            reInitialiseForm(brandForm)
            setHasChanged(false)
            onChangeSelectedBrand(change)
        }
        const handleChangeSelectedBrand = (event: ChangeEvent<HTMLSelectElement>) => {
            const brandId = event.target.value
            if (hasChanged) {
                return AlertController.show({
                    title: "Are you sure you want to switch brands without saving?",
                    message: "If you do not save your changes, they will be lost.",
                    primaryAction: {
                        label: "Switch",
                        onClick: () => switchOrCreateBrand(brandId),
                    },
                    secondaryAction: {
                        label: "Cancel",
                    },
                    isDismissable: true,
                    variant: "danger",
                })
            }
            switchOrCreateBrand(brandId)
        }
        const handleChangeTextStyleTemplate = (event: ChangeEvent<HTMLSelectElement>) => {
            const templateName = event.target.value as ETextStyleTemplateName
            // @ts-ignore
            const template = CAPTION_STYLE_TEMPLATE_MAP[templateName] as Partial<TBrandStyleForm>
            const updated = {
                ...data,
                ...template,
                styleTemplate: templateName,
            }
            reInitialiseForm(updated)
        }
        const handleCaptionPlacementOnChange =
            (option: ECaptionPlacement) => (e: React.MouseEvent<HTMLButtonElement>) => {
                handleStringChange("position")(option)
            }
        const handleColorChange =
            (key: keyof TBrandStyleForm) => (e: ChangeEvent<HTMLInputElement>) => {
                const colorValue = e.currentTarget.value
                const colorHex = colorValue.includes("#") ? colorValue : `#${colorValue}`
                handleStringChange(key)(colorHex)
            }
        const handleUpdateFontStroke = () => {
            if (data.strokeColor === "none") {
                handleStringChange("strokeColor")(DEFAULT_TEXT_STROKE_COLOR)
            } else {
                handleStringChange("strokeColor")("none")
            }
        }
        const handleToggleFontShadow = () => {
            if (data.shadowColor === "none") {
                handleStringChange("shadowColor")(DEFAULT_TEXT_SHADOW_COLOR)
            } else {
                handleStringChange("shadowColor")("none")
            }
        }
        const handleUpdateTextBackground = () => {
            if (data.backgroundColor === "none") {
                handleStringChange("backgroundColor")(DEFAULT_TEXT_BACKGROUND)
                handleNumberChange("backgroundOpacity")(DEFAULT_TEXT_BACKGROUND_OPACITY)
                handleNumberChange("cornerRadius")(DEFAULT_TEXT_BACKGROUND_RADIUS)
            } else {
                handleStringChange("backgroundColor")("none")
            }
        }
        const handleTogglePunctuationVisibility = (e: ChangeEvent<HTMLInputElement>) => {
            const willHide = data.showPunctuationOptions
            if (willHide) {
                handleStringChange("punctuationOption")(
                    EBrandPunctuationOption.None as unknown as string,
                )
            } else {
                handleStringChange("punctuationOption")(
                    EBrandPunctuationOption.Regular as unknown as string,
                )
            }
            handleBooleanChange("showPunctuationOptions")(e)
        }
        const renderCaptionPlacement = () => {
            const captionValues = Object.values(ECaptionPlacement)
            return (
                <BrandForm.Group>
                    <BrandForm.FieldTitle>Captions position</BrandForm.FieldTitle>
                    <BrandForm.PillContainer width="fit-content">
                        <BrandForm.ButtonCell
                            onClick={handleCaptionPlacementOnChange(captionValues[0])}
                            selected={data.position === captionValues[0]}
                            hasLeftOption={false}
                            hasRightOption={true}
                            fontWeight="normal"
                            width="80px"
                        >
                            {captionValues[0]}
                        </BrandForm.ButtonCell>
                        <BrandForm.ButtonCell
                            onClick={handleCaptionPlacementOnChange(captionValues[1])}
                            selected={data.position === captionValues[1]}
                            hasLeftOption={true}
                            hasRightOption={true}
                            fontWeight="normal"
                            width="80px"
                        >
                            {captionValues[1]}
                        </BrandForm.ButtonCell>
                        <BrandForm.ButtonCell
                            onClick={handleCaptionPlacementOnChange(captionValues[2])}
                            selected={data.position === captionValues[2]}
                            hasLeftOption={true}
                            hasRightOption={false}
                            fontWeight="normal"
                            width="80px"
                        >
                            {captionValues[2]}
                        </BrandForm.ButtonCell>
                    </BrandForm.PillContainer>
                </BrandForm.Group>
            )
        }
        const renderFontStyle = () => {
            return (
                <BrandForm.Group>
                    <BrandForm.FieldTitle>Font</BrandForm.FieldTitle>
                    <BrandForm.PillContainer>
                        <BrandForm.SelectCell
                            title="Font family"
                            value={data.fontFamily}
                            onChange={handleChange("fontFamily")}
                            hasLeftOption={false}
                            hasRightOption={true}
                        >
                            {SUPPORTED_FONTS.map((ff) => (
                                <option value={ff} key={ff}>
                                    {ff}
                                </option>
                            ))}
                        </BrandForm.SelectCell>
                        <BrandForm.SelectCell
                            title="Font weight"
                            value={data.fontWeight}
                            onChange={handleChange("fontWeight")}
                            hasLeftOption={true}
                            hasRightOption={true}
                        >
                            {supportedFontWeights.map((weight) => (
                                <option value={weight.value} key={weight.value}>
                                    {weight.display}
                                </option>
                            ))}
                        </BrandForm.SelectCell>
                        <BrandForm.SelectCell
                            title="Font size"
                            value={data.fontSize}
                            onChange={(e) =>
                                handleNumberChange("fontSize")(parseInt(e.target.value))
                            }
                            hasLeftOption={true}
                            hasRightOption={true}
                        >
                            {SUPPORTED_FONT_SIZES.map((ff) => (
                                <option value={ff} key={ff}>
                                    {ff}
                                </option>
                            ))}
                        </BrandForm.SelectCell>
                        <BrandForm.ButtonCell
                            onClick={() =>
                                handleBooleanChange("isItalic")({
                                    target: { checked: !data.isItalic },
                                })
                            }
                            aria-label="italic"
                            selected={data.isItalic}
                            hasLeftOption={true}
                            hasRightOption={false}
                            fontStyle="italic"
                        >
                            I
                        </BrandForm.ButtonCell>
                    </BrandForm.PillContainer>
                </BrandForm.Group>
            )
        }
        const renderFontStroke = () => {
            const showTextStroke = data.strokeColor !== "none"
            return (
                <>
                    <BrandForm.SwitchGroup>
                        <BrandForm.FieldTitle>Text stroke</BrandForm.FieldTitle>
                        <SwitchInput isChecked={showTextStroke} onChange={handleUpdateFontStroke} />
                    </BrandForm.SwitchGroup>

                    <BrandForm.GroupExpansion isExpanded={showTextStroke}>
                        <BrandForm.ColorPicker
                            name="stroke-color"
                            pickerRef={strokeColorRef}
                            value={data.strokeColor}
                            onChange={handleColorChange("strokeColor")}
                        />
                        <BrandForm.Slider
                            min={0}
                            max={30}
                            value={data.strokeWidth}
                            onChange={handleNumberChange("strokeWidth")}
                            title="Thickness"
                            aria-label="stroke thickness slider"
                        />
                    </BrandForm.GroupExpansion>
                </>
            )
        }
        const renderFontShadow = () => {
            const showShadow = data.shadowColor !== "none"
            return (
                <>
                    <BrandForm.SwitchGroup>
                        <BrandForm.FieldTitle>Text shadow</BrandForm.FieldTitle>
                        <SwitchInput isChecked={showShadow} onChange={handleToggleFontShadow} />
                    </BrandForm.SwitchGroup>

                    <BrandForm.GroupExpansion isExpanded={showShadow}>
                        <BrandForm.ColorPicker
                            name="shadow-color"
                            pickerRef={shadowColorRef}
                            value={data.shadowColor}
                            onChange={handleColorChange("shadowColor")}
                        />
                        <BrandForm.Slider
                            title="X"
                            aria-label="shadow x slider"
                            min={0}
                            max={15}
                            value={data.shadowOffsetX}
                            onChange={handleNumberChange("shadowOffsetX")}
                        />
                        <BrandForm.Slider
                            title="Y"
                            aria-label="shadow y slider"
                            min={0}
                            max={15}
                            value={data.shadowOffsetY}
                            onChange={handleNumberChange("shadowOffsetY")}
                        />
                        <BrandForm.Slider
                            title="Blur"
                            aria-label="shadow blur slider"
                            min={0}
                            max={15}
                            value={data.shadowBlur}
                            onChange={handleNumberChange("shadowBlur")}
                        />
                    </BrandForm.GroupExpansion>
                </>
            )
        }
        const renderFontBackground = () => {
            const showBackground = data.backgroundColor !== "none"
            return (
                <>
                    <BrandForm.SwitchGroup>
                        <BrandForm.FieldTitle>Text background</BrandForm.FieldTitle>
                        <SwitchInput
                            isChecked={showBackground}
                            onChange={handleUpdateTextBackground}
                        />
                    </BrandForm.SwitchGroup>

                    <BrandForm.GroupExpansion isExpanded={showBackground}>
                        <BrandForm.ColorPicker
                            name="background-color"
                            pickerRef={bgColorRef}
                            value={data.backgroundColor}
                            onChange={handleColorChange("backgroundColor")}
                        />
                        <BrandForm.Slider
                            title="Roundness"
                            aria-label="roundness slider"
                            min={0}
                            max={50}
                            value={data.cornerRadius}
                            onChange={handleNumberChange("cornerRadius")}
                        />
                        <BrandForm.Slider
                            title="Transparency"
                            aria-label="transparency slider"
                            min={0}
                            max={1}
                            step={0.1}
                            value={data.backgroundOpacity}
                            onChange={handleNumberChange("backgroundOpacity")}
                        />
                    </BrandForm.GroupExpansion>
                </>
            )
        }
        const renderCustomTextTemplate = () => {
            return (
                <>
                    <BrandForm.Group flexDirection="column">
                        <BrandForm.FieldTitle>Text style template</BrandForm.FieldTitle>
                        <BrandForm.Select
                            onChange={handleChangeTextStyleTemplate}
                            value={data.styleTemplate}
                        >
                            {Object.values(ETextStyleTemplateName).map((template) => (
                                <option value={template} key={template}>
                                    {template}
                                </option>
                            ))}
                        </BrandForm.Select>
                    </BrandForm.Group>

                    <BrandForm.SwitchGroup marginTop="-20px">
                        <BrandForm.FieldTitle fontWeight="normal" fontSize="md">
                            Customise this style
                        </BrandForm.FieldTitle>
                        <SwitchInput
                            isChecked={showCustomTextStyle}
                            onChange={() => setShowCustomTextStyle((prev) => !prev)}
                        />
                    </BrandForm.SwitchGroup>

                    <BrandForm.GroupExpansion isExpanded={showCustomTextStyle} gap={4}>
                        {renderFontStyle()}

                        <BrandForm.Group>
                            <BrandForm.FieldTitle>Text color</BrandForm.FieldTitle>
                            <BrandForm.ColorPicker
                                name="font-color"
                                pickerRef={fontColorRef}
                                value={data.fillColor}
                                onChange={handleColorChange("fillColor")}
                            />
                        </BrandForm.Group>

                        {renderFontStroke()}

                        {renderFontShadow()}

                        {renderFontBackground()}
                    </BrandForm.GroupExpansion>
                </>
            )
        }
        const renderCaptionsOptions = () => {
            if (!data.showCaptions) {
                return null
            }
            return (
                <>
                    <BrandForm.SwitchGroup>
                        <BrandForm.FieldTitle>Show punctuation</BrandForm.FieldTitle>
                        <SwitchInput
                            isChecked={data.showPunctuationOptions}
                            onChange={handleTogglePunctuationVisibility}
                        />
                    </BrandForm.SwitchGroup>
                    {data.showPunctuationOptions && (
                        <BrandForm.Group marginTop="-15px">
                            <BrandForm.Select
                                value={data.punctuationOption}
                                onChange={handleChange("punctuationOption")}
                            >
                                <option
                                    value={EBrandPunctuationOption.Regular}
                                    key={EBrandPunctuationOption.Regular}
                                    color="#060B1A"
                                >
                                    {`Regular Punctuation (. and ,)`}
                                </option>
                                <option
                                    value={EBrandPunctuationOption.Special}
                                    key={EBrandPunctuationOption.Special}
                                    color="#060B1A"
                                >
                                    {`Special Punctuation (! and ?)`}
                                </option>
                            </BrandForm.Select>
                        </BrandForm.Group>
                    )}
                    <BrandForm.Group>
                        <BrandForm.FieldTitle>Captions style</BrandForm.FieldTitle>
                        <BrandForm.Select
                            value={data.captionStyle}
                            onChange={handleChange("captionStyle")}
                        >
                            {Object.values(ECaptionStyle).map((captionStyle) => {
                                const formattedStyleName = capitalizeFirstLetter(
                                    captionStyle.replace("-", " "),
                                )
                                return (
                                    <option value={captionStyle} key={captionStyle} color="#060B1A">
                                        {formattedStyleName}
                                    </option>
                                )
                            })}
                        </BrandForm.Select>
                    </BrandForm.Group>

                    {renderCaptionPlacement()}

                    {renderCustomTextTemplate()}

                    <BrandForm.SwitchGroup>
                        <BrandForm.FieldTitle>Uppercase all text</BrandForm.FieldTitle>
                        <SwitchInput
                            isChecked={data.isUppercase}
                            onChange={handleBooleanChange("isUppercase")}
                        />
                    </BrandForm.SwitchGroup>

                    <Text
                        marginTop="-20px"
                        fontSize="md"
                        color="rgba(255, 255, 255, 0.6)"
                        lineHeight={1.3}
                    >
                        Convert all captions to uppercase. Please note that some fonts can only
                        display uppercase characters.
                    </Text>
                </>
            )
        }
        return (
            // @ts-ignore
            <Box as="form" onSubmit={handleSubmit} width="full">
                <Flex
                    flexDirection="column"
                    width="full"
                    padding="24px"
                    height="var(--transcript-editor-height)"
                    lineHeight="24px"
                    position="relative"
                    overflowY="scroll"
                    gap={5}
                    sx={{
                        "::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <BrandForm.Group position="relative" marginBottom="20px">
                        <BrandForm.Select value={selectedId} onChange={handleChangeSelectedBrand}>
                            {brands.map((s: BrandStyle) => (
                                <option value={s.id} key={s.id}>
                                    {s.name}
                                </option>
                            ))}
                            <option
                                value={BRAND_STYLE_FORM__NEW_BRAND_KEY}
                                key={BRAND_STYLE_FORM__NEW_BRAND_KEY}
                            >
                                + Create new brand
                            </option>
                        </BrandForm.Select>
                        <Button
                            variant="primaryGreen"
                            type="submit"
                            position="absolute"
                            top="7px"
                            right="35px"
                            size="sm"
                            borderRadius="4px"
                            loadingText="Saving"
                            spinnerPlacement="end"
                            isLoading={isLoading}
                            isDisabled={isLoading || !hasChanged}
                            title={hasChanged ? "Update brand template" : "No changes to save"}
                        >
                            <Text as="span" color="white">
                                Save
                            </Text>
                        </Button>
                        {errorMessage && <Text color="#ff4242">{errorMessage}</Text>}
                    </BrandForm.Group>
                    <BrandForm.Group>
                        <BrandForm.FieldTitle>Brand name</BrandForm.FieldTitle>
                        <BrandForm.Input value={data.name} onChange={handleChange("name")} />
                    </BrandForm.Group>
                    <BrandForm.SwitchGroup>
                        <BrandForm.FieldTitle>Show captions</BrandForm.FieldTitle>
                        <SwitchInput
                            isChecked={data.showCaptions}
                            onChange={handleBooleanChange("showCaptions")}
                        />
                    </BrandForm.SwitchGroup>
                    {renderCaptionsOptions()}
                </Flex>
            </Box>
        )
    },
)

export default BrandStylesEditor
