import { Box } from "@chakra-ui/react"
import { Stage as StageClass } from "konva/lib/Stage"
import { useEffect, useMemo, useRef } from "react"
import { Layer, Stage } from "react-konva"
import { Sequence, useVideoConfig } from "remotion"

import { TBrandStyle } from "../../../@types/brand_style_types"
import { ICaptionWord, ICaptionWordWithFrames } from "../../../@types/snippet_types"
import useFontFamily from "../../../hooks/useFontFamily"
import { formatCaptions } from "../../../utils/captions_util"
import SingleCaption from "./SingleCaption"

const BASE_RELATIVE_HEIGHT = 720

// Create list of durations from 1 to 10 seconds.
const FONT_REDRAW_DURATIONS = [...Array(10).keys()].map((i) => (i + 1) * 1_000)

const CaptionSequencer = (props: {
    captions: ICaptionWord[]
    fps: number
    brandStyle: TBrandStyle
}) => {
    const { captions, fps, brandStyle } = props
    const stageRef = useRef<StageClass | null>(null)
    const lastLoadedFont = useRef<string | null>(null)
    const font = useFontFamily(brandStyle)

    const allCaptions = useMemo(() => formatCaptions(captions, fps), [captions])

    const config = useVideoConfig()
    const { scaleFactor, scaledWidth } = (() => {
        const scaleFactor = config.height / BASE_RELATIVE_HEIGHT
        return { scaleFactor, scaledWidth: config.width / scaleFactor }
    })()
    const fontFamily = font.info?.fontFamily

    useEffect(() => {
        if (stageRef.current) {
            const context = stageRef.current.getLayers()[0].getContext()._context
            context.font = `36px ${fontFamily}`
        }
    }, [font.info])

    useEffect(() => {
        // Redraw the canvas when a font is loaded.
        const hasChanged = fontFamily !== lastLoadedFont.current
        if (font.status === "loaded" && hasChanged) {
            // When font status changes to loaded, this means a load has been "triggered".
            // The font still needs to be made available to the Canvas API.
            // If the user's internet/computer speed is slow this can take many seconds.
            // For this reason, we trigger a redraw every second for 10 seconds.
            // This ensures the font is shown as soon as it is available.
            for (const duration of FONT_REDRAW_DURATIONS) {
                setTimeout(() => {
                    stageRef.current?.draw()
                }, duration)
            }
            lastLoadedFont.current = fontFamily
        }
    }, [font])

    return (
        <Box
            className="CaptionSequencer"
            display="block"
            position="absolute"
            left="0"
            top="0"
            width="100%"
            height="100%"
            backgroundColor="transparent"
        >
            <Box
                display="block"
                position="absolute"
                left="0"
                top="0"
                width={scaledWidth}
                height={BASE_RELATIVE_HEIGHT}
                transformOrigin="top left"
                transform={`scale(${scaleFactor})`}
                backgroundColor="transparent"
            >
                <Stage ref={stageRef} width={scaledWidth} height={BASE_RELATIVE_HEIGHT}>
                    <Layer>
                        {allCaptions.map((caption: ICaptionWordWithFrames) => {
                            const durationInFrames = caption.endFrame - caption.startFrame
                            if (durationInFrames <= 0) {
                                return null
                            }
                            return (
                                <Sequence
                                    layout="none"
                                    key={caption.startFrame}
                                    from={caption.startFrame}
                                    durationInFrames={durationInFrames}
                                >
                                    <SingleCaption
                                        caption={caption}
                                        brandStyle={brandStyle}
                                        size={{ width: scaledWidth, height: BASE_RELATIVE_HEIGHT }}
                                    />
                                </Sequence>
                            )
                        })}
                    </Layer>
                </Stage>
            </Box>
        </Box>
    )
}

export default CaptionSequencer
