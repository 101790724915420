import { useRouter } from "next/router"
import {
    createContext,
    forwardRef,
    ForwardRefRenderFunction,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react"

interface ITutorialContextProps {
    currentTutorialStep?: ITutorialReadyStep
    registerTutorialStep: (steps: TTutorialInputSteps) => void
    showTutorialStep: (tutorialKey: string) => void
    finishTutorialSteps: () => void
}

export const TutorialContext = createContext<ITutorialContextProps | null>(null)

type TTutorialInputSteps = Record<string, string>

export interface ITutorialReadyStep {
    id: string
    content: string
}

interface ITutorialPageStepsInitial {
    ready: false
    steps: Record<string, never>
}

interface ITutorialPageSteps {
    ready: true
    steps: Record<string, ITutorialReadyStep>
}

interface ITutorialPageStepsMapping {
    [page: string]: ITutorialPageStepsInitial | ITutorialPageSteps
}

interface ITutorialWrapperProps {
    children: ReactNode
    steps?: TTutorialInputSteps
    initialiseStepAfterMount?: string
}

export interface ITutorialControllerRef
    extends Omit<ITutorialContextProps, "currentTutorialStep" | "finishTutorialSteps"> {
    isTutorialReady: boolean
    hasOngoingTutorial: boolean
}

const TutorialWrapperComponent: ForwardRefRenderFunction<
    ITutorialControllerRef,
    ITutorialWrapperProps
> = ({ children, steps, initialiseStepAfterMount: initialiseStepAfterMount }, ref) => {
    const router = useRouter()
    const { pathname: page } = router

    const tutorialStepsRef = useRef<ITutorialPageStepsMapping>({
        [page]: {
            ready: false,
            steps: {},
        },
    })

    const localStorageKey = `${page}-tutorial`

    const isTutorialActiveFromLocalStorageRef = useRef(false)
    const [isReady, setIsReady] = useState(false)
    const [currentStep, setCurrentStep] = useState<ITutorialReadyStep>()

    const showTutorialStep = (tutorialKey: string) => {
        const tutorialStep = tutorialStepsRef.current[page].steps[tutorialKey]
        setCurrentStep(tutorialStep)
        updateLocalStorage({ currentStep: tutorialKey, finished: false })
    }

    const getTutorialValueFromLocalStorage = (key: string) => {
        const localStorageValue = localStorage.getItem(localStorageKey)
        if (!localStorageValue) {
            return
        }
        const tutorialSteps = JSON.parse(localStorageValue)
        return tutorialSteps[key]
    }

    useEffect(() => {
        if (!steps) {
            return
        }
        registerTutorialStep(steps)
    }, [steps])

    useEffect(() => {
        if (!initialiseStepAfterMount || !isReady || isTutorialActiveFromLocalStorageRef.current) {
            return
        }
        const isTutorialFinished = getTutorialValueFromLocalStorage("finished")
        if (isTutorialFinished) {
            return
        }
        const tutorialStep = tutorialStepsRef.current[page].steps[initialiseStepAfterMount]
        showTutorialStep(tutorialStep.id)
    }, [isReady, initialiseStepAfterMount])

    const registerTutorialStep = (steps: TTutorialInputSteps) => {
        const tutorialElements = document.querySelectorAll("[data-tutorial-key]")
        tutorialElements.forEach((element) => {
            const tutorialKey = element.getAttribute("data-tutorial-key")!
            const tutorialStep = steps[tutorialKey]

            if (!tutorialStep || tutorialStepsRef.current[page].steps[tutorialKey]) {
                return
            }

            tutorialStepsRef.current = {
                ...tutorialStepsRef.current,
                [page]: {
                    ready: true,
                    steps: {
                        ...tutorialStepsRef.current[page].steps,
                        [tutorialKey]: {
                            id: tutorialKey,
                            content: tutorialStep,
                        },
                    },
                },
            }
            setIsReady(true)
        })
    }

    useEffect(() => {
        if (!isReady) {
            return
        }
        const currentStepFromLocalStorage = getTutorialValueFromLocalStorage("currentStep")
        const isTutorialFinished = getTutorialValueFromLocalStorage("finished")
        if (isTutorialFinished) {
            setIsReady(false)
            return
        }
        if (!currentStepFromLocalStorage) {
            return
        }
        const tutorialStep = tutorialStepsRef.current[page].steps[currentStepFromLocalStorage]
        isTutorialActiveFromLocalStorageRef.current = true
        setCurrentStep(tutorialStep)
    }, [isReady])

    const updateLocalStorage = (value: object) => {
        const localStorageItem = localStorage.getItem(localStorageKey)
        if (!localStorageItem) {
            localStorage.setItem(localStorageKey, JSON.stringify(value))
            return
        }
        const tutorialSteps = JSON.parse(localStorageItem)
        localStorage.setItem(localStorageKey, JSON.stringify({ ...tutorialSteps, ...value }))
    }

    const finishTutorialSteps = () => {
        updateLocalStorage({ finished: true, currentStep: undefined })
        setIsReady(false)
        setCurrentStep(undefined)
    }

    useImperativeHandle(ref, () => ({
        registerTutorialStep,
        showTutorialStep,
        hasOngoingTutorial: isTutorialActiveFromLocalStorageRef.current,
        isTutorialReady: isReady,
    }))

    return (
        <TutorialContext.Provider
            value={{
                currentTutorialStep: currentStep,
                registerTutorialStep,
                showTutorialStep,
                finishTutorialSteps,
            }}
        >
            {children}
        </TutorialContext.Provider>
    )
}

export const TutorialWrapper = forwardRef(TutorialWrapperComponent)
