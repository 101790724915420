import { round } from "lodash"

import {
    EVideoFormat,
    IClipSchemaItem,
    IClusterInterval,
    LANDSCAPE_ASPECT_RATIO,
    PORTRAIT_ASPECT_RATIO,
} from "../@types/snippet_types"
import _c from "../configs/constants"
import { applySmoothingToIntervals } from "../server/utils/ml_util"
import { sumFields } from "./number_util"

export const getCropDimensionsForFormat = (args: {
    format: EVideoFormat
    sourceHeight: number
    sourceWidth: number
}) => {
    const { format, sourceHeight, sourceWidth } = args
    let croppedWidth: number
    let croppedHeight: number
    if (format === EVideoFormat.Portrait) {
        croppedHeight = sourceHeight
        const isSourcePortrait = sourceWidth < sourceHeight
        if (!isSourcePortrait) {
            croppedWidth = Math.round(sourceHeight * PORTRAIT_ASPECT_RATIO)
        } else {
            croppedWidth = sourceWidth
        }
    } else if (format === EVideoFormat.Landscape) {
        croppedWidth = sourceWidth
        const isSourceLandscape = sourceWidth > sourceHeight
        if (!isSourceLandscape) {
            croppedHeight = Math.round(sourceWidth * LANDSCAPE_ASPECT_RATIO)
        } else {
            croppedHeight = sourceHeight
        }
    } else {
        const shortestDimensionLength = Math.min(sourceWidth, sourceHeight)
        croppedWidth = shortestDimensionLength
        croppedHeight = shortestDimensionLength
    }

    return { width: croppedWidth, height: croppedHeight }
}

export const getCropChangeTimes = (args: {
    centroidChangeTimes: { start: number; centroid: number[] }[]
    format: EVideoFormat
    sourceHeight: number
    sourceWidth: number
}) => {
    const { centroidChangeTimes, format, sourceHeight, sourceWidth } = args
    const { width: croppedWidth, height: croppedHeight } = getCropDimensionsForFormat({
        format,
        sourceHeight,
        sourceWidth,
    })

    const cropChangeTimes = centroidChangeTimes.map((changeTime) => {
        const { centroid, ...rest } = changeTime
        const [xPos, yPos] = changeTime.centroid
        const xfaceCentered = Math.round(xPos - croppedWidth / 2)
        const xStartBoundAdjusted = Math.max(xfaceCentered, 0)
        const xEndBoundAdjusted = Math.min(
            xStartBoundAdjusted,
            Math.round(sourceWidth - croppedWidth),
        )
        const yFaceCentered = Math.round(yPos - croppedHeight / 2)
        const yStartBoundAdjusted = Math.max(yFaceCentered, 0)
        const yEndBoundAdjusted = Math.min(
            yStartBoundAdjusted,
            Math.round(sourceHeight - croppedHeight),
        )
        return {
            ...rest,
            width: croppedWidth,
            height: croppedHeight,
            x: xEndBoundAdjusted,
            y: yEndBoundAdjusted,
        }
    })

    return cropChangeTimes
}

export const isClipSchema = (data: any): data is IClipSchemaItem[] => {
    return Array.isArray(data) && data.length > 0
}

export const getIntervalsInRange = ({
    intervals,
    startTime,
    endTime,
}: {
    intervals: IClusterInterval[]
    startTime: number
    endTime: number
}): IClusterInterval[] => {
    const intervalsInRange = intervals.filter((interval) => {
        const intervalEnd = interval.start + interval.duration
        const isInRange =
            interval.start >= startTime && interval.start <= endTime && intervalEnd <= endTime
        const startsBeforeRange = interval.start < startTime && intervalEnd > startTime
        const endsAfterRange = interval.start < endTime && intervalEnd > endTime
        if (isInRange || startsBeforeRange || endsAfterRange) {
            return true
        }
        return false
    })
    if (intervalsInRange.length === 0) {
        return []
    }
    if (intervalsInRange[0].start < startTime) {
        const startDelta = startTime - intervalsInRange[0].start
        intervalsInRange[0] = {
            ...intervalsInRange[0],
            start: startTime,
            duration: intervalsInRange[0].duration - startDelta,
        }
    }
    const lastInterval = intervalsInRange[intervalsInRange.length - 1]
    if (lastInterval.start + lastInterval.duration > endTime) {
        intervalsInRange[intervalsInRange.length - 1] = {
            ...lastInterval,
            duration: endTime - lastInterval.start,
        }
    }
    return intervalsInRange
}

export const getClipDuration = (clipSchema: IClipSchemaItem[]): number => {
    return sumFields(clipSchema, "duration")
}

export const generateClipSchemaFromRange = ({
    intervals,
    startTime,
    endTime,
}: {
    intervals: IClusterInterval[]
    startTime: number
    endTime: number
}): IClipSchemaItem[] => {
    const intervalsInRange = getIntervalsInRange({
        intervals,
        startTime,
        endTime,
    }).map((interval) => ({
        ...interval,
        start: round(interval.start, _c.REQUIRED_TIMELINE_PRECISION),
        duration: round(interval.duration, _c.REQUIRED_TIMELINE_PRECISION),
    }))
    const smoothedIntervals = applySmoothingToIntervals(intervalsInRange)
    return smoothedIntervals.map((interval) => ({
        ...interval,
        type: "source_video",
    }))
}

export const getVideoFileMetadata = async (file: File): Promise<{ duration: number }> => {
    return new Promise((resolve) => {
        const video = document.createElement("video")
        video.preload = "metadata"
        video.src = URL.createObjectURL(file)
        video.onloadedmetadata = function () {
            const duration = video.duration
            window.URL.revokeObjectURL(video.src)
            resolve({ duration })
        }
    })
}
