import { IClipSchemaItem, ITranscriptWord, IClusterInterval } from "../@types/snippet_types"
import _c from "../configs/constants"
import useHistoryReducer, { EHistoryAction, IReducerAction } from "./useHistoryReducer"

enum EVideoActionType {
    SetInitialState = "set-initial-state",
    SetCaptionWord = "set-caption-word",
    SetIntervalFocus = "set-interval-focus",
    SetClipSchema = "set-clip-schema",
    SplitInterval = "split-interval",
    DeleteInterval = "delete-interval",
}

export class VideoAction {
    static setInitial = (state: IVideoState) => {
        return {
            type: EVideoActionType.SetInitialState,
            data: state,
        }
    }
    static setCaptionWord = (wordIndex: number, text: string) => {
        return {
            type: EVideoActionType.SetCaptionWord,
            data: { wordIndex, text },
        }
    }
    static setIntervalFocus = (intervalIndex: number, centroid: [number, number]) => {
        return {
            type: EVideoActionType.SetIntervalFocus,
            data: { intervalIndex, centroid },
        }
    }
    static setClipSchema = (schema: IClipSchemaItem[]) => {
        return {
            type: EVideoActionType.SetClipSchema,
            data: { schema },
        }
    }
    static splitInterval = (
        index: number,
        updatedInterval: IClipSchemaItem,
        newInterval: IClipSchemaItem,
    ) => {
        return {
            type: EVideoActionType.SplitInterval,
            data: { index, updatedInterval, newInterval },
        }
    }
    static deleteInterval = (index: number) => {
        return {
            type: EVideoActionType.DeleteInterval,
            data: { index },
        }
    }
}

// Data that will be necessary for the video to be exported
export type IVideoState = {
    captionWords: ITranscriptWord[]
    intervals: IClusterInterval[]
    clipSchema: IClipSchemaItem[]
}

export type TVideoActionType = EVideoActionType | EHistoryAction.ReplaceState

export const videoReducer = (
    draft: IVideoState,
    action: IReducerAction<EVideoActionType | EHistoryAction.ReplaceState>,
) => {
    if (action.type === EVideoActionType.SetInitialState) {
        return action.data as IVideoState
    } else if (action.type === EVideoActionType.SetCaptionWord) {
        const { wordIndex, text } = action.data as { wordIndex: number; text: string }
        draft.captionWords[wordIndex].text = text
    } else if (action.type === EVideoActionType.SetIntervalFocus) {
        const { intervalIndex, centroid } = action.data as {
            intervalIndex: number
            centroid: [number, number]
        }
        draft.clipSchema[intervalIndex].centroid = centroid
    } else if (action.type === EVideoActionType.SetClipSchema) {
        const { schema } = action.data as {
            schema: IClipSchemaItem[]
        }
        const filteredSchema = schema.filter(
            (interval) => interval.duration >= _c.SIGNIFICANT_DURATION_THRESHOLD,
        )
        draft.clipSchema = filteredSchema
    } else if (action.type === EVideoActionType.SplitInterval) {
        const { index, updatedInterval, newInterval } = action.data as {
            index: number
            updatedInterval: IClipSchemaItem
            newInterval: IClipSchemaItem
        }
        const intervals = draft.clipSchema
        intervals[index] = updatedInterval
        intervals.splice(index + 1, 0, newInterval)
    } else if (action.type === EVideoActionType.DeleteInterval) {
        const { index } = action.data as { index: number }
        draft.clipSchema.splice(index, 1)
    } else if (action.type === EHistoryAction.ReplaceState) {
        return action.data as IVideoState
    }
}

const DEFAULT_VIDEO_STATE: IVideoState = {
    captionWords: [],
    intervals: [],
    clipSchema: [],
}

const useVideoReducer = () => {
    const { state, setState, changes } = useHistoryReducer<TVideoActionType, IVideoState>(
        videoReducer,
        DEFAULT_VIDEO_STATE,
    )
    return { videoState: state, dispatchVideoAction: setState, history: changes }
}

export default useVideoReducer
