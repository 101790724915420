import { Text } from "konva/lib/shapes/Text"
import isEqual from "lodash/isEqual"
import { RefObject, useEffect, useRef, useState } from "react"
import { Text as KonvaText, Rect } from "react-konva"

import {
    ECaptionPlacement,
    TBrandStyle,
    TBrandStylingObject,
} from "../../../@types/brand_style_types"
import { ICaptionWordWithFrames } from "../../../@types/snippet_types"
import { getCaptionStyles } from "../../../utils/captions_util"

const DEFAULT_PADDING_X = 30
const DEFAULT_PADDING_Y = 20
const INITIAL_HEIGHT = 50
const INITIAL_WIDTH_PROPORTION = 0.8

const getCaptionDimensions = (args: {
    size: { width: number; height: number }
    captionStyles: TBrandStylingObject
    textRef: RefObject<Text>
}) => {
    const { size, captionStyles, textRef } = args
    const { position, shadowColor, shadowOffsetY } = captionStyles
    const textWidth = size.width * 0.8
    let textY
    if (position === ECaptionPlacement.Bottom) {
        textY = size.height * 0.7
    } else if (position === ECaptionPlacement.Middle) {
        textY = size.height * 0.45
    } else {
        textY = size.height * 0.2
    }
    let dimensions
    if (textRef.current) {
        const textNode = textRef.current
        const width = textNode.getWidth()
        const height = textNode.getHeight()
        dimensions = { width, height }
    } else {
        dimensions = {
            width: INITIAL_WIDTH_PROPORTION * size.width,
            height: INITIAL_HEIGHT,
        }
    }
    const bgWidth = textWidth + DEFAULT_PADDING_X
    const hasVerticalShadow = shadowColor !== "none" && shadowOffsetY !== 0
    const shadowOffset = hasVerticalShadow ? shadowOffsetY : 0
    return {
        text: {
            width: size.width * 0.8,
            x: size.width * 0.1,
            y: textY,
        },
        background: {
            x: (size.width - bgWidth) / 2,
            y: textY - DEFAULT_PADDING_Y / 2 - shadowOffset,
            width: textWidth + DEFAULT_PADDING_X,
            height: dimensions.height + DEFAULT_PADDING_Y,
        },
    }
}

// Forcibly re-renders component when the given ref is initialized.
const useRenderOnRefInit = (ref: RefObject<any>) => {
    const [forceUpdate, setForceUpdate] = useState(false)
    useEffect(() => {
        if (forceUpdate) {
            setForceUpdate(false)
        }
    }, [forceUpdate])

    if (!forceUpdate && ref.current === null) {
        setForceUpdate(true)
    }
}

// We don't know when updates will become visible, so we force a re-render on 0.5s intervals.
const useUpdateOnStyleChange = (captionStyles: TBrandStylingObject) => {
    const [forceUpdateVal, setForceUpdateVal] = useState(false)
    const initialCaptions = useRef<TBrandStylingObject>(captionStyles)

    useEffect(() => {
        if (!isEqual(captionStyles, initialCaptions.current)) {
            const UPDATE_DURATIONS = [...Array(5).keys()].map((i) => (i + 1) * 500)
            for (const duration of UPDATE_DURATIONS) {
                setTimeout(() => {
                    setForceUpdateVal((prev) => !prev)
                }, duration)
            }
            initialCaptions.current = captionStyles
        }
    }, [captionStyles])

    return forceUpdateVal
}

const SingleCaption = (props: {
    caption: ICaptionWordWithFrames
    brandStyle: TBrandStyle
    size: { width: number; height: number }
}) => {
    const { caption, brandStyle } = props
    const textRef = useRef<Text>(null)
    const captionStyles = getCaptionStyles(caption, brandStyle)
    const dimensions = getCaptionDimensions({
        size: props.size,
        captionStyles,
        textRef,
    })
    const forceUpdateVal = useUpdateOnStyleChange(captionStyles)

    const hasShadow = captionStyles.shadowColor !== "none"
    const hasBackground = captionStyles.backgroundColor !== "none"

    useRenderOnRefInit(textRef)

    // When any styles during editing, Konva does not re-render text.
    // However, it will re-render if core text styles change like font size, color, etc.
    // Here we harmlessly change width to force a re-render.
    const textWidth = dimensions.text.width + (forceUpdateVal ? 1 : 0)

    return (
        <>
            {hasBackground && (
                <Rect
                    width={dimensions.background.width}
                    height={dimensions.background.height}
                    x={dimensions.background.x}
                    y={dimensions.background.y}
                    fill={captionStyles.backgroundColor}
                    cornerRadius={captionStyles.cornerRadius}
                    opacity={captionStyles.backgroundOpacity}
                />
            )}
            <KonvaText
                // Computed
                ref={textRef}
                width={textWidth}
                x={dimensions.text.x}
                y={dimensions.text.y}
                // Inherited
                align="center"
                fillAfterStrokeEnabled={true}
                text={captionStyles.isUppercase ? caption.text.toLocaleUpperCase() : caption.text}
                fill={captionStyles.fillColor}
                fontSize={captionStyles.fontSize}
                lineHeight={captionStyles.lineHeight}
                stroke={captionStyles.strokeColor}
                strokeWidth={captionStyles.strokeWidth}
                fontFamily={captionStyles.fontFamily}
                fontStyle={
                    captionStyles.isItalic
                        ? `italic ${captionStyles.fontWeight}`
                        : `${captionStyles.fontWeight}`
                }
                shadowOffsetX={captionStyles.shadowOffsetX}
                shadowOffsetY={captionStyles.shadowOffsetY}
                shadowBlur={captionStyles.shadowBlur}
                shadowColor={captionStyles.shadowColor}
                shadowOpacity={hasShadow ? 1 : 0}
            />
        </>
    )
}

export default SingleCaption
