import * as BagelFatOne from "@remotion/google-fonts/BagelFatOne"
import * as Bangers from "@remotion/google-fonts/Bangers"
import * as BebasNeue from "@remotion/google-fonts/BebasNeue"
import * as Bungee from "@remotion/google-fonts/Bungee"
import * as Caprasimo from "@remotion/google-fonts/Caprasimo"
import * as ClimateCrisis from "@remotion/google-fonts/ClimateCrisis"
import * as Gloock from "@remotion/google-fonts/Gloock"
import * as Koulen from "@remotion/google-fonts/Koulen"
import * as Montserrat from "@remotion/google-fonts/Montserrat"
import * as PermanentMarker from "@remotion/google-fonts/PermanentMarker"
import * as Poppins from "@remotion/google-fonts/Poppins"
import * as RedHatDisplay from "@remotion/google-fonts/RedHatDisplay"
import * as Syne from "@remotion/google-fonts/Syne"
import * as Unbounded from "@remotion/google-fonts/Unbounded"
import FontFaceObserver from "fontfaceobserver"
import { useEffect, useRef, useState } from "react"
import { continueRender, delayRender, staticFile } from "remotion"

import {
    EBrandStyleSection,
    ECaptionFont,
    TBrandStyle,
    TSupportedFontWeight,
} from "../@types/brand_style_types"
import _c from "../configs/constants"
import { isServer } from "../utils/browser_util"

const SUPPORTED_FONT_WEIGHTS = [300, 400, 500, 600, 700, 800, 900]

// Placeholder for server-side font loading
class ServerFontFace {
    weight = ""
}

const FontWrapper = isServer ? ServerFontFace : FontFace

const LOCAL_FONTS: { [K in ECaptionFont]?: FontFace } = {
    // @ts-ignore
    [ECaptionFont.TheBoldOne]: new FontWrapper(
        ECaptionFont.TheBoldOne,
        `url('${staticFile("fonts/TheBoldFont.woff2")}') format('woff2')`,
        {
            weight: "400",
        },
    ),
}

type TFontLoader = {
    getInfo: () => Record<string, any>
    loadFont: (...args: any[]) => void | Promise<void>
}

const convertLocalToPlatformFormat = (font: FontFace): TFontLoader => {
    const normalFontWeights = font.weight.split(" ").reduce((acc, w) => ({ ...acc, [w]: {} }), {})
    return {
        getInfo: () => {
            return {
                fontFamily: font.family,
                fonts: {
                    normal: normalFontWeights,
                },
            }
        },
        loadFont: async () => {
            await font.load()
        },
    }
}
const convertGoogleFontToPlatformFormat = (font: {
    getInfo: () => Record<string, any>
    loadFont: (...args: any[]) => void
}): TFontLoader => {
    return {
        getInfo: font.getInfo,
        loadFont: async (...args: any[]) => Promise.resolve(font.loadFont(...args)),
    }
}

const getFont = (font: ECaptionFont): TFontLoader | null => {
    if (LOCAL_FONTS[font]) {
        return convertLocalToPlatformFormat(LOCAL_FONTS[font]!)
    }
    let result = null
    if (font === ECaptionFont.Montserrat) {
        result = Montserrat
    } else if (font === ECaptionFont.BebasNeue) {
        result = BebasNeue
    } else if (font === ECaptionFont.PermanentMarker) {
        result = PermanentMarker
    } else if (font === ECaptionFont.BagelFatOne) {
        result = BagelFatOne
    } else if (font === ECaptionFont.Caprasimo) {
        result = Caprasimo
    } else if (font === ECaptionFont.ClimateCrisis) {
        result = ClimateCrisis
    } else if (font === ECaptionFont.Bangers) {
        result = Bangers
    } else if (font === ECaptionFont.Bungee) {
        result = Bungee
    } else if (font === ECaptionFont.Gloock) {
        result = Gloock
    } else if (font === ECaptionFont.Koulen) {
        result = Koulen
    } else if (font === ECaptionFont.Poppins) {
        result = Poppins
    } else if (font === ECaptionFont.RedHatDisplay) {
        result = RedHatDisplay
    } else if (font === ECaptionFont.Syne) {
        result = Syne
    } else if (font === ECaptionFont.Unbounded) {
        result = Unbounded
    }
    if (!result) {
        return null
    }
    return convertGoogleFontToPlatformFormat(result)
}

const extractAvailableWeights = (font: ReturnType<typeof getFont>) => {
    if (!font) {
        return [] as TSupportedFontWeight[]
    }
    const fontInfo = font.getInfo()
    const availableWeights = Object.keys(fontInfo.fonts.normal).filter((w) =>
        SUPPORTED_FONT_WEIGHTS.includes(parseInt(w)),
    )
    return availableWeights as TSupportedFontWeight[]
}

export const getAvailableWeights = (fontFamily: ECaptionFont) => {
    const font = getFont(fontFamily)
    return extractAvailableWeights(font)
}

type TFontLoadState = "loading" | "loaded" | "error"

const useFontLoadState = (fontName: string): TFontLoadState => {
    const [fontState, setFontState] = useState<TFontLoadState>("loading")

    useEffect(() => {
        if ("fonts" in document) {
            ;(async () => {
                await document.fonts.ready
                const fontExists = document.fonts.check(`16px '${fontName}'`)
                if (fontExists) {
                    setFontState("loaded")
                    return
                }
                try {
                    const fontObserver = new FontFaceObserver(fontName)
                    setFontState("loading")
                    await fontObserver.load(null, 10_000)
                    setFontState("loaded")
                } catch (error) {
                    setFontState("error")
                }
            })()
        }
    }, [fontName])

    return fontState
}

const useFontFamily = (brandStyle: TBrandStyle) => {
    const fontFamily = brandStyle[EBrandStyleSection.General].fontFamily

    const fontState = useFontLoadState(fontFamily)
    const fontStateRef = useRef<number | null>(null)

    const fontLoader = getFont(fontFamily as ECaptionFont)
    const fontInfo = fontLoader?.getInfo() ?? null

    // Load font
    useEffect(() => {
        ;(async () => {
            const id = delayRender()
            if (fontLoader) {
                // @ts-ignore all loadFont() signatures are of different types
                await fontLoader.loadFont("normal", {
                    weights: extractAvailableWeights(fontLoader),
                })
            }
            continueRender(id)
        })()
    }, [fontFamily])

    // Continue render when font is loaded
    useEffect(() => {
        if (fontState === "loaded") {
            continueRender(fontStateRef.current!)
            fontStateRef.current = null
            return
        }
        if (fontStateRef.current) {
            return
        }
        fontStateRef.current = delayRender()
    }, [fontState])

    return { info: fontInfo, status: fontState }
}

export default useFontFamily
