import { Box, Fade, Flex, Image } from "@chakra-ui/react"
import { BrandStyle } from "@prisma/client"
import { Player, PlayerRef } from "@remotion/player"
import {
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"

import { TBrandStyle } from "../../@types/brand_style_types"
import { EAnimation } from "../../@types/global_types"
import { EVideoFormat, IClipSchemaItem, ITranscriptWord, IClusterInterval } from "../../@types/snippet_types"
import { useClipSchema } from "../../hooks/useClipSchema"
import { getCropChangeTimes, getCropDimensionsForFormat } from "../../utils/video_util"
import LottiePlayer from "../LottiePlayer"
import { TutorialStepWrapper } from "../tutorial/TutorialStepWrapper"
import { TutorialContext } from "../tutorial/TutorialWrapper"
import { EEditorTimelineSteps } from "./EditorTimeline"
import CaptionSequencer from "./remotion/CaptionSequencer"

export const scaleCropForViewer = (args: {
    crop: {
        start: number
        width: number
        height: number
        x: number
        y: number
    }
    cropMode: EVideoFormat
    sourceDimensions: { width: number; height: number }
    viewerDimensions: { width: number; height: number }
}) => {
    const { crop, cropMode, sourceDimensions, viewerDimensions } = args
    let scaledVideoWidth: number
    let scaledVideoHeight: number
    if (cropMode !== EVideoFormat.Landscape) {
        const landscapeRatio = sourceDimensions.width / sourceDimensions.height
        if (sourceDimensions.width > sourceDimensions.height) {
            scaledVideoWidth = viewerDimensions.height * landscapeRatio
            scaledVideoHeight = viewerDimensions.height
        } else if (sourceDimensions.width === sourceDimensions.height) {
            scaledVideoWidth = viewerDimensions.width
            scaledVideoHeight = viewerDimensions.width * landscapeRatio
        } else {
            scaledVideoWidth = viewerDimensions.width
            scaledVideoHeight = viewerDimensions.width * landscapeRatio
        }
    } else {
        scaledVideoWidth = viewerDimensions.width
        scaledVideoHeight = viewerDimensions.height
    }
    const scaleFactor = sourceDimensions.width / scaledVideoWidth!
    return {
        start: crop.start,
        width: scaledVideoWidth!,
        height: scaledVideoHeight!,
        x: crop.x / scaleFactor,
        y: crop.y / scaleFactor,
    }
}

export interface ICropProps {
    start: number
    width: number
    height: number
    x: number
    y: number
}

interface IEditorVideoProps {
    url: string
    frameRate: number
    clipSchema: IClipSchemaItem[]
    activeIntervalIndex: number
    intervals: IClusterInterval[]
    transcript: ITranscriptWord[]
    brand?: BrandStyle

    cropMode: EVideoFormat
    sourceDimensions: { width: number; height: number }
    viewerDimensions: { width: number; height: number }

    isLoading: boolean
    showVideoOverlay: boolean
    showWatermark: boolean

    onVideoRef?: (
        ref: MutableRefObject<HTMLVideoElement | null>,
        captionsRef: MutableRefObject<PlayerRef | null>,
    ) => void
    onSetVideoFocus?: (position: number[]) => void
}

export enum EEditorVideoTutorialSteps {
    DragFrame = "step-drag-frame",
}

const EditorVideoTutorialStepsMapping: Record<EEditorVideoTutorialSteps, string> = {
    [EEditorVideoTutorialSteps.DragFrame]: "Drag to reposition the frame in the selected section",
}

/*  EditorVideo
    This component is used to display the viewport for a clip in the EditorPage.
    It is responsible for:
    - Computing the crop for the given interval.
    - Showing the correct crop for the interval.
    - Showing the correct captions.
    - Showing the watermark.
    - Showing the tiktok overlay.
    - Repositioning the current video.
    Not responsible for:
    - Computing the interval
    - Handling playback
*/
const EditorVideo = ({
    url,
    clipSchema,
    activeIntervalIndex,
    brand,
    transcript,
    cropMode,
    sourceDimensions,
    viewerDimensions,
    showVideoOverlay,
    frameRate,
    showWatermark,
    isLoading,
    onVideoRef,
    onSetVideoFocus,
}: IEditorVideoProps) => {
    const [isOutlineVisible, setOutlineVisibility] = useState(false)
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const [dragStartPoint, setDragStartPoint] = useState(0)
    const [videoOffset, setVideoOffset] = useState(0)

    const videoContainerRef = useRef<HTMLDivElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    const videoOutlineRef = useRef<HTMLDivElement>(null)
    const viewerRef = useRef<HTMLDivElement>(null)
    const captionsRef = useRef<PlayerRef>(null)
    const tutorialContext = useContext(TutorialContext)

    const { clipStart, clipEnd, clipDuration, deletedRanges, getCaptionsForSchema } =
        useClipSchema(clipSchema)

    useEffect(() => {
        // Manage visibility of video outline when repositioning video
        if (isDragging && !isOutlineVisible) {
            setOutlineVisibility(true)
        } else if (!isDragging && isOutlineVisible) {
            setOutlineVisibility(false)
        }
    }, [isDragging])
    useEffect(() => {
        if (!!onVideoRef) {
            onVideoRef(videoRef, captionsRef)
        }
    }, [])

    useEffect(() => {
        if (!tutorialContext) {
            return
        }
        tutorialContext.registerTutorialStep(EditorVideoTutorialStepsMapping)
    }, [tutorialContext])

    const withCaptions = brand?.showCaptions ?? false
    const canAdjustVideoPosition = cropMode !== EVideoFormat.Landscape

    const cropIntervals = useMemo(() => {
        const cropChangeParams = {
            centroidChangeTimes: clipSchema,
            sourceHeight: sourceDimensions.height,
            sourceWidth: sourceDimensions.width,
        }
        const changes = getCropChangeTimes({
            ...cropChangeParams,
            format: cropMode,
        })
        const scaledIntervals = changes.map((interval) =>
            scaleCropForViewer({
                crop: interval,
                cropMode,
                sourceDimensions,
                viewerDimensions,
            }),
        )
        return scaledIntervals
    }, [clipSchema, viewerDimensions, cropMode])
    const captionsForClip = useMemo(() => {
        if (!brand || !withCaptions) {
            return []
        }
        return getCaptionsForSchema(transcript, brand)
    }, [brand, clipStart, clipEnd, withCaptions, transcript, deletedRanges, frameRate])

    const activeInterval = cropIntervals[activeIntervalIndex]

    useEffect(() => {
        window.addEventListener("mouseup", handleDragMouseUp)
        return () => {
            window.removeEventListener("mouseup", handleDragMouseUp)
        }
    }, [clipEnd, isDragging, videoOffset, withCaptions, transcript])

    const handleDragMouseDown = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
        setIsDragging(true)
        setDragStartPoint(e.clientX)
        setVideoOffset(0)
    }, [])
    const handleDragMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !videoContainerRef.current) {
            return
        }
        const dragDistance = e.clientX - dragStartPoint
        let updatedOffset = dragDistance

        const viewerBoundingRect = videoContainerRef.current!.getBoundingClientRect()
        const videoStartX = viewerBoundingRect!.x - activeInterval.x
        const currentVideoStartX = videoStartX + dragDistance
        const hasLeftGap = currentVideoStartX > viewerBoundingRect!.x
        if (hasLeftGap) {
            const gapSize = currentVideoStartX - viewerBoundingRect!.x
            updatedOffset = dragDistance - gapSize
        }

        const currentVideoEndX = videoStartX + activeInterval.width + dragDistance
        const viewerEndX = viewerBoundingRect!.x + viewerDimensions.width
        const hasRightGap = currentVideoEndX < viewerEndX
        if (hasRightGap) {
            const gapSize = viewerEndX - currentVideoEndX
            updatedOffset = dragDistance + gapSize
        }

        setVideoOffset(updatedOffset)
    }
    const handleDragMouseUp = (e: MouseEvent) => {
        if (!onSetVideoFocus || !isDragging) {
            return
        }
        if (videoOffset === 0) {
            setIsDragging(false)
            return
        }

        const scaleFactor = sourceDimensions.width / activeInterval.width
        const currentOffset = e.clientX - dragStartPoint
        const scaledOffset = currentOffset * scaleFactor
        const prevCentroid = clipSchema[activeIntervalIndex].centroid
        const newCentroid = [prevCentroid[0] - scaledOffset, prevCentroid[1]]
        onSetVideoFocus(newCentroid)

        // Reset drag state to prevent flicker as state updates
        setDragStartPoint(e.clientX)
        setTimeout(() => {
            setIsDragging(false)
        }, 50)
    }
    const renderCaptions = () => {
        if (!withCaptions) {
            return null
        }
        const durationInFrames = Math.floor(clipDuration * frameRate)
        const compDimensions = getCropDimensionsForFormat({
            format: cropMode,
            sourceWidth: sourceDimensions.width,
            sourceHeight: sourceDimensions.height,
        })
        const brandStyle = brand!.styles as TBrandStyle
        return (
            <Player
                ref={captionsRef}
                component={CaptionSequencer}
                durationInFrames={durationInFrames > 0 ? durationInFrames : 1}
                compositionWidth={compDimensions.width}
                compositionHeight={compDimensions.height}
                style={{
                    width: viewerDimensions.width,
                }}
                inputProps={{
                    captions: captionsForClip,
                    fps: frameRate,
                    brandStyle,
                }}
                fps={frameRate}
            />
        )
    }
    const renderLoadingEffect = (isVisible: boolean) => {
        return (
            <>
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    backdropFilter="blur(20px)"
                    opacity={isVisible ? 1 : 0}
                    transition="opacity 0.3s ease-in-out"
                />
                <Box
                    display="block"
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    backgroundColor="rgba(0,0,0,0.3)"
                    opacity={isVisible ? 1 : 0}
                    transition="opacity 0.3s ease-in-out"
                >
                    <LottiePlayer
                        animation={EAnimation.brandLoading}
                        loop={true}
                        autoplay={true}
                        speed={1}
                        position="absolute"
                        top="calc(50% - var(--brand-loading-size) / 2)"
                        left="calc(50% - var(--brand-loading-size) / 2)"
                        width="var(--brand-loading-size)"
                        height="var(--brand-loading-size)"
                        sx={{
                            "--brand-loading-size": "90px",
                        }}
                    />
                </Box>
            </>
        )
    }
    const renderWatermark = (isVisible: boolean) => {
        if (!showWatermark) {
            return null
        }
        const width = (() => {
            if (cropMode === EVideoFormat.Landscape) {
                return viewerDimensions.width * 0.2
            }
            return viewerDimensions.width * 0.4
        })()
        return (
            <Image
                position="absolute"
                left="10px"
                bottom="35%"
                width={width}
                alt="LiveLink AI watermark"
                src="/images/watermarks/ll_overlay_watermark.png"
                opacity={isVisible ? 1 : 0}
                transition="opacity 0.3s ease-in-out"
            />
        )
    }
    const renderOverlays = () => {
        let canShowWatermark = false
        let canShowLoading = false

        if (isLoading) {
            canShowWatermark = true
            canShowLoading = true
        } else{
            canShowWatermark = true
        }
        return (
            <>
                {renderWatermark(canShowWatermark)}
                {renderLoadingEffect(canShowLoading)}
            </>
        )
    }
    return (
        <Flex
            width="full"
            justifyContent="center"
            alignItems="center"
            data-clarity-mask="true"
            position="relative"
        >
            <TutorialStepWrapper
                tutorialKey={EEditorVideoTutorialSteps.DragFrame}
                nextTutorialKey={EEditorTimelineSteps.EditorTimeline}
                popoverPlacement="right"
                placeholderPosition={{ left: "100%", top: "10%" }}
            />
            <Flex
                ref={videoContainerRef}
                width={`${viewerDimensions.width}px`}
                height={`${viewerDimensions.height}px`}
                backgroundColor="black"
                overflow="hidden"
                position="relative"
                boxSizing="content-box"
                borderX={isOutlineVisible ? "1px dashed white" : undefined}
            >
                <Box
                    // @ts-ignore as="video" doesn't assign "video" type to ref
                    ref={videoRef}
                    as="video"
                    preload="none"
                    crossOrigin="anonymous"
                    playsInline={true}
                    autoPlay={false}
                    controls={false}
                    controlsList="nodownload"
                    position="absolute"
                    top={`-${activeInterval?.y}px`}
                    left={`-${activeInterval?.x}px`}
                    width={`${activeInterval?.width}px`}
                    height={`${activeInterval?.height}px`}
                    transform={isDragging ? `translateX(${videoOffset}px)` : "none"}
                    maxWidth="none"
                >
                    <source src={url} type="video/mp4" />
                </Box>

                {renderCaptions()}

                {renderOverlays()}

                {showVideoOverlay && (
                    <Flex
                        position="absolute"
                        width={viewerDimensions.width}
                        height={viewerDimensions.height}
                    >
                        <Image
                            alt="tiktok preview"
                            src="/images/snippets/tiktok_layer.svg"
                            top="0"
                            right="0"
                            left="0"
                            bottom="0"
                            width="100%"
                            height="100%"
                        />
                    </Flex>
                )}
            </Flex>
            {canAdjustVideoPosition && (
                <Flex
                    ref={viewerRef}
                    width={`${viewerDimensions.width}px`}
                    height={`${viewerDimensions.height}px`}
                    position="absolute"
                >
                    <Flex
                        ref={videoOutlineRef}
                        onMouseDown={handleDragMouseDown}
                        onMouseMove={handleDragMouseMove}
                        position="absolute"
                        top={`-${activeInterval?.y}px`}
                        left={`-${activeInterval?.x}px`}
                        width={`${activeInterval?.width}px`}
                        height={`${activeInterval?.height}px`}
                        cursor={isDragging || isOutlineVisible ? "grabbing" : "grab"}
                        transform={isDragging ? `translateX(${videoOffset}px)` : undefined}
                        transition="border-color 0.3s ease-in-out"
                        border={isOutlineVisible ? "3px solid #06D6A0" : "1px dashed #06D6A07F"}
                        _hover={{
                            border: isOutlineVisible ? "3px solid #06D6A0" : "1px dashed #06D6A0",
                        }}
                    />
                </Flex>
            )}
        </Flex>
    )
}

EditorVideo.displayName = "EditorVideo"

export default EditorVideo
