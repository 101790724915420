import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Container,
    Flex,
    HStack,
    Icon,
    IconButton,
    Image,
    Kbd,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import round from "lodash/round"
import { InferGetServerSidePropsType, NextPage } from "next"
import { AuthAction, withAuthUser } from "next-firebase-auth"
import Head from "next/head"
import NextLink from "next/link"
import { useRouter } from "next/router"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { BsDot } from "react-icons/bs"
import { FaTiktok } from "react-icons/fa"
import { FiInfo } from "react-icons/fi"
import { IconType } from "react-icons/lib"
import { MdContentCut, MdPause, MdPlayArrow } from "react-icons/md"
import { RiShareBoxLine } from "react-icons/ri"

import { AE } from "../../@types/analytics"
import { EVideoFormat, IClipSchemaItem, ITranscriptWord } from "../../@types/snippet_types"
import MetaTags from "../../components/MetaTags"
import BrandStylesEditor, {
    IBrandStylesEditorRef,
} from "../../components/snippets/BrandStylesEditor"
import EditorTimeline from "../../components/snippets/EditorTimeline"
import EditorTranscript from "../../components/snippets/EditorTranscript"
import EditorVideo, { EEditorVideoTutorialSteps } from "../../components/snippets/EditorVideo"
import { TutorialStepWrapper } from "../../components/tutorial/TutorialStepWrapper"
import { ITutorialControllerRef, TutorialWrapper } from "../../components/tutorial/TutorialWrapper"
import UserNavbarMenu from "../../components/UserNavbarMenu"
import _c from "../../configs/constants"
import { EThemeMode, ThemeContext } from "../../configs/theme"
import AlertController from "../../controllers/alert_controller"
import Analytics from "../../controllers/analytics_controller"
import useEditorController, {
    ESidebarPanelName,
    TSidebarPanel,
} from "../../hooks/useEditorController"
import useVideoReducer, { VideoAction } from "../../hooks/useVideoReducer"
import { BaseSnippetVideo } from "../../server/dao/snippet_video_dao"
import api from "../../services/root_service"
import { functionNoop } from "../../utils/function_util"
import {
    linkToEditClip,
    linkToLogin,
    linkToProject,
    linkToSnippetProjects,
} from "../../utils/link_util"
import { sumFields } from "../../utils/number_util"
import { IGetServerSidePropsContext, withAppContext } from "../../utils/ssr_util"
import { isUserSubscribedToPlatform } from "../../utils/user_util"
import { generateClipSchemaFromRange } from "../../utils/video_util"

const highlightColor = "#02b788"
const uiSeparatorColor = "#252d44"
const primaryBgColor = "#060B1A"
const secondaryBgColor = "#090F22"
const secondaryTextColor = "#A9A9A9"
const activeWordColor = "#06D6A0"
const editorBorder = "1px solid var(--ui-separator-color)"

interface IEditorPageProps extends InferGetServerSidePropsType<typeof fetchDataRequirements> {}

export enum EEditorTutorialSteps {
    Design = "step-design",
    EditorTutorialInfo = "step-editor-tutorial-info",
}

const EditorTutorialStepsMapping: Record<EEditorTutorialSteps, string> = {
    [EEditorTutorialSteps.Design]: "Fonts, captions, colours and more",
    [EEditorTutorialSteps.EditorTutorialInfo]: "Click here at anytime to see these tips again",
}

const EditorPage: NextPage<IEditorPageProps> = ({ currentUser, task, snippetVideo, brands }) => {
    const router = useRouter()
    const store = useVideoReducer()
    const controller = useEditorController({
        store,
        task,
        snippetVideo,
        brands,
    })

    const { videoState, dispatchVideoAction, history } = store
    const { intervals, clipSchema } = videoState
    const transcript = videoState.captionWords
    const video = controller.video.ref.current!

    const [isEditingCaptions, setEditingCaptions] = useState<boolean>(false)
    const [showTiktokLayer, setShowTiktokLayer] = useState<boolean>(false)
    const [editorTabIndex, setEditorTabIndex] = useState(0)

    const brandEditorRef = useRef<IBrandStylesEditorRef>()
    const viewerRef = useRef<HTMLDivElement | null>(null)

    const clipStart = useMemo(() => {
        if (clipSchema.length === 0) {
            return 0
        }
        return clipSchema[0].start
    }, [clipSchema])
    const clipEnd = useMemo(() => {
        if (clipSchema.length === 0) {
            return 0
        }
        const lastInterval = clipSchema[clipSchema.length - 1]
        return lastInterval.start + lastInterval.duration
    }, [clipSchema])
    const clipDuration = useMemo(() => {
        if (!clipSchema) {
            return clipEnd - clipStart
        }
        return sumFields(clipSchema, "duration")
    }, [clipStart, clipEnd, clipSchema])
    const tutorialControllerRef = useRef<ITutorialControllerRef>({
        hasOngoingTutorial: false,
        isTutorialReady: false,
        registerTutorialStep: functionNoop,
        showTutorialStep: functionNoop,
    })

    useEffect(() => {
        if (!!viewerRef) {
            controller.ui.setVideoContainerRef(viewerRef)
        }
    }, [])
    const generateClipSchemaForNewStart = (newStart: number) => {
        let newSchema = clipSchema.slice(0)
        const currentStart = clipSchema[0].start
        const originalEndIndex = intervals.findIndex(
            (interval) =>
                interval.start <= currentStart && currentStart < interval.start + interval.duration,
        )
        const originalEndInterval = intervals[originalEndIndex]!
        const isBeforeCurrentStart = newStart <= currentStart
        if (isBeforeCurrentStart) {
            const isWithinOriginalInterval = newStart >= originalEndInterval.start
            if (isWithinOriginalInterval) {
                newSchema[0] = {
                    ...newSchema[0],
                    start: newStart,
                    duration: newSchema[0].duration + (newSchema[0].start - newStart),
                }
            } else {
                const newStartIndex = intervals.findIndex((interval) => {
                    const end = interval.start + interval.duration
                    return interval.start <= newStart && newStart <= end
                })
                const newIntervals = intervals.slice(newStartIndex, originalEndIndex + 1).map(
                    ({ start, duration, ...rest }) =>
                        ({
                            start: round(start, _c.REQUIRED_TIMELINE_PRECISION),
                            duration: round(duration, _c.REQUIRED_TIMELINE_PRECISION),
                            ...rest,
                            type: "source_video",
                        } as IClipSchemaItem),
                )
                if (newIntervals[0].start < newStart) {
                    newIntervals[0].start = newStart
                    newIntervals[0].duration -= newStart - newIntervals[0].start
                }
                newSchema = [...newIntervals, ...newSchema.slice(1)]
            }
        } else {
            const isWithinOriginalInterval =
                newStart <= originalEndInterval.start + originalEndInterval.duration
            if (isWithinOriginalInterval) {
                const newDuration = newSchema[0].duration - (newStart - newSchema[0].start)
                newSchema[0] = {
                    ...newSchema[0],
                    start: newStart,
                    duration: newDuration,
                }
            } else {
                // Filter out intervals that are before the new start time
                const newStartIndex = clipSchema.findIndex(
                    (interval) => newStart < interval.start + interval.duration,
                )
                newSchema = newSchema.slice(newStartIndex)
                if (newSchema[0].start < newStart) {
                    newSchema[0] = {
                        ...newSchema[0],
                        start: newStart,
                    }
                }
            }
        }
        return newSchema
    }
    const generateClipSchemaForNewEnd = (newEnd: number) => {
        let newSchema = clipSchema.slice(0)
        const currentEndInterval = clipSchema[clipSchema.length - 1]
        const currentEnd = currentEndInterval.start + currentEndInterval.duration
        const originalEndIndex = intervals.findIndex((interval) => {
            const end = interval.start + interval.duration
            return interval.start < currentEnd && currentEnd <= end
        })
        const originalEndInterval = intervals[originalEndIndex]!
        const originalEnd = originalEndInterval.start + originalEndInterval.duration
        const isBeforeCurrentEnd = newEnd <= currentEnd
        if (isBeforeCurrentEnd) {
            const isWithinOriginalInterval =
                newEnd > originalEndInterval.start && newEnd < originalEnd
            if (isWithinOriginalInterval) {
                const newDuration = newEnd - currentEndInterval.start
                newSchema[newSchema.length - 1] = {
                    ...newSchema[newSchema.length - 1],
                    duration: newDuration,
                }
            } else {
                const newEndIndex = newSchema.findIndex((interval) => {
                    const end = interval.start + interval.duration
                    return interval.start <= newEnd && newEnd <= end
                })
                newSchema = newSchema.slice(0, newEndIndex + 1)
                if (newEnd < currentEnd) {
                    newSchema[newSchema.length - 1] = {
                        ...newSchema[newSchema.length - 1],
                        duration: newEnd - newSchema[newSchema.length - 1].start,
                    }
                }
            }
        } else {
            const isWithinOriginalInterval =
                newEnd <= originalEndInterval.start + originalEndInterval.duration
            if (isWithinOriginalInterval) {
                const delta = newEnd - currentEnd
                newSchema[newSchema.length - 1] = {
                    ...newSchema[newSchema.length - 1],
                    duration: currentEndInterval.duration + delta,
                }
            } else {
                const newEndIndex = intervals.findIndex((interval) => {
                    const end = interval.start + interval.duration
                    return interval.start <= newEnd && newEnd <= end
                })
                const newIntervals = intervals
                    .slice(originalEndIndex + 1, newEndIndex + 1)
                    .map((interval) => ({ type: "source_video", ...interval } as IClipSchemaItem))

                // Pad the previous final interval till the start of the newly added one
                const prevLastIntervalDuration = newIntervals[0].start - currentEndInterval.start
                newSchema[newSchema.length - 1] = {
                    ...newSchema[newSchema.length - 1],
                    duration: prevLastIntervalDuration,
                }
                newSchema = [...newSchema, ...newIntervals]
                const lastIntervalEnd =
                    newSchema[newSchema.length - 1].start + newSchema[newSchema.length - 1].duration
                if (lastIntervalEnd >= newEnd) {
                    const newDuration = newEnd - newSchema[newSchema.length - 1].start
                    newSchema[newSchema.length - 1] = {
                        ...newSchema[newSchema.length - 1],
                        duration: newDuration,
                    }
                }
            }
        }
        return newSchema
    }
    const saveAndRedirect = async () => {
        try {
            const changes = controller.getChanges()
            controller.ui.setSaving(true)
            await api().postUpdateSnippet(changes)
            controller.ui.setSaving(false)

            Analytics.trackEvent(AE.Editor_SaveCompileClick, {
                clipId: changes.snippetId,
            })

            router.push(linkToProject(snippetVideo.requestId, changes.snippetId))
        } catch (error) {
            controller.ui.setSaving(false)
            console.error(error)
        }
    }
    const handleUpdateVideoStartForCaption = (wordClicked: ITranscriptWord) => {
        const newSchema = generateClipSchemaForNewStart(wordClicked.start)
        dispatchVideoAction(VideoAction.setClipSchema(newSchema))
    }
    const handleUpdateVideoEndForCaption = (wordClicked: ITranscriptWord) => {
        const newSchema = generateClipSchemaForNewEnd(wordClicked.end)
        dispatchVideoAction(VideoAction.setClipSchema(newSchema))
    }
    const handleUpdateVideoStartByTime = (newStart: number) => {
        const cappedStart = Math.max(newStart, 0)
        const newSchema = generateClipSchemaForNewStart(cappedStart)
        dispatchVideoAction(VideoAction.setClipSchema(newSchema))
    }
    const handleUpdateVideoEndByTime = (newEnd: number) => {
        const cappedEnd = Math.min(newEnd, task.videoDuration!)
        const newSchema = generateClipSchemaForNewEnd(cappedEnd)
        dispatchVideoAction(VideoAction.setClipSchema(newSchema))
    }
    const handleDeleteInterval = (index: number) => {
        dispatchVideoAction(VideoAction.deleteInterval(index))
    }
    const handleUpdateVideoFocusForCurrentTime = (position: [number, number]) => {
        if (!video) {
            return
        }
        dispatchVideoAction(VideoAction.setIntervalFocus(controller.video.intervalIndex, position))
    }
    const handleUpdateCaptionWordText = (newWord: ITranscriptWord) => {
        const wordIndex = transcript.findIndex((word) => word.start === newWord!.start)
        dispatchVideoAction(VideoAction.setCaptionWord(wordIndex, newWord.text))
    }
    const handleUndoLastAction = () => {
        history.undo()
    }
    const handleRedoLastAction = () => {
        history.redo()
    }
    const handleClickSplitInterval = useCallback(() => {
        const { clipSchema } = videoState
        const currentInterval = clipSchema[controller.video.intervalIndex]
        const currentTime = round(video.currentTime, _c.REQUIRED_TIMELINE_PRECISION)
        const updatedDuration = currentTime - currentInterval.start
        const hasNoDuration = updatedDuration <= 0
        if (hasNoDuration) {
            return
        }
        const roundedDuration = round(updatedDuration, _c.REQUIRED_TIMELINE_PRECISION)
        const updatedInterval: IClipSchemaItem = {
            ...currentInterval,
            duration: roundedDuration,
        }
        const newStart = updatedInterval.start + updatedInterval.duration
        const newDuration = round(
            currentInterval.duration - roundedDuration,
            _c.REQUIRED_TIMELINE_PRECISION,
        )
        const newInterval: IClipSchemaItem = {
            start: newStart,
            duration: newDuration,
            centroid: currentInterval.centroid,
            type: "source_video",
        }
        dispatchVideoAction(
            VideoAction.splitInterval(controller.video.intervalIndex, updatedInterval, newInterval),
        )
    }, [videoState.clipSchema, controller.video.intervalIndex])
    const handleSaveAndCompile = async () => {
        if (brandEditorRef.current?.hasChanges()) {
            return AlertController.show({
                title: "Save changes to your brand before exporting.",
                message: "If you do not save your changes, they will be lost.",
                primaryAction: {
                    label: "Save and Continue",
                    onClick: async () => {
                        await brandEditorRef.current?.triggerSaveChanges()
                        await saveAndRedirect()
                    },
                },
                secondaryAction: {
                    label: "Cancel",
                },
                size: "md",
                isDismissable: true,
            })
        }
        await saveAndRedirect()
    }
    const handleDeleteWordRange = (rangeStart: number, rangeEnd: number) => {
        // Filters out intervals/parts of intervals that are in the range of deletion
        const newSchema = clipSchema.reduce((acc, interval) => {
            const intervalEnd = interval.start + interval.duration
            const endsBeforeRangeStart = intervalEnd < rangeStart
            if (endsBeforeRangeStart) {
                acc.push(interval)
                return acc
            }
            const startsAfterRangeEnd = interval.start >= rangeEnd
            if (startsAfterRangeEnd) {
                acc.push(interval)
                return acc
            }
            const isRangeWithinInterval = rangeStart >= interval.start && rangeEnd <= intervalEnd
            if (isRangeWithinInterval) {
                const newInterval1 = {
                    ...interval,
                    duration: round(rangeStart - interval.start, _c.REQUIRED_TIMELINE_PRECISION),
                }
                const newInterval2 = {
                    ...interval,
                    start: round(rangeEnd, _c.REQUIRED_TIMELINE_PRECISION),
                    duration: round(intervalEnd - rangeEnd, _c.REQUIRED_TIMELINE_PRECISION),
                }
                acc.push(newInterval1)
                if (newInterval2.duration > _c.SIGNIFICANT_DURATION_THRESHOLD) {
                    acc.push(newInterval2)
                }
                return acc
            }
            const startsBeforeRangeStart = interval.start < rangeStart
            const endsBeforeRangeEnd = intervalEnd < rangeEnd
            if (startsBeforeRangeStart && endsBeforeRangeEnd) {
                const delta = intervalEnd - rangeStart
                const newInterval: IClipSchemaItem = {
                    ...interval,
                    duration: round(interval.duration - delta, _c.REQUIRED_TIMELINE_PRECISION),
                }
                acc.push(newInterval)
                return acc
            }
            const isIntervalWithinRange = interval.start >= rangeStart && intervalEnd <= rangeEnd
            if (isIntervalWithinRange) {
                return acc
            }
            const startsAfterRangeStart = interval.start >= rangeStart
            const endsAfterRangeEnd = intervalEnd > rangeEnd
            if (startsAfterRangeStart && endsAfterRangeEnd) {
                const newStart = rangeEnd
                const newDuration = round(intervalEnd - rangeEnd, _c.REQUIRED_TIMELINE_PRECISION)
                // Happens if previous iteration split an interval
                const prevStartsAtSameTime = acc[acc.length - 1].start === newStart
                if (prevStartsAtSameTime) {
                    acc[acc.length - 1] = {
                        ...interval,
                        start: newStart,
                        duration: newDuration,
                    }
                } else {
                    acc.push({
                        ...interval,
                        start: newStart,
                        duration: newDuration,
                    })
                }
                return acc
            }
            return acc
        }, [] as IClipSchemaItem[])
        dispatchVideoAction(VideoAction.setClipSchema(newSchema))
    }
    const handleEnableWordRange = (rangeStart: number, rangeEnd: number) => {
        // Create ranges of gaps between intervals - i.e. the deleted ranges
        const deletedRanges = clipSchema.reduce((acc, currentRange, i, list) => {
            if (i === 0) {
                return []
            }
            const previousRange = list[i - 1]
            const previousRangeEnd = previousRange.start + previousRange.duration
            const rangeDiff = currentRange.start - previousRangeEnd
            if (rangeDiff > _c.SIGNIFICANT_DURATION_THRESHOLD) {
                acc.push({
                    start: previousRangeEnd,
                    end: currentRange.start,
                    skipIndex: i,
                })
            }
            return acc
        }, [] as { start: number; end: number; skipIndex: number }[])
        // Figure out which deleted ranges are within the range to fill
        const gapsToFill = deletedRanges.reduce((acc, deleted) => {
            const isRangeStartInDeleted = rangeStart >= deleted.start && rangeStart <= deleted.end
            const isRangeEndInDeleted = rangeEnd >= deleted.start && rangeEnd <= deleted.end
            const isDeleteWithinRange = deleted.start >= rangeStart && deleted.end <= rangeEnd
            if (isDeleteWithinRange) {
                acc.push(deleted)
            } else if (isRangeStartInDeleted && !isRangeEndInDeleted) {
                acc.push({
                    start: rangeStart,
                    end: deleted.end,
                })
            } else if (isRangeEndInDeleted && !isRangeStartInDeleted) {
                acc.push({
                    start: deleted.start,
                    end: rangeEnd,
                })
            } else if (isRangeStartInDeleted && isRangeEndInDeleted) {
                acc.push({
                    start: rangeStart,
                    end: rangeEnd,
                })
            }
            return acc
        }, [] as { start: number; end: number }[])
        // Figure out which intervals were originally in those gaps
        const gapIntervals = gapsToFill
            .map((gap) => {
                return generateClipSchemaFromRange({
                    intervals,
                    startTime: gap.start,
                    endTime: gap.end,
                })
            })
            .flat()
        const mixedIntervals = [...clipSchema, ...gapIntervals].sort((a, b) => a.start - b.start)
        // Merge intervals that are contiguous and have the same centroid
        const newSchema = mixedIntervals.reduce((acc, interval, i) => {
            if (i === 0) {
                acc.push(interval)
                return acc
            }
            const prevInterval = acc[acc.length - 1]
            const prevEnd = prevInterval.start + prevInterval.duration
            const isContiguous = interval.start - prevEnd <= _c.SIGNIFICANT_DURATION_THRESHOLD
            const hasSameCentroid =
                prevInterval.centroid[0] === interval.centroid[0] &&
                prevInterval.centroid[1] === interval.centroid[1]
            const shouldMerge = hasSameCentroid && isContiguous
            if (shouldMerge) {
                acc[acc.length - 1] = {
                    ...prevInterval,
                    duration: prevInterval.duration + interval.duration,
                }
                return acc
            }
            acc.push(interval)
            return acc
        }, [] as IClipSchemaItem[])
        dispatchVideoAction(VideoAction.setClipSchema(newSchema))
    }
    const handleTogglePlay = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!controller.video.ref.current) {
            return
        }
        const video = controller.video.ref.current!
        if (video.paused) {
            if (video.currentTime >= clipEnd) {
                video.currentTime = clipStart
            }
            video.play()
        } else {
            video.pause()
        }
        e.currentTarget?.blur()
    }
    const handleClickSideBarOption = (option: ESidebarPanelName, tabIndex: number) => {
        controller.ui.setSideBarMode(option)
        setEditorTabIndex(tabIndex)
    }
    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            const HANDLED_KEYS = ["d", "D", "ArrowRight", "ArrowLeft", " "]
            if (!HANDLED_KEYS.includes(e.key) || isEditingCaptions) {
                return
            }
            if (e.key.toLowerCase() === "d") {
                handleClickSplitInterval()
                return
            }
            const video = controller.video.ref.current
            if (!video) {
                return
            }
            if (e.key === " ") {
                if (video.paused) {
                    video.play()
                } else {
                    video.pause()
                }
                return
            }
            // Use max estimate, as it will work for lower and higher fps videos
            const maxSingleFrameDuration = 1 / task.sourceFps!
            if (e.key === "ArrowRight") {
                video.currentTime += maxSingleFrameDuration
            } else if (e.key === "ArrowLeft") {
                video.currentTime -= maxSingleFrameDuration
            }
        },
        [intervals, video, isEditingCaptions],
    )
    const handleBreadcrumbClick = (destination: string) => {
        const requiresSaving = brandEditorRef.current?.hasChanges() || controller.hasChanges()
        if (requiresSaving) {
            AlertController.show({
                title: "Exit without saving changes?",
                message: "If you navigate away from this page your changes won't be saved.",
                primaryAction: {
                    label: "Exit",
                    onClick: () => router.push(destination),
                },
                secondaryAction: {
                    label: "Continue editing",
                },
                variant: "danger",
                size: "md",
            })
        } else {
            router.push(destination)
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress)
        return () => {
            window.removeEventListener("keydown", handleKeyPress)
        }
    }, [clipSchema, video, isEditingCaptions])
    const renderTimelineSection = () => {
        const aspectRatio = task.sourceWidth! / task.sourceHeight!
        return (
            <Box
                backgroundColor="var(--primary-bg-color)"
                height="var(--timeline-section-height)"
                borderTop="1px solid var(--ui-separator-color)"
            >
                <EditorTimeline
                    taskId={task.id}
                    videoRef={controller.video.ref}
                    clipSchema={videoState.clipSchema}
                    activeIntervalIndex={controller.video.intervalIndex}
                    hasStoryboards={!!task.hasStoryboards}
                    sourceAspectRatio={aspectRatio}
                    onUpdateStart={handleUpdateVideoStartByTime}
                    onUpdateEnd={handleUpdateVideoEndByTime}
                    onDeleteInterval={handleDeleteInterval}
                />
            </Box>
        )
    }
    const renderPlayBarSection = () => {
        const startStr = dayjs.duration(clipStart, "seconds").format("HH:mm:ss")
        const endStr = dayjs.duration(clipEnd, "seconds").format("HH:mm:ss")
        const durationStr = (() => {
            const minutes = Math.floor(clipDuration / 60)
            const seconds = clipDuration % 60
            let text = ""
            if (minutes > 0) {
                text = `${minutes} min `
            }
            text += `${seconds.toFixed(2)} secs`
            return text
        })()
        return (
            <Flex
                alignItems="center"
                justifyContent="space-between"
                paddingX="12px"
                height="var(--video-controls-height)"
                backgroundColor="var(--primary-bg-color)"
                borderTop="1px solid var(--ui-separator-color)"
            >
                <HStack
                    width="calc(var(--sidebar-panel-width) - 12px)"
                    height="var(--video-controls-height)"
                    borderRight={editorBorder}
                    pl="12px"
                >
                    <Text as="span" color="#C6C6C6">
                        {`${startStr} - ${endStr}`}
                    </Text>
                    <Icon as={BsDot} color="#C6C6C6" />
                    <Text as="span" color="white">
                        {durationStr}
                    </Text>
                </HStack>
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="calc(100% - var(--sidebar-panel-width))"
                >
                    <HStack flex="1">
                        <Tooltip
                            closeOnClick={false}
                            label={
                                <Flex alignItems="center">
                                    <Text color="white" marginRight="5px" marginBottom={0}>
                                        {"Split · "}
                                    </Text>
                                    <Text marginTop="-4px" marginBottom={0}>
                                        <Kbd backgroundColor="rgb(200, 200, 200)">d</Kbd>
                                    </Text>
                                </Flex>
                            }
                            placement="top"
                            hasArrow={true}
                            gutter={8}
                            padding="5px 8px"
                            backgroundColor="#313131"
                            borderRadius="md"
                            fontSize="sm"
                            openDelay={1_000}
                        >
                            <IconButton
                                aria-label="Split"
                                icon={<MdContentCut color="white" size="18px" />}
                                backgroundColor="transparent"
                                _hover={{
                                    backgroundColor: "var(--ui-separator-color)",
                                }}
                                onClick={handleClickSplitInterval}
                            />
                        </Tooltip>
                    </HStack>
                    <IconButton
                        variant="ghost"
                        aria-label="Play"
                        isRound={true}
                        isDisabled={controller.video.isLoadingPreview || controller.video.isLoading}
                        boxSize="40px"
                        fontSize="20px"
                        color="white"
                        margin="auto"
                        transition="background-color 0.3s ease-in-out"
                        backgroundColor={
                            controller.video.isPlaying ? "transparent" : "var(--ui-separator-color)"
                        }
                        _active={{
                            backgroundColor: "var(--ui-highlight-color)",
                        }}
                        _hover={{
                            backgroundColor: "var(--ui-highlight-color)",
                        }}
                        icon={controller.video.isPlaying ? <MdPause /> : <MdPlayArrow />}
                        onClick={handleTogglePlay}
                    />
                    <HStack flex="1" justifyContent="flex-end">
                        <Tooltip
                            closeOnClick={false}
                            isDisabled={!history.isUndoPossible}
                            label={
                                <Flex alignItems="center">
                                    <Text as="span" color="white" marginRight="5px">
                                        Undo
                                    </Text>
                                </Flex>
                            }
                            placement="top"
                            hasArrow={true}
                            gutter={8}
                            padding="5px 8px"
                            backgroundColor="#313131"
                            borderRadius="md"
                            fontSize="sm"
                            openDelay={1_000}
                        >
                            <IconButton
                                isDisabled={!history.isUndoPossible}
                                background="transparent"
                                icon={<Image src="/images/snippets/undo.svg" alt="Undo" />}
                                aria-label="Undo"
                                _hover={{
                                    backgroundColor: history.isUndoPossible
                                        ? "var(--ui-separator-color)"
                                        : undefined,
                                }}
                                pointerEvents={history.isUndoPossible ? "inherit" : "none"}
                                onClick={handleUndoLastAction}
                            />
                        </Tooltip>
                        <Tooltip
                            closeOnClick={false}
                            isDisabled={!history.isRedoPossible}
                            label={
                                <Flex alignItems="center">
                                    <Text as="span" color="white" marginRight="5px">
                                        Redo
                                    </Text>
                                </Flex>
                            }
                            placement="top"
                            hasArrow={true}
                            gutter={8}
                            padding="5px 8px"
                            backgroundColor="#313131"
                            borderRadius="md"
                            fontSize="sm"
                            openDelay={1_000}
                        >
                            <IconButton
                                isDisabled={!history.isRedoPossible}
                                background="transparent"
                                icon={<Image src="/images/snippets/redo.svg" alt="Redo" />}
                                aria-label="Redo"
                                title="Redo"
                                _hover={{
                                    backgroundColor: history.isRedoPossible
                                        ? "var(--ui-separator-color)"
                                        : undefined,
                                }}
                                pointerEvents={history.isRedoPossible ? "inherit" : "none"}
                                onClick={handleRedoLastAction}
                            />
                        </Tooltip>
                    </HStack>
                </Flex>
            </Flex>
        )
    }
    const renderBottomBarSection = () => {
        return (
            <Box
                backgroundColor="var(--primary-bg-color)"
                height="var(--bottombar-section-height)"
                borderTop="1px solid var(--ui-separator-color)"
            >
                <HStack
                    width="full"
                    height="full"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingX="20px"
                >
                    <TutorialStepWrapper
                        tutorialKey={EEditorTutorialSteps.EditorTutorialInfo}
                        isFinalStep
                        primaryButtonLabel="Done"
                        popoverPlacement="top"
                    >
                        <IconButton
                            aria-label="editor-tutorial-info"
                            background="transparent"
                            _hover={{ background: "transparent" }}
                            onClick={() => {
                                tutorialControllerRef.current.showTutorialStep(
                                    EEditorTutorialSteps.Design,
                                )
                            }}
                            icon={<FiInfo size={20} color="#5396FB" />}
                        />
                    </TutorialStepWrapper>
                    <Button
                        variant="primaryGreen"
                        leftIcon={<RiShareBoxLine color="var(--primary-bg-color)" />}
                        isLoading={controller.ui.isSaving}
                        isDisabled={controller.ui.isSaving}
                        _loading={{
                            color: "var(--primary-bg-color)",
                        }}
                        onClick={handleSaveAndCompile}
                    >
                        <Text as="span" color="var(--primary-bg-color)">
                            Save changes
                        </Text>
                    </Button>
                </HStack>
            </Box>
        )
    }
    const renderVideoPlayer = () => {
        const hasSubscription = isUserSubscribedToPlatform(currentUser)
        const isLoading = controller.video.isLoading || controller.video.isLoadingPreview
        return (
            <Flex position="relative" height="100%" width="100%" ref={viewerRef} overflow="hidden">
                <EditorVideo
                    url={controller.video.url}
                    viewerDimensions={controller.ui.dimensions}
                    sourceDimensions={{ width: task.sourceWidth!, height: task.sourceHeight! }}
                    transcript={videoState.captionWords}
                    clipSchema={videoState.clipSchema}
                    activeIntervalIndex={controller.video.intervalIndex}
                    intervals={intervals}
                    brand={controller.brand.selected}
                    cropMode={controller.ui.mode}
                    frameRate={task.sourceFps!}
                    isLoading={isLoading}
                    showVideoOverlay={showTiktokLayer}
                    showWatermark={!hasSubscription}
                    onVideoRef={controller.video.setRef}
                    onSetVideoFocus={handleUpdateVideoFocusForCurrentTime}
                />
            </Flex>
        )
    }
    const renderVideoSection = () => {
        return (
            <Flex
                flex="1"
                width="var(--video-section-width)"
                backgroundColor="var(--secondary-bg-color)"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                position="relative"
            >
                {renderVideoPlayer()}
                <HStack position="absolute" bottom="10px" right="10px">
                    {controller.ui.mode === EVideoFormat.Portrait && (
                        <VideoOverlayButton
                            isHighlighted={false}
                            icon={FaTiktok}
                            onClick={() => setShowTiktokLayer(!showTiktokLayer)}
                        />
                    )}
                </HStack>
            </Flex>
        )
    }
    const renderPanel = (panel: TSidebarPanel) => {
        return (
            <>
                {panel.description && (
                    <Text
                        width="100%"
                        padding="12px"
                        color="var(--secondary-text-color)"
                        fontSize="11px"
                        borderBottom="1px solid var(--ui-separator-color)"
                        marginBottom={0}
                    >
                        {panel.description}
                    </Text>
                )}
                {panel.mode === ESidebarPanelName.Captions && (
                    <EditorTranscript
                        videoRef={controller.video.ref}
                        videoState={videoState}
                        onSelectStartWord={(word) => handleUpdateVideoStartForCaption(word)}
                        onSelectEndWord={(word) => handleUpdateVideoEndForCaption(word)}
                        onChangeEditCaptionState={(v) => setEditingCaptions(v)}
                        onCaptionWordChange={(word) => handleUpdateCaptionWordText(word)}
                        onDeleteWords={handleDeleteWordRange}
                        onEnableWords={handleEnableWordRange}
                    />
                )}
                {panel.mode === ESidebarPanelName.Design && (
                    <ThemeContext.Provider value={EThemeMode.Dark}>
                        <BrandStylesEditor
                            ref={brandEditorRef}
                            selectedId={controller.brand.selected?.id}
                            brands={controller.brand.list}
                            onBrandStylesChange={controller.brand.update}
                            onChangeSelectedBrand={controller.brand.switch}
                        />
                    </ThemeContext.Provider>
                )}
            </>
        )
    }
    const renderSidebarPanels = () => {
        const sidebarPanels = controller.ui.sidebar
        return (
            <Box
                width="var(--sidebar-panel-width)"
                height="100%"
                backgroundColor="var(--primary-bg-color)"
                borderRight={editorBorder}
                position="relative"
            >
                {editorTabIndex === 0 && renderPanel(sidebarPanels[0])}
                {editorTabIndex === 1 && renderPanel(sidebarPanels[1])}
            </Box>
        )
    }
    const renderSidebarButton = (
        data: {
            isOpen: boolean
            text: string
            mode: ESidebarPanelName
            icon: IconType
        },
        tabIndex: number,
    ) => {
        const { isOpen, text, mode, icon } = data

        const Element = (
            <Box
                key={text}
                onClick={() => {
                    handleClickSideBarOption(mode, tabIndex)
                }}
                as="button"
                width="full"
                height="75px"
                padding="10px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                backgroundColor={isOpen ? secondaryBgColor : primaryBgColor}
                _hover={{ backgroundColor: secondaryBgColor }}
            >
                <Icon
                    as={icon}
                    boxSize="24px"
                    color={isOpen ? highlightColor : "white"}
                    transition="color 0.3s ease-in"
                    marginBottom="6px"
                />
                <Text marginBottom={0} fontWeight="600" fontSize="11px" color="white">
                    {text}
                </Text>
            </Box>
        )

        if (data.mode !== ESidebarPanelName.Design) {
            return Element
        }

        return (
            <TutorialStepWrapper
                key={EEditorTutorialSteps.Design}
                tutorialKey={EEditorTutorialSteps.Design}
                nextTutorialKey={EEditorVideoTutorialSteps.DragFrame}
                popoverPlacement="right"
            >
                {Element}
            </TutorialStepWrapper>
        )
    }
    return (
        <TutorialWrapper
            ref={tutorialControllerRef}
            initialiseStepAfterMount={EEditorTutorialSteps.Design}
            steps={EditorTutorialStepsMapping}
        >
            <MetaTags title="Edit your clip" />
            <Flex
                position="relative"
                flexDir="column"
                maxWidth="100%"
                height="100vh"
                sx={{
                    // Colors
                    "--primary-bg-color": primaryBgColor,
                    "--secondary-bg-color": secondaryBgColor,
                    "--secondary-text-color": secondaryTextColor,
                    "--ui-highlight-color": highlightColor,
                    "--ui-separator-color": uiSeparatorColor,
                    "--active-word-color": activeWordColor,
                    // Dimensions
                    "--sidebar-width": "75px",
                    "--sidebar-panel-width": "33.33vw",
                    "--video-section-width": "calc(66.66vw - var(--sidebar-panel-width))",
                    "--video-controls-height": "55px",
                    "--timeline-section-height": "150px",
                    "--bottombar-section-height": "66px",
                    "--navbar-height": "70px",
                    "--transcript-editor-height":
                        "calc(100vh - 40px - var(--video-controls-height) - var(--timeline-section-height) - var(--bottombar-section-height) - var(--navbar-height))",
                }}
                userSelect="none"
            >
                <Head>
                    {/* Make overscroll/overflow color UI color */}
                    <style global>{`
                    html {
                        background-color: ${primaryBgColor} !important;
                    }
                `}</style>
                </Head>
                <Container
                    as="header"
                    display="flex"
                    alignItems="center"
                    maxWidth="100%"
                    paddingX={[3, 6, 8]}
                    paddingY={6}
                    height="var(--navbar-height)"
                    backgroundColor="var(--primary-bg-color)"
                    borderBottom="1px solid var(--ui-separator-color)"
                >
                    <Flex flex="1" alignItems="center">
                        <NextLink href="/clips" passHref legacyBehavior>
                            <Box as="a" flexShrink={0} marginRight="30px">
                                <Image
                                    src="/images/livelink_logo.svg"
                                    alt="LiveLink Logo"
                                    width={["32px", "28px"]}
                                />
                            </Box>
                        </NextLink>
                        <Breadcrumb
                            separator={
                                <Text as="span" color="rgba(255, 255, 255, 0.4)" fontWeight="bold">
                                    ·
                                </Text>
                            }
                            fontWeight="600"
                            fontSize="13px"
                        >
                            <BreadcrumbItem>
                                <BreadcrumbLink
                                    onClick={() => handleBreadcrumbClick(linkToSnippetProjects())}
                                    color="rgba(255, 255, 255, 0.4)"
                                >
                                    All projects
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <BreadcrumbLink
                                    onClick={() => handleBreadcrumbClick(linkToProject(task.id))}
                                    color="rgba(255, 255, 255, 0.4)"
                                >
                                    Project
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage color="white">
                                <Text
                                    pointerEvents="none"
                                    color="rgba(255, 255, 255, 0.9)"
                                    marginBottom="0"
                                >
                                    {snippetVideo.title}
                                </Text>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Flex>
                    <UserNavbarMenu currentUser={currentUser!} />
                </Container>
                <Flex width="100vw" height="calc(100vh - var(--navbar-height))" position="relative">
                    <VStack
                        spacing={0}
                        width="var(--sidebar-width)"
                        position="relative"
                        backgroundColor="var(--primary-bg-color)"
                        borderRight="1px solid var(--ui-separator-color)"
                        height="full"
                    >
                        {controller.ui.sidebar.map((option, i) => renderSidebarButton(option, i))}
                    </VStack>
                    <Flex flexDirection="column" height="full">
                        <Flex
                            flexDir="row"
                            width={`calc(100vw - var(--sidebar-width))`}
                            height={`calc(100% - (var(--video-controls-height) + calc(var(--timeline-section-height) + var(--bottombar-section-height))))`}
                            position="relative"
                        >
                            {renderSidebarPanels()}
                            {renderVideoSection()}
                        </Flex>
                        <Flex justifyContent="flex-end" flexDir="column" position="relative">
                            {renderPlayBarSection()}
                            {renderTimelineSection()}
                            {renderBottomBarSection()}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </TutorialWrapper>
    )
}

EditorPage.displayName = "EditorPage"

const VideoOverlayButton = (props: {
    onClick: () => void
    isHighlighted: boolean
    icon: IconType
    iconProps?: Record<string, any>
}) => {
    const { iconProps = {} } = props
    return (
        <Box
            as="button"
            onClick={props.onClick}
            width="36px"
            height="36px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="6px"
            transition="background-color 0.2s ease-in-out"
            backgroundColor="var(--primary-bg-color)"
            _hover={{ backgroundColor: "var(--ui-separator-color)" }}
            zIndex={5}
        >
            <Icon
                as={props.icon}
                boxSize="20px"
                color={props.isHighlighted ? highlightColor : "white"}
                {...iconProps}
            />
        </Box>
    )
}

const fetchDataRequirements = async ({
    services,
    currentUser,
    query,
}: IGetServerSidePropsContext) => {
    const { clipId } = query

    if (!clipId) {
        return {
            redirect: {
                destination: linkToSnippetProjects(),
            },
        }
    }

    if (!currentUser) {
        return {
            redirect: {
                destination: linkToLogin(
                    {
                        destination: linkToEditClip(clipId as string),
                    },
                    true,
                ),
            },
        }
    }

    let task
    try {
        const res = await services.api.getSnippetTaskBySnippetId(clipId as string)
        task = res.task
    } catch (e) {
        return {
            redirect: {
                destination: linkToSnippetProjects(),
            },
        }
    }
    const snippetVideo = task.snippets.find((s) => s.id === clipId) as BaseSnippetVideo

    const { brands } = await services.api.getUserBrandStyles()

    return {
        props: {
            currentUser,
            brands,
            task,
            snippetVideo,
            appShell: { disable: true },
        },
    }
}

export const getServerSideProps = withAppContext(fetchDataRequirements)

export default withAuthUser({
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
})(EditorPage)
