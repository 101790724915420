import { SnippetTask } from "@prisma/client"

import { ICropInterval, EVideoFormat, PORTRAIT_ASPECT_RATIO, IClusterInterval } from "../../@types/snippet_types"
import { _l } from "../../utils/logging_util"

export const getCropChangeTimes = (args: {
    intervals: IClusterInterval[]
    format: EVideoFormat
    videoWidth: number
    videoHeight: number
}) => {
    const { intervals: faceChanges, videoWidth, videoHeight } = args

    let croppedWidth: number
    let croppedHeight: number
    if (args.format === EVideoFormat.Portrait) {
        croppedHeight = videoHeight
        const isInputPortrait = videoWidth < videoHeight
        if (isInputPortrait) {
            croppedWidth = videoWidth
        } else {
            croppedWidth = Math.round(videoHeight * PORTRAIT_ASPECT_RATIO)
        }
    } else if (args.format === EVideoFormat.Landscape) {
        const isInputLandscape = videoWidth > videoHeight
        if (isInputLandscape) {
            croppedHeight = videoHeight
            croppedWidth = videoWidth
        } else {
            croppedWidth = videoWidth
            croppedHeight = Math.round(videoWidth * PORTRAIT_ASPECT_RATIO)
        }
    } else {
        const shortestDimensionLength = Math.min(videoWidth, videoHeight)
        croppedWidth = shortestDimensionLength
        croppedHeight = shortestDimensionLength
    }

    const cropTimes = faceChanges.map((changeTime) => {
        const [xPos, yPos] = changeTime.centroid
        const xfaceCentered = Math.round(xPos - croppedWidth / 2)
        const xStartBoundAdjusted = Math.max(xfaceCentered, 0)
        const xEndBoundAdjusted = Math.min(
            xStartBoundAdjusted,
            Math.round(videoWidth - croppedWidth),
        )
        const yFaceCentered = Math.round(yPos - croppedHeight / 2)
        const yStartBoundAdjusted = Math.max(yFaceCentered, 0)
        const yEndBoundAdjusted = Math.min(
            yStartBoundAdjusted,
            Math.round(videoHeight - croppedHeight),
        )
        return {
            start: changeTime.start,
            duration: changeTime.duration,
            width: croppedWidth,
            height: videoHeight!,
            x: xEndBoundAdjusted,
            y: yEndBoundAdjusted,
        } as ICropInterval
    })
    return cropTimes
}

export const applySmoothingToIntervals = <T extends { duration: number }>(intervals: T[]) => {
    const MIN_CROP_DURATION_SECONDS = 1.5
    return intervals.reduce((acc, interval, i) => {
        if (i === 0) {
            return [interval]
        }
        if (interval.duration >= MIN_CROP_DURATION_SECONDS) {
            return [...acc, interval]
        }
        const allPreviousChanges = acc.slice()
        const prevChange = allPreviousChanges.pop()!
        return [
            ...allPreviousChanges,
            {
                ...prevChange,
                duration: prevChange.duration + interval.duration,
            },
        ]
    }, [] as T[])
}

export const getFaceChangesKey = (task: SnippetTask) => {
    if (!!task.faceChangesUpdatedKey) {
        return task.faceChangesUpdatedKey!
    }
    return task.faceChangesKey!
}

export const getTranscriptKey = (task: SnippetTask) => {
    if (!!task.transcriptUpdatedKey) {
        return task.transcriptUpdatedKey!
    }
    return task.transcriptKey!
}
