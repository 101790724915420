import {
    Button,
    Flex,
    PlacementWithLogical,
    Popover,
    PopoverAnchor,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    Text,
} from "@chakra-ui/react"
import debounce from "lodash/debounce"
import React, { cloneElement, MouseEventHandler, useContext, useEffect, useState } from "react"

import { ITutorialReadyStep, TutorialContext } from "./TutorialWrapper"

interface ITutorialWrapperProps {
    tutorialKey: string
    nextTutorialKey?: string
    isFinalStep?: boolean
    currentStep?: ITutorialReadyStep
    onShowStep?: () => void
    onFinish?: () => void
    children?: JSX.Element
    popoverPlacement: PlacementWithLogical
    primaryButtonLabel?: string
    placeholderPosition?: {
        top?: number | string
        right?: number | string
        left?: number | string
    }
}

export const TutorialStepWrapper = ({
    tutorialKey,
    isFinalStep,
    nextTutorialKey,
    popoverPlacement,
    primaryButtonLabel = "Next",
    placeholderPosition = { top: 0, left: 0 },
    children = (
        <Text
            marginBottom={0}
            position="absolute"
            top={placeholderPosition?.top}
            left={placeholderPosition?.left}
            right={placeholderPosition?.right}
        />
    ),
    onShowStep,
    onFinish,
}: ITutorialWrapperProps) => {
    const tutorialContext = useContext(TutorialContext)
    const [shownText, setShownText] = useState("")

    const debounceUpdateShownText = debounce((text: string) => {
        setShownText(text)
    }, 20)

    useEffect(() => {
        if (!tutorialContext || !tutorialContext.currentTutorialStep) {
            return
        }
        const { content } = tutorialContext.currentTutorialStep
        if (shownText) {
            debounceUpdateShownText(content)
        } else {
            setShownText(content)
        }
    }, [tutorialContext, shownText])

    useEffect(() => {
        return () => {
            debounceUpdateShownText.cancel()
        }
    }, [])

    useEffect(() => {
        if (!onShowStep || !tutorialContext?.currentTutorialStep?.id) {
            return
        }
        onShowStep()
    }, [tutorialContext])

    const handlePrimaryButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation()
        if (!tutorialContext) {
            return
        }
        if (!isFinalStep && nextTutorialKey) {
            tutorialContext.showTutorialStep(nextTutorialKey)
            return
        }
        tutorialContext.finishTutorialSteps()
        if (onFinish) {
            onFinish()
        }
    }

    if (!tutorialContext) {
        return null
    }

    const { currentTutorialStep, finishTutorialSteps } = tutorialContext

    const renderButtons = () => {
        return (
            <Flex alignItems="flex-start" justifyContent="space-between" width="100%">
                {!isFinalStep && (
                    <Button
                        pointerEvents="auto"
                        onClick={finishTutorialSteps}
                        background="transparent"
                        color="#FFFFFF"
                        fontSize="15px"
                        fontWeight="500"
                        paddingY="4px"
                        paddingX="7px"
                        lineHeight="1.07"
                        height="auto"
                        borderRadius="3px"
                        marginBottom="10px"
                        marginLeft="5px"
                        _hover={{ background: "rgba(255, 255, 255, 0.2)", color: "#FFFFFF" }}
                    >
                        Skip
                    </Button>
                )}
                <Button
                    variant="primary"
                    pointerEvents="auto"
                    onClick={handlePrimaryButtonClick}
                    marginLeft={isFinalStep ? "auto" : 0}
                    fontSize="15px"
                    fontWeight="500"
                    paddingY="4px"
                    paddingX="7px"
                    lineHeight="1.07"
                    height="auto"
                    borderRadius="3px"
                    marginBottom="10px"
                    marginRight="10px"
                >
                    {primaryButtonLabel}
                </Button>
            </Flex>
        )
    }
    const renderContent = () => {
        if (!currentTutorialStep) {
            return null
        }

        return (
            <Flex
                flexDirection="column"
                gap="14px"
                data-testid={`tutorial-step-content-${tutorialKey}`}
            >
                <Text
                    fontSize="16px"
                    fontWeight="500"
                    marginBottom={0}
                    color="#FFFFFF"
                    paddingTop="10px"
                    paddingX="10px"
                    lineHeight="1.2"
                    letterSpacing="0.04px"
                >
                    {shownText}
                </Text>
                {renderButtons()}
            </Flex>
        )
    }

    const isTooltipShown = currentTutorialStep && currentTutorialStep.id === tutorialKey

    return (
        <Popover
            isOpen={isTooltipShown}
            placement={popoverPlacement}
            closeOnBlur={false}
            closeOnEsc={false}
        >
            <PopoverAnchor>
                {cloneElement(children, { "data-tutorial-key": tutorialKey })}
            </PopoverAnchor>
            <PopoverContent borderColor="transparent" background="#5398FB" maxWidth="165px">
                <PopoverArrow
                    sx={{
                        "--popper-arrow-bg": "#5398FB",
                        "--popper-arrow-default-shadow": "#5398FB !important",
                    }}
                />
                <PopoverBody pointerEvents="none" padding="0">
                    {renderContent()}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}
