import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ImmerReducer, useImmerReducer } from "use-immer"

export enum EHistoryAction {
    SetInitial = "SET_INITIAL",
    SetState = "SET_STATE",
    Undo = "UNDO",
    Redo = "REDO",
    Reset = "RESET",
    ConfirmUpdate = "CONFIRM_UPDATE",
    ReplaceState = "REPLACE_STATE",
}

type HistoryState<T> = {
    initial: T
    past: T[]
    present: T
    future: T[]
    requiresUpdate: boolean
}

export type IReducerAction<T extends string> = {
    type: T
    data?: Record<string, any>
}

const historyReducer = (
    draft: HistoryState<Record<string, any>>,
    action: IReducerAction<EHistoryAction>,
) => {
    const { past, present, future, initial } = draft
    if (action.type === EHistoryAction.SetInitial) {
        draft.initial = action.data!
        draft.requiresUpdate = false
    } else if (action.type === EHistoryAction.SetState) {
        draft.past.push(draft.present)
        draft.present = action.data!
        draft.future = []
        draft.requiresUpdate = false
    } else if (action.type === EHistoryAction.Undo) {
        draft.present = past[past.length - 1]
        draft.past = past.slice(0, past.length - 1)
        draft.future = [present, ...future]
        draft.requiresUpdate = true
    } else if (action.type === EHistoryAction.Redo) {
        draft.present = future[0]
        draft.past = [...past, present]
        draft.future = future.slice(1)
        draft.requiresUpdate = true
    } else if (action.type === EHistoryAction.Reset) {
        draft.present = initial
        draft.past = []
        draft.future = []
        draft.requiresUpdate = true
    } else if (action.type === EHistoryAction.ConfirmUpdate) {
        draft.requiresUpdate = false
    }
}

const useHistoryReducer = <T extends string, S extends Record<string, any>>(
    reducer: ImmerReducer<S, IReducerAction<T | EHistoryAction.ReplaceState>>,
    initialState: S,
) => {
    const [mainState, mainDispatch] = useImmerReducer(reducer, initialState)
    const [historyState, historyDispatch] = useImmerReducer<
        HistoryState<S>,
        IReducerAction<EHistoryAction>
    >(historyReducer, {
        past: [],
        future: [],
        present: mainState,
        initial: mainState,
        requiresUpdate: false,
    })
    const updateMode = useRef(false)
    const { past, present, future } = historyState

    useEffect(() => {
        // This is true when mainState is updated via history change
        if (updateMode.current === true) {
            // Set to false to continue normal operation
            updateMode.current = false
            return
        }
        // When the mainState has a new state, update the present historyState
        historyDispatch({ type: EHistoryAction.SetState, data: mainState })
    }, [mainState])

    useEffect(() => {
        // After modifying history via undo/redo/reset, update the mainState
        if (historyState.requiresUpdate) {
            updateMode.current = true
            mainDispatch({ type: EHistoryAction.ReplaceState, data: historyState.present })
            historyDispatch({ type: EHistoryAction.ConfirmUpdate })
        }
    }, [historyState.requiresUpdate])

    const setState = (action: IReducerAction<T>) => {
        mainDispatch(action)
    }
    const setInitial = (data: S) => {
        historyDispatch({ type: EHistoryAction.SetInitial, data })
    }
    const getInitial = () => {
        return historyState.initial
    }
    const undo = () => historyDispatch({ type: EHistoryAction.Undo })
    const redo = () => historyDispatch({ type: EHistoryAction.Redo })
    const reset = () => {
        historyDispatch({ type: EHistoryAction.Reset })
    }

    const isUndoPossible = past && past.length > 0
    const isRedoPossible = future && future.length > 0

    return {
        state: present,
        setState: setState,
        changes: {
            undo,
            redo,
            reset,
            setInitial,
            getInitial,
            isUndoPossible,
            isRedoPossible,
        },
    }
}

export default useHistoryReducer
