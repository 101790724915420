export enum EVideoTaskMode {
    Chapters = "chapters",
    Snippets = "snippets",
}

export enum EVideoTaskSource {
    Youtube = "youtube",
    Upload = "upload",
}

export enum EVideoFormat {
    Portrait = "portrait",
    Landscape = "landscape",
    Square = "square",
}

export enum EVideoResolution {
    "1080p" = 1080,
    "720p" = 720,
}

export const PORTRAIT_ASPECT_RATIO = 9 / 16
export const LANDSCAPE_ASPECT_RATIO = 16 / 9

export type TSnippetFormat = "square" | "landscape" | "portrait"

export enum EVideoTaskStatus {
    // User has submitted task but signup is required to process it
    AwaitingSignup = "awaiting-signup",
    // Task has been added to the queue but not begin processing
    Open = "open",
    // Task has been taken off the queue and is being processed by a worker
    Processing = "processing",
    EnablingFastStart = "enabling-fast-start",
    CapturingThumbnail = "capturing-thumbnail",
    CalculatingDuration = "calculating-duration",
    ExtractingAudio = "extracting-audio",
    DownloadingAudio = "downloading-audio",
    TriggeringTranscription = "triggering-transcription",
    DownloadingVideo = "downloading-video",
    UploadingSourceVideo = "uploading-source-video",
    TriggeringFaceDetection = "triggering-face-detection",
    AwaitingFaceDetections = "storing-face-detections",
    PollingTranscript = "polling-transcript",
    Snippeting = "snippeting",
    Complete = "complete",
}

export enum EVideoExportStatus {
    Open = "open",
    Processing = "processing",
    UpdateTranscript = "update-transcript",
    GetFaceDetections = "get-face-detections",
    Cutting = "cutting",
    Complete = "complete",
}

export enum EVideoTaskErrorTemplate {
    Unknown = "unknown",
    Stalled = "stalled",

    ProcessingIncomplete = "processing-incomplete",
    PremieresInFuture = "premieres-in-future",

    // Audio failure reasons
    NoWordsDetected = "no-words-detected",
    LowWordsDetected = "low-words-detected",
    NoAudioStream = "no-audio-stream",

    // Download failure reasons
    LambdaStorageLimited = "lambda-storage-limited",
    UnavailableVideo = "unavailable-video",
    UnavailableVideoCountry = "unavailable-video-country",
    PrivateVideo = "private-video",
    InappropriateVideo = "inappropriate-video",
    ContentBlocked = "content-blocked-copywright",
    ContentBlockedCountry = "content-blocked-country",
    ContentBlockedCountryCopyright = "content-blocked-country-copyright",
    DownloadAuthRequired = "download-auth-required",
    
    // Snippet generation
    NoClipsCreated = "no-clips-created",
}

export enum EProjectBannerReasons {
    InsufficientResults = "insufficient-results",
    MinutesAllowanceExceeded = "minutes-allowance-exceeded",
}

/**
 * Represents a word in the transcript before exporting and while processing transcription outputs
 */
export type ITranscriptWord = {
    start: number
    end: number
    text: string
    speaker?: string
}

/**
 * Represents a word in the transcript used for exporting and user preview
 * @rowIndex refers to the index of the caption this word belongs to
 */
export type ICaptionWord = {
    text: string
    start: number
    end: number
    rowIndex: number
    styles?: Record<string, string | number> | null
}

export type ICaptionWordWithFrames = ICaptionWord & {
    startFrame: number
    endFrame: number
}

// The format stored in S3 to be used at export for cropping
export type IClusterInterval = {
    start: number
    duration: number
    centroid: number[]
}

// Exact dimensions and interval of crop used in export
export type ICropInterval = {
    start: number
    duration: number
    width: number
    height: number
    x: number
    y: number
}

export interface IClipSchemaItem {
    type: "source_video" | "image" | "video"
    source?: string
    start: number
    duration: number
    centroid: number[]
}

export interface IFaceDetectionSfnInput {
    callbackUrl: string
    taskId: string
    environment: "prod" | "staging"
    bucket: string
    requestKey: string
    videoKey: string
    sourceHeight: number
    sourceWidth: number
    sourceFps: number
    videoDuration: number
    faceDetectionsFolder: string
    asdDetectionsFolder: string
    asdDetectionRequestsFolder: string
    sceneDetectionsFolder: string
    desiredCopies: number
    asdChunkDuration: number
    indexList: number[]
}
export interface IFaceDetectionSfnOutput {
    faceDetectionsKey: string
    faceChangesKey: string
}
export interface IDownloadVideoSfnInput {
    callbackUrl: string
    taskId: string
    type: "audio" | "video"
    bucket: string
    videoId: string
    languageCode: string
}
export interface IDownloadVideoSfnOutput {
    outputKey: string
}
export interface IExtractAudioSfnInput {
    callbackUrl: string
    taskId: string
    bucket: string
    videoKey: string
}
export interface IExtractAudioSfnOutput {
    outputKey: string
}

export interface IExportSfnInput {
    // Reference
    exportId: string
    callbackUrl: string

    // Both lambdas
    environment: "prod" | "staging"
    snippetId: string
    bucket: string
    videoKey: string
    outputWidth: number
    outputHeight: number
    frameRate: number
    title: string
    hasWatermark: boolean

    // Clipper
    intervals: ICropInterval[]
    requiresPadding: boolean
    bitrate: number

    // Remotion
    hasCaptions: boolean
    captionsKey?: string
    brandStyle: Record<string, any>
}

export interface IExportSfnOutput {
    snippetId: string
    bucket: string
    clipKey: string
    thumbnailKey: string
}
