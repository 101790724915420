import type { PlayerRef } from "@remotion/player/dist/cjs/player-methods"
import { MutableRefObject, RefObject, useCallback, useEffect, useState } from "react"

import _c from "../configs/constants"
import { useClipSchema } from "./useClipSchema"
import { IVideoState } from "./useVideoReducer"

// Test html video events
// https://www.w3.org/2010/05/video/mediaevents.html

/* 
    useVideoState
    - Sets up and tracks the load state of a video
    - Tracks the play state of a video
    - Tracks the active interval of a clip
    - Keeps the video in sync with the clip schema
    - Keeps captions in sync with the clip schema
*/
const useVideoState = (props: {
    videoRef: MutableRefObject<HTMLVideoElement | null>
    captionsRef: RefObject<PlayerRef>
    state: IVideoState
    frameRate: number
}) => {
    const { videoRef, captionsRef, frameRate } = props
    const { clipSchema } = props.state
    const [isPlaying, setPlaying] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [activeIntervalIndex, setActiveIntervalIndex] = useState(0)
    const { clipStart, clipEnd, schemaTimeRanges, deletedRanges } = useClipSchema(clipSchema)

    const onUpdateVideoPlayingState = useCallback(
        (e: any) => {
            if (!videoRef.current) {
                return
            }
            const isPlaying = !videoRef.current!.paused
            if (isPlaying) {
                // If the browser thinks the video autoplayed it will pause the video
                // without triggering a pause event. This is a workaround to force playing.
                setTimeout(() => {
                    const isActuallyPlaying = !videoRef.current!.paused
                    if (!isActuallyPlaying) {
                        // @ts-ignore
                        videoRef.current?.play(e)
                    }
                }, 10)
                captionsRef.current?.play(e)
            } else {
                captionsRef.current?.pause()
            }
            setPlaying(isPlaying)
        },
        [videoRef.current, captionsRef.current],
    )
    const onVideoLoaded = useCallback((e: Event) => {
        const video = videoRef.current!
        const hasCompletedFirstLoad = video.readyState >= 3
        if (!hasCompletedFirstLoad) {
            return
        }

        const hasLoadedRange = [...Array(video.buffered.length).keys()]
            .map((index) => ({
                start: video.buffered.start(index),
                end: video.buffered.end(index),
            }))
            .some((range) => clipStart >= range.start)
        if (hasLoadedRange) {
            video.removeEventListener("loadeddata", onVideoLoaded)
            video.removeEventListener("progress", onVideoLoaded)
            video.removeEventListener("seeked", onVideoLoaded)
            video.removeEventListener("play", onUpdateVideoPlayingState)
            video.removeEventListener("pause", onUpdateVideoPlayingState)
            setLoading(false)
            return
        }
    }, [clipStart, videoRef.current])
    const onVideoSeeking = useCallback(() => {
        // Figure out location in clip schema and adjust the ticker
        const videoTime = videoRef.current!.currentTime
        const intervalIndex = clipSchema.findIndex((interval) => {
            return videoTime >= interval.start && videoTime < interval.start + interval.duration
        })
        if (intervalIndex === -1 || intervalIndex === activeIntervalIndex) {
            return
        }
        setActiveIntervalIndex(intervalIndex)
        maintainCaptions(videoRef.current!)
    }, [clipSchema, activeIntervalIndex])
    useEffect(() => {
        if (isLoading || !videoRef.current) {
            return
        }
        const video = videoRef.current
        video.addEventListener("seeking", onVideoSeeking)
        video.addEventListener("play", onUpdateVideoPlayingState)
        video.addEventListener("pause", onUpdateVideoPlayingState)
        return () => {
            video.removeEventListener("seeking", onVideoSeeking)
            video.removeEventListener("play", onUpdateVideoPlayingState)
            video.removeEventListener("pause", onUpdateVideoPlayingState)
        }
    }, [
        isLoading,
        clipSchema,
        activeIntervalIndex,
        videoRef.current,
        captionsRef.current,
        clipStart,
    ])
    const maintainSchemaInterval = useCallback(
        (video: HTMLVideoElement) => {
            if (clipSchema.length === 0) {
                return
            }
            // If video is at time 0, it likely has not been seeked yet,
            // so we can take clipStart as the better time.
            const videoTime = video.currentTime ?? clipStart
            const intervalIndex = clipSchema.findIndex((interval) => {
                return videoTime >= interval.start && videoTime < interval.start + interval.duration
            })
            if (intervalIndex === -1 || intervalIndex === activeIntervalIndex) {
                return
            }
            setActiveIntervalIndex(intervalIndex)
        },
        [clipSchema, activeIntervalIndex, clipStart],
    )
    const maintainClipInRange = useCallback(
        (video: HTMLVideoElement) => {
            if (video.currentTime < clipStart) {
                video.currentTime = clipStart
            } else if (video.currentTime > clipEnd) {
                video.pause()
                video.currentTime = clipStart
            } else {
                const skipRange = deletedRanges.find(
                    (skip) => video.currentTime >= skip.start && video.currentTime <= skip.end,
                )
                const isInsideDeletedRange = !!skipRange
                if (isInsideDeletedRange) {
                    // Without SIGNIFICANT_DURATION_THRESHOLD video will get caught in an infinite loop
                    // as it will keep seeking to the same time
                    video.currentTime = skipRange.end + _c.SIGNIFICANT_DURATION_THRESHOLD
                }
            }
        },
        [clipStart, clipEnd, deletedRanges],
    )
    const maintainCaptions = useCallback(
        (video?: HTMLVideoElement) => {
            if (!video || schemaTimeRanges.length === 0) {
                return
            }
            // If video is at time 0, it likely has not been seeked yet,
            // so we can take clipStart as the better time.
            const currentTime = video.currentTime ?? clipStart
            // Get index of current interval in clip
            const intervalIndex = clipSchema.findIndex((interval) => {
                return (
                    currentTime >= interval.start &&
                    currentTime < interval.start + interval.duration
                )
            })
            if (intervalIndex === -1) {
                return
            }
            const videoIntervalStart = clipSchema[intervalIndex].start
            const schemaIntervalStart = schemaTimeRanges[intervalIndex].start
            const elapsedInInterval = currentTime - videoIntervalStart
            const seekTime = schemaIntervalStart + elapsedInInterval
            const currentFrame = Math.floor(seekTime * frameRate)
            const seekFrame = currentFrame < 0 ? 0 : currentFrame
            if (seekFrame === captionsRef.current?.getCurrentFrame()) {
                return
            }
            captionsRef.current?.seekTo(seekFrame)
        },
        [clipStart, clipSchema, schemaTimeRanges, captionsRef.current],
    )
    useEffect(() => {
        const video = videoRef.current
        if (!video) {
            return
        }
        maintainSchemaInterval(video)
        maintainClipInRange(video)
        maintainCaptions(video)
        const intervalId = setInterval(() => {
            if (video.paused) {
                return
            }
            maintainSchemaInterval(video)
            maintainCaptions(video)
            maintainClipInRange(video)
        }, 50)
        return () => {
            clearInterval(intervalId)
        }
    }, [
        clipSchema,
        clipStart,
        clipEnd,
        videoRef.current,
        captionsRef.current,
        schemaTimeRanges,
        activeIntervalIndex,
        deletedRanges,
    ])
    const handleVideoRef = (ref: MutableRefObject<HTMLVideoElement | null>) => {
        const video = ref.current
        if (!video) {
            return
        }

        video.addEventListener("loadeddata", onVideoLoaded)
        // Required for Safari as it doesn't always trigger loadeddata for .readyStates 3 and 4
        video.addEventListener("progress", onVideoLoaded)
        // Before video has loaded, seek handler is same as onVideoLoaded
        video.addEventListener("seeked", onVideoLoaded)

        video.addEventListener("play", onUpdateVideoPlayingState)
        video.addEventListener("pause", onUpdateVideoPlayingState)

        // Triggers video data load as video is initially paused
        setLoading(true)
        video.currentTime = clipStart
        video.load()

        videoRef.current = video
    }
    return {
        isPlaying,
        isLoading,
        activeIntervalIndex,
        setRef: handleVideoRef,
    }
}

export default useVideoState
