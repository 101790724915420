import { MutableRefObject, useCallback, useEffect } from "react"

export const useDismissableOverlay = (
    ref: MutableRefObject<HTMLElement | null>,
    onDismiss: (e: KeyboardEvent | MouseEvent) => void,
) => {
    const handleEscapeKey = useCallback(
        (e: KeyboardEvent) => {
            if (e.code === "Escape") {
                onDismiss(e)
            }
        },
        [onDismiss],
    )

    const handleClickOutsideRef = useCallback(
        (e: MouseEvent) => {
            const element = ref.current
            if (element && !element.contains(e.target as Node)) {
                onDismiss(e)
            }
        },
        [ref, onDismiss],
    )

    useEffect(() => {
        document.addEventListener("keydown", handleEscapeKey)
        document.addEventListener("mousedown", handleClickOutsideRef)

        return () => {
            document.removeEventListener("keydown", handleEscapeKey)
            document.removeEventListener("mousedown", handleClickOutsideRef)
        }
    }, [ref, handleEscapeKey, handleClickOutsideRef])
}
