import { BrandStyle, SnippetTask } from "@prisma/client"
import { PlayerRef } from "@remotion/player"
import { produce } from "immer"
import _ from "lodash"
import { useRouter } from "next/router"
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { IconType } from "react-icons/lib"
import { MdAutoFixHigh, MdClosedCaptionOff } from "react-icons/md"

import { EVideoFormat, IClipSchemaItem } from "../@types/snippet_types"
import { XOR } from "../@types/utility_types"
import AlertController from "../controllers/alert_controller"
import { PostUpdateSnippetQuery, TIntervalChange } from "../pages/api/v1/snippet/snippets/update"
import { BaseSnippetTask } from "../server/dao/snippet_task_dao"
import { BaseSnippetVideo } from "../server/dao/snippet_video_dao"
import api from "../services/root_service"
import { EAWSCFDistribution, getCloudFrontUrl, linkToProject } from "../utils/link_util"
import {
    generateClipSchemaFromRange,
    getCropDimensionsForFormat,
    isClipSchema,
} from "../utils/video_util"
import useVideoReducer from "./useVideoReducer"
import useVideoState from "./useVideoState"
import useWindowSize from "./useWindowSize"

export enum ESidebarPanelName {
    Captions = "captions",
    Design = "design",
}
export type TSidebarPanel = {
    isOpen: boolean
    mode: ESidebarPanelName
    text: string
    icon: IconType
    description: string
}

const SIDEBAR_DATA_MODEL = [
    {
        mode: ESidebarPanelName.Captions,
        text: "Captions",
        icon: MdClosedCaptionOff,
        description: "Edit words in the video transcript or change the video length.",
    },
    {
        mode: ESidebarPanelName.Design,
        text: "Design",
        icon: MdAutoFixHigh,
        description: "Select your captions style and format for this video.",
    },
]

/*
    FIX: Some logic is mixed between this and EditorPage
    
    useEditorController
    - Sets up the dimensions for the viewer
    - Sets up the props for the video in editor.
    - Sets up the editor UI state.
    - Handles logic for detecting changes in clip state.
*/
const useEditorController = (args: {
    task: SnippetTask
    snippetVideo: BaseSnippetVideo
    brands: BrandStyle[]
    store: ReturnType<typeof useVideoReducer>
}) => {
    const { task, snippetVideo, store } = args
    const { clipSchema } = store.videoState
    const router = useRouter()
    const { width } = useWindowSize()
    const isMobile = !!(width && width < 600)
    const defaultBrand = args.brands.find((b) => b.isDefault)
    const initialBrand = snippetVideo.brandId ?? defaultBrand?.id
    const [selectedBrandId, setSelectedBrand] = useState<string | undefined>(initialBrand)
    const [brands, setBrands] = useState<BrandStyle[]>(args.brands)
    const [sidebarSelectedMode, setSideBarMode] = useState<ESidebarPanelName>(
        ESidebarPanelName.Captions,
    )
    const [cropMode, setCropMode] = useState<EVideoFormat>(task.format as EVideoFormat)
    const [isLoadingPreview, setPreviewLoading] = useState<boolean>(true)
    const [isSaving, setSaving] = useState<boolean>(false)

    const videoElementRef = useRef<HTMLVideoElement | null>(null)
    const captionsRef = useRef<PlayerRef | null>(null)
    const videoContainerRef = useRef<HTMLDivElement | null>(null)

    const videoState = useVideoState({
        videoRef: videoElementRef,
        captionsRef,
        state: store.videoState,
        frameRate: task.sourceFps!,
    })

    useEffect(() => {
        if (!router.isReady) {
            return
        }
        loadPreviewData()
    }, [router])

    useEffect(() => {
        if (router.isReady && isMobile) {
            if (router.pathname.includes("editor")) {
                AlertController.show({
                    title: "Your screen is too small",
                    message: "Please access LiveLink from a desktop device to edit your clip.",
                    primaryAction: {
                        label: "OK",
                        onClick: () => router.push(linkToProject(task.id)),
                    },
                })
            }
        }
    }, [router.isReady, isMobile])

    const getChanges = useCallback(() => {
        const brandId = selectedBrandId !== snippetVideo.brandId ? selectedBrandId : initialBrand
        const changes = {
            snippetId: snippetVideo.id,
            captionWords: [],
            brandId,
        } as PostUpdateSnippetQuery
        const original = store.history.getInitial()

        const transcriptState = store.videoState.captionWords.reduce((acc, curr) => {
            acc[curr.start] = curr.text
            return acc
        }, {} as Record<number, string>)
        original.captionWords.forEach((word) => {
            const stateValue = transcriptState[word.start]
            const hasChanged = JSON.stringify(stateValue) !== JSON.stringify(word.text)
            if (hasChanged) {
                changes.captionWords!.push({
                    start: word.start,
                    text: stateValue,
                })
            }
        })
        const hasChangedSchema = !_.isEqual(original.clipSchema, clipSchema)
        if (hasChangedSchema) {
            changes.clipSchema = clipSchema
        }

        return changes
    }, [store, snippetVideo, selectedBrandId, clipSchema])
    const hasChanges = useCallback(() => {
        const { captionWords = [], clipSchema = [] } = getChanges() as PostUpdateSnippetQuery

        if (captionWords.length > 0 || clipSchema.length > 0) {
            return true
        }
        return false
    }, [store, snippetVideo, selectedBrandId])

    const loadPreviewData = async () => {
        try {
            const { transcript, faceChanges: intervals } = await api().getSnippetTaskPreviewData({
                taskId: task.id,
            })

            // TODO(mujavid): remove activity.
            const intervalsWithActivity = intervals.map((word) => ({ ...word, active: true }))
            let clipSchema
            if (isClipSchema(snippetVideo.schema)) {
                clipSchema = snippetVideo.schema as unknown as IClipSchemaItem[]
            } else {
                const snippetStart = snippetVideo.startTime / 1_000
                const snippetEnd = snippetVideo.endTime / 1_000
                clipSchema = generateClipSchemaFromRange({
                    intervals,
                    startTime: snippetStart,
                    endTime: snippetEnd,
                })
            }
            store.history.setInitial({
                intervals: intervalsWithActivity,
                captionWords: transcript,
                clipSchema,
            })
            // Reset history to match initial state
            store.history.reset()
            setPreviewLoading(false)
        } catch (error) {
            setPreviewLoading(false)
            console.error(error)
        }
    }

    const sidebarModel = useMemo(() => {
        return SIDEBAR_DATA_MODEL.map((panel) => {
            return {
                ...panel,
                isOpen: panel.mode === sidebarSelectedMode,
            }
        })
    }, [sidebarSelectedMode])

    const videoUrl = useMemo(() => {
        return getCloudFrontUrl(EAWSCFDistribution.Snippets, task.sourceVideoKey!)
    }, [task.sourceVideoKey])

    const containerDimensions = (() => {
        const dimensions = { width: 0, height: 0 }
        if (!videoContainerRef.current) {
            return dimensions
        }
        const viewerRefPos = videoContainerRef.current.getBoundingClientRect()
        dimensions.width = viewerRefPos.width
        dimensions.height = viewerRefPos.height
        return dimensions
    })()

    const viewerDimensions = useMemo(() => {
        const containerWidth = containerDimensions.width
        const containerHeight = containerDimensions.height

        const dimensions = { width: containerWidth, height: containerHeight }

        const cropDimensions = getCropDimensionsForFormat({
            format: cropMode,
            sourceWidth: task.sourceWidth!,
            sourceHeight: task.sourceHeight!,
        })

        const maxHeight = containerHeight * 0.9
        const maxWidth = containerWidth * 0.9

        const requiresHeightAdjust = cropDimensions.height > maxHeight
        const requiresWidthAdjust = cropDimensions.width > maxWidth

        if (requiresHeightAdjust && requiresWidthAdjust) {
            const heightScaleFactor = maxHeight / cropDimensions.height
            const widthScaleFactor = maxWidth / cropDimensions.width
            const scaleFactor = Math.min(heightScaleFactor, widthScaleFactor)
            if (scaleFactor === heightScaleFactor) {
                dimensions.height = maxHeight
                dimensions.width = cropDimensions.width * scaleFactor
            } else {
                dimensions.width = maxWidth
                dimensions.height = cropDimensions.height * scaleFactor
            }
        } else if (requiresHeightAdjust) {
            const scaleFactor = maxHeight / cropDimensions.height
            dimensions.height = maxHeight
            dimensions.width = cropDimensions.width * scaleFactor
        } else if (requiresWidthAdjust) {
            const scaleFactor = maxWidth / cropDimensions.width
            dimensions.width = maxWidth
            dimensions.height = cropDimensions.height * scaleFactor
        } else {
            dimensions.height = cropDimensions.height
            dimensions.width = cropDimensions.width
        }

        return dimensions
    }, [containerDimensions, cropMode])

    const setVideoContainerRef = (ref: MutableRefObject<HTMLDivElement | null>) => {
        videoContainerRef.current = ref.current
    }
    const updateBrand = (brand: BrandStyle, prevId?: string) => {
        const brandId = prevId ?? brand.id
        setBrands((prevBrands) =>
            produce(prevBrands, (draftBrands) => {
                const brandIndex = draftBrands.findIndex((b) => b.id === brandId)
                if (brandIndex !== -1) {
                    draftBrands[brandIndex] = brand
                }
            }),
        )
    }
    const switchBrand = (args: XOR<{ id: string }, { brand: BrandStyle }>) => {
        if (!!args.id) {
            setSelectedBrand(args.id)
            return
        }
        if (!!args.brand) {
            setBrands((prevBrands) => [...prevBrands, args.brand])
            setSelectedBrand(args.brand.id)
            return
        }
    }
    return {
        // Visible UI State
        ui: {
            sidebar: sidebarModel,
            mode: cropMode,
            dimensions: viewerDimensions,
            isSaving,
            setCropMode,
            setSideBarMode,
            setSaving,
            setVideoContainerRef,
        },
        video: {
            url: videoUrl,
            isPlaying: videoState.isPlaying,
            isLoadingPreview,
            isLoading: videoState.isLoading,
            intervalIndex: videoState.activeIntervalIndex,
            ref: videoElementRef,
            setRef: (
                ref: MutableRefObject<HTMLVideoElement | null>,
                videoCaptionsRef: MutableRefObject<PlayerRef | null>,
            ) => {
                videoState.setRef(ref)
                captionsRef.current = videoCaptionsRef.current
            },
        },
        brand: {
            selected: brands.find((b) => b.id === selectedBrandId),
            list: brands,
            update: updateBrand,
            switch: switchBrand,
        },
        getChanges,
        hasChanges,
    }
}

export default useEditorController
